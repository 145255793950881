<div class="row animated fadeIn fast">
    <div class="col-12">
        <div class="card">
            <div class="card-body">

                <input type="text" class="form-control" placeholder="Buscar..." (keyup)="search( txtTerm.value )" #txtTerm/>

            </div>
        </div>
    </div>
</div>

<div class="row animated fadeIn fast" *ngIf="loading">
    <div class="col-12">

        <div class="alert alert-info text-center">
            <h4 class="alert-heading">Cargando</h4>
            <i class="fa fa-spin fa-refresh fa-2x"></i>
            <p class="mb-0">Por favor espere</p>
        </div>

    </div>
</div>


<div class="row animated fadeIn fast" *ngIf="!loading">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Total de Ofertas ({{ totalOffers }})</h4>
                <h6 class="card-subtitle">Ofertas registradas</h6>
                <div class="table-responsive">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th class="w100">Avatar</th>
                                <th>Título</th>
                                <th>Local</th>
                                <th>Usuario</th>
                                <th>Reg</th>
                                <th class="text-nowrap w100">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let offer of offers">
                                <td class="text-center">
                                    <img [src]="offer.img | img: 'offers'" alt="Avatar de offer" class="avatar">
                                </td>
                                <td>{{ offer.name }}</td>
                                <td>{{ offer.offerer.reg }}</td>
                                <td>{{ offer.user.reg }}</td>
                                <td>{{ offer.reg }}</td>

                                <td class="text-nowrap text-center">
                                    <a data-toggle="tooltip" data-original-title="Borrar" class="cursor" (click)="deleteOffer(offer)">
                                        <i class="fa fa-close text-danger"></i>
                                    </a>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>

                <div class="row">
                    <div class="col-6">
                        <button (click)="pageChange(-1)" class="btn btn-secondary me-3 mb-2">Anterior</button>
                        <button (click)="pageChange(1)" class="btn btn-secondary mb-2">Siguiente</button>
                    </div>
                    <div class="col-6 text-right">
                        {{ from }} - {{ to }}
                    </div>
                </div>


            </div>
        </div>
    </div>
</div>