<div class="h-80-vh d-flex">
    <div class="m-auto">
        <div class="row text-center">
            <div class="col-12">
                <img class="img-init" src="../../../assets/img/blanco-amarillo.svg" alt="LoCana">
            </div>
            <div class="col-12 text-white mb-5">
                <h1>Descuentos únicos</h1>
                <h1>de locales únicos.</h1>
            </div>
        </div>
        <div class="row text-center justify-content-center mt-3 position-relative">
            <div class="col-12 col-sm-4 mb-3">
                <button class="btn btn-warning text-white fw-bolder text-y-shadow w-100 mw-200-px" routerLink="/init/auth/login">Iniciar</button>
            </div>
            <div class="col-12 col-sm-4 mb-3">
                <button class="btn btn-outline-light w-100 mw-200-px sign-in" routerLink="/init/auth/register">Registrarse</button>
            </div>
        </div>
    </div>
</div>