import { Component, OnInit, Input } from '@angular/core';
import { RedsysService } from '../../services/redsys.service';
import { FormBuilder } from '@angular/forms';
import Swal from 'sweetalert2';
import { AuthService } from '../../services/auth.service';
import { User } from '../../models/user.model';

@Component({
  selector: 'app-redsys',
  templateUrl: './redsys.component.html',
  styles: [
  ]
})
export class RedsysComponent implements OnInit {
  
  @Input() popMsg: boolean = true;
  @Input() init: boolean = false;

  public form: any;
  public user: User;

  constructor(  private fb: FormBuilder,
                private redsysService: RedsysService,
                private authService: AuthService  ) {

                  this.user = this.authService.user;
                  
                }

  ngOnInit(): void {

    if ( this.init ) {
      this.redsysInit( this.user.payment.nextLevel, this.user.payment.nextSubsType );
    }

  }

  redsysInit(level: number, type: string) {

    this.redsysService.payment(level, type).subscribe( res => {
      
      this.form = res.form;
      
      if ( !this.popMsg ) {
        return;
      }
      
      let status: any = 'success'
      if (!res.ok) {
        status = 'error';
      }

      if ( this.redsysService.downGradeQuestion( level, type, this.user ) !== 'degradar' && !res.downgrade ) {
        
        Swal.fire(res.title, res.msg, status);

      } else if ( this.redsysService.downGradeQuestion( level, type, this.user ) === 'degradar' || res.downgrade ) {
        
        Swal.fire({
          title: res.title,
          text: res.msg,
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: 'Si, bajar nivel'
        }).then((result) => {
  
              if (result.value) {
                let title = "Guardado"
                status = 'success'
                if (!res.ok) {
                  title = "Error"
                  status = 'error';
                }
                this.redsysService.downgrade( level, type )
                    .subscribe( resp => {
                      Swal.fire( title, resp.msg, status ).then((result) => {
                        if (level === this.user.payment.nextLevel && type === this.user.payment.nextSubsType) {
                          return;
                        }
                        if (result.value) {
                          window.location.reload();
                        }
                      });
                    });
              }
  
            });
      
      } else {

        Swal.fire( 'error', res.msg, 'error');

      }

    });

  }

  reload() {

    setTimeout(()=>{ 
      window.location.reload();
    }, 500)
    
  }

}
