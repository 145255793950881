import { Component, OnInit } from '@angular/core';
import { User } from '../../models/user.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MailService } from '../../services/mail.service';
import Swal from 'sweetalert2';
import { OfferService } from '../../services/offer.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-offer-checker',
  templateUrl: './offer-checker.component.html',
  styles: [
  ]
})
export class OfferCheckerComponent implements OnInit {

  public formSubmitted = false;
  public user: User;
  public checkForm: FormGroup;
  public invalid: boolean = false;

  constructor(  private router: Router,
                private fb: FormBuilder,
                private mailService: MailService,
                private offerService: OfferService,
                private activatedRoute: ActivatedRoute,
                private userService: UserService  ) {}

  ngOnInit(): void {    

    if ( this.activatedRoute.snapshot.params ) {
      var { offerReg, clientReg } = this.activatedRoute.snapshot.params;
    }

    this.checkForm = this.fb.group({
      offerReg: [ offerReg || '', [ Validators.required, Validators.pattern('[0-9]{4}[A-Z]{3}$') ] ],
      clientReg: [ clientReg || '', [ Validators.required, Validators.pattern('[0-9]{4}[A-Z]{3}$') ] ]
    });

  }

  onSend() {

    this.formSubmitted = true;
    this.format()
    
    this.offerService.checkOffer( this.checkForm.value )
          .subscribe( resp => {

            Swal.fire('Enviado', 'El cupón fue canjeado con éxito', 'success');
            this.checkForm.reset();
            this.formSubmitted = false;
            this.invalid = false;
            this.userService.contentsReset();

          }, (err) => {

            Swal.fire('Error', err.error.msg, 'error' );
            this.invalid = true

          } );

  }

  invalidField( field: string ): boolean {

    if ( !this.formSubmitted ) {
      return;
    }
    
    if ( this.checkForm.get( field ).invalid && this.formSubmitted || this.formSubmitted && this.invalid ) {
      return true;
    } else {
      return false;
    }

  }

  format() {

    this.itemFormat('offerReg');
    this.itemFormat('clientReg');

  }

  itemFormat( item ) {

    const registerForm = this.checkForm.controls;
    let finalValue = registerForm[ item ].value.trim();

    finalValue = finalValue.toUpperCase()
    
    registerForm[ item ].setValue( finalValue );
    
  }

}
