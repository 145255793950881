<div class="container text-center text-white login w-100">
    <!-- <div class="row">
        <div class="col mb-3">
            <img class="img-fluid mh-30-vh" src="../../../assets/img/blanco-amarillo.svg" alt="LoCana">
        </div>
    </div> -->
    <form class="form-horizontal form-material position-relative" [formGroup]="contactForm" (ngSubmit)="onSend()" id="loginform">
        <div class="form-group m-t-40 mb-3">
            <div class="col-xs-12">
                <input class="form-control" type="text" placeholder="Email" value="email?email:''" formControlName="email">
            </div>
        </div>
        <div class="form-group mb-3">
            <div class="col-xs-12 d-flex">
                <textarea class="form-control h-15-vh" type="" placeholder="Cuerpo del mensaje" formControlName="msg"></textarea>
            </div>
        </div>

        <div class="form-group text-center m-t-20">
            <div class="col-xs-12 d-flex">
                <button class="btn btn-warning text-white fw-bolder text-y-shadow px-5 m-auto my-3" type="submit">Enviar</button>
            </div>
        </div>
    </form>
</div>