<div class="modal-background animated fadeIn fast show-none" [class.show-opt]="!modalNewService.hideModal">
    <div class="modal fade show m-auto" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel1" style="display: block;">
        <div class="row container-fluid m-auto w-70-lg justify-content-center px-2 px-sm-3">

            <div class="card mh-95-vh">
                <div class="row">
                    <div class="col-6">
                        <h4 *ngIf="selectedType === 'offers'" class="modal-title" id="exampleModalLabel1">Nueva Oferta</h4>
                        <h4 *ngIf="selectedType === 'raffles'" class="modal-title" id="exampleModalLabel1">Nuevo Sorteo</h4>
                    </div>
                    <div class="col-6 text-end">
                        <button type="button" class="btn close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                </div>

                <form class="form mt-3 overflow-auto" [formGroup]="newForm" (submit)="newItem()">
                    <div class="row pb-5">
                        <div class="col-12 mb-3">
                            <div class="card h-100">
                                <div class="card-body">
                                    <h4 class="card-title">Avatar</h4>
                                    <h6 class="card-subtitle">Imagen del Local</h6>

                                    <app-img-select [type]="'offers'" (newImg)="newImg = $event"></app-img-select>

                                </div>
                            </div>
                        </div>
                        <div class="col-12 mb-3">
                            <div class="card h-100">
                                <div class="card-body">
                                    <div class="row mt-3">
                                        <div class="form-group mb-3 mb-3 col-12">
                                            <label *ngIf="selectedType === 'offers'" for="exampleInputuname">Título</label>
                                            <label *ngIf="selectedType === 'raffles'" for="exampleInputuname">Premio</label>
                                            <div class="input-group">
                                                <input class="form-control" type="text" [placeholder]="(selectedType === 'offers')? 'Título': 'Premio'" formControlName="name">
                                            </div>
                                        </div>
                                        <div *ngIf="selectedType === 'offers'" class="row">
                                            <div class="form-group mb-3 mb-3 col-12 col-sm-8" [class.invalid]="invalid" [class.show-none]="selectedType !== 'offers'">
                                                <div class="row">
                                                    <label for="exampleInputuname">Precio (€)</label>
                                                    <div class="col-6 pe-1 pe-sm-2">
                                                        <label for="exampleInputuname">Inicial</label>
                                                        <div class="input-group">
                                                            <input (keyup)="validatePrice()" class="form-control" type="number" step="any" placeholder="Precio" formControlName="price" #price>
                                                        </div>
                                                    </div>
                                                    <div class="col-6 ps-1 ps-sm-2">
                                                        <label for="exampleInputuname">Final</label>
                                                        <div class="input-group">
                                                            <input (keyup)="validatePrice()" class="form-control" type="number" step="any" placeholder="Precio final" formControlName="finalPrice" #finalPrice>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group mb-3 col-12 col-sm-4 align-fe" [class.show-none]="selectedType !== 'offers'">
                                                <label for="exampleInputuname">Descuento</label>
                                                <div class="input-group">
                                                    <span *ngIf="price.value <= 0" class="fs-3">--,- %</span>
                                                    <span class="fs-3">{{ (( 1 - finalPrice.value / price.value ) | percent:'.0-1') }}</span>
                                                </div>
                                            </div>
                                            <div class="col-12 text-start text-danger" [class.show-none]="!invalid">
                                                <p>* Revise los importes</p>
                                            </div>
                                        </div>
                                        <div class="form-group mb-3 mb-3 col-12 col-sm-6">
                                            <label for="exampleInputuname">Fecha de Activación</label>
                                            <div class="input-group">
                                                <input class="form-control" type="date" placeholder="Fecha de Activación" formControlName="startDate">
                                            </div>
                                        </div>
                                        <div class="form-group mb-3 mb-3 col-12 col-sm-6">
                                            <label for="exampleInputuname">Fecha de Desactivación</label>
                                            <div class="input-group">
                                                <input class="form-control" type="date" placeholder="Fecha de desactivación" formControlName="endDate">
                                            </div>
                                        </div>
                                        <div class="form-group mb-3 mb-3 col-12 col-sm-6">
                                            <label for="exampleInputuname">Hora de Activación</label>
                                            <div class="input-group">
                                                <input class="form-control" type="time" placeholder="Hora de Activación" formControlName="startTime">
                                            </div>
                                        </div>
                                        <div class="form-group mb-3 mb-3 col-12 col-sm-6">
                                            <label for="exampleInputuname">Hora de Desactivación</label>
                                            <div class="input-group">
                                                <input class="form-control" type="time" placeholder="Hora de desactivación" formControlName="endTime">
                                            </div>
                                        </div>
                                        <div *ngIf="selectedType === 'offers'" class="form-group mb-3 mb-3 col-12 col-sm-6 align-fe">
                                            <label for="exampleInputuname">Nº Cupones</label>
                                            <div class="input-group">
                                                <input class="form-control" type="number" placeholder="Nº Cupones" formControlName="limit">
                                            </div>
                                        </div>
                                        <div *ngIf="selectedType === 'raffles'" class=" form-group mb-3 mb-3 col-12 align-fe">
                                            <label for="exampleInputuname">Nº Premios</label>
                                            <div class="input-group">
                                                <input class="form-control" type="number" placeholder="Nº Premios" formControlName="winnersLimit">
                                            </div>
                                        </div>
                                        <div *ngIf="selectedType==='offers'" class="form-group mb-3 mb-3 col-12 col-sm-6">
                                            <div class="row">
                                                <div class="col-12">
                                                    <label for="exampleInputuname">Espera</label>
                                                </div>
                                                <div class="col-6 pe-1 pe-sm-2">
                                                    <label for="exampleInputuname">Tiempo</label>
                                                    <div class="input-group">
                                                        <input class="form-control" type="number" placeholder="Horas" formControlName="waitTime">
                                                    </div>
                                                </div>
                                                <div class="col-6 ps-1 ps-sm-2">
                                                    <label for="exampleInputuname">Usos</label>
                                                    <div class="input-group">
                                                        <input class="form-control" type="number" placeholder="Usos máximos" formControlName="maxUses">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div class="form-group mb-3 mb-3 col-12 col-sm-6">
                                            <label for="exampleInputuname">Tiempo preparación (min)</label>
                                            <div class="input-group">
                                                <input class="form-control" type="text" placeholder="Tiempo estimado de ejecución" formControlName="executionTime">
                                            </div>
                                        </div> -->
                                        <div class="form-group mb-3 mb-3 col-12">
                                            <label for="exampleInputuname">Etiquetas</label>
                                            <div class="input-group border-bottom">
                                                <div class="my-auto ms-0">
                                                    <span *ngFor="let tag of tags" class="badge rounded-pill bg-secondary h-50 me-1 mb-1">{{ tag | titlecase }}<i (click)="onDeleteTag(tag)" class="fas fa-times-circle ms-1 pointer"></i></span>
                                                </div>
                                                <input (change)="onTagsInput( tagsInput.value ); tagsInput.value=''" class="form-control border-0" list="tagsList" type="text" placeholder="Etiquetas" formControlName="tags" #tagsInput>
                                                <datalist id="tagsList">
                                                    <option *ngFor="let tagListItem of tagsList" [value]="tagListItem">
                                                </datalist>
                                            </div>
                                        </div>
                                        <div *ngIf="selectedType==='offers'" class="form-group mb-3 mb-3 col-12">
                                            <label for="exampleInputuname">Condiciones de uso</label>
                                            <div class="input-group">
                                                <textarea class="form-control" type="text" placeholder="Condiciones de uso" formControlName="useConditions"></textarea>
                                            </div>
                                        </div>
                                        <div class="form-group mb-3 mb-3 col-12">
                                            <label for="exampleInputuname">Descripción</label>
                                            <div class="input-group">
                                                <textarea class="form-control" type="text" placeholder="Descripción" formControlName="description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col text-warning">
                                <div *ngIf="selectedType === 'offers'">
                                    <p *ngIf="invalidField( 'price')">* Comprobar precio</p>
                                    <p *ngIf="invalidField( 'finalPrice')">* Comprobar precio final</p>
                                    <p *ngIf="invalidField( 'limit')">* Comprobar límite</p>
                                    <p *ngIf="invalidField( 'useConditions')">* Comprobar condiciones</p>
                                </div>
                                <div *ngIf="selectedType === 'raffles'">
                                    <p *ngIf="invalidField( 'winnersLimit')">* Comprobar límite</p>
                                </div>
                                <p *ngIf="invalidField( 'name')">* Comprobar nombre</p>
                                <p *ngIf="invalidField( 'description')">* Comprobar descripción</p>
                                <!-- <p *ngIf="invalidField( 'executionTime')">* Comprobar tiempo de ejecución</p> -->

                            </div>
                        </div>
                    </div>
                    <div class="row pt-5">
                        <div class="col bg-white position-absolute start-0 bottom-0 w-100 px-3">
                            <div class="">
                                <button type="submit" class="btn btn-warning text-white fw-bolder my-3 w-100" [disabled]="newForm.untouched || !newForm.controls">
                                    Crear
                                </button>
                            </div>
                        </div>
                    </div>

                </form>

            </div>
        </div>

    </div>
</div>