<form *ngIf="offer" class="form mt-3 w-70 m-auto" [formGroup]="offerForm">
    <div class="form-group row animate__animated animate__fadeIn py-0 m-auto">
        <div class="position-relative z-1000">
            <div class="position-absolute top-0 end-0 mt-0 mt-lg-1 me-2">
                <button *ngIf="edit" (click)="delete()" type="button" class="btn btn-danger py-1 px-2 me-2">
                    <i class="fas fa-trash-alt"></i>
                </button>
                <button *ngIf="owner" (click)="edit=!edit" type="button" class="btn btn-outline-secondary py-1 px-2" [class.bg-secondary]="edit" [class.text-white]="edit">
                    <i class="fas fa-edit"></i>
                </button>
                <div *ngIf="!owner" class="position-relative text-warning my-auto mt-1 mt-lg-0 me-1">
                    <span (click)="report()" class="pointer me-2">
                        <i class="fas fa-exclamation-circle text-danger"></i>
                    </span>
                    <span (click)="addFavs()" class="pointer">
                        <i class="far fa-star animated fast"></i>
                        <i class="fas fa-star animated fast position-absolute end-0 top-025" [class.o-10]="fav"></i>
                    </span>
                </div>
            </div>
        </div>
        <div class="col-12 f-s">
            <div class="of-wrap input-group w-70-st">
                <h1 *ngIf="!edit">{{ offer.name }}</h1>
            </div>
            <div class="input-group">
                <a [href]="('https://www.google.es/maps/place/'+offer.offerer.address.address+', '+offer.offerer.address.pc)" [title]="offer.offerer.name  + ', en ' + offer.offerer.address.town" target="_blank" class="pointer">
                    <i class="me-2 far fa-compass"></i>
                    <small>{{ (offer.offerer.name + ', en ' + offer.offerer.address.town) | titlecase }}</small>
                    <!-- <small>{{ ((((offer.offerer.name.length > maxLength) ? ( (offer.offerer.name | slice:0:maxLength) + '...'))  + ', en ' + ((offer.offerer.address.town.length > maxLength) ? ((offer.offerer.address.town | slice:0:maxLength) + '...') : offer.offerer.address.town )) : (offer.offerer.name  + ', en ' + offer.offerer.address.town)) | titlecase }}</small> -->
                </a>
            </div>
            <div class="input-group">
                <a [href]="'user/offerer/' + offer.offerer._id"><i class="fas fa-sign-out-alt me-2"></i>Ir al local</a>
            </div>
        </div>
    </div>
    <div class="form-group row pt-2">
        <div class="col-12 col-lg-6 img-offer-h" [class.col-lg-12]="edit">
            <app-img-select [img]="offer?offer.img:'no-img'" [type]="'offers'" [edit]="edit" (newImg)="newImg = $event"></app-img-select>
        </div>
        <div class="col-12 mt-4 mt-lg-0 col-lg-6" [class.col-lg-12]="edit">
            <div class="position-relative m-auto" [class.h-100]="!edit" [class.w-70]="edit" [class.mt-4]="edit">
                <h5 *ngIf="!edit">Condiciones de uso</h5>
                <h5 *ngIf="edit">Editar Oferta</h5>
                <div *ngIf="edit" class="input-group">
                    <input class="form-control main mb-2" type="text" placeholder="Título" formControlName="name" contenteditable="false" [readonly]="!edit">
                </div>
                <div class="input-group desc-txt scroll-show" [class.edit]="edit" [class.mb-2]="edit">
                    <p *ngIf="!edit" [innerText]="offer.useConditions"></p>
                    <textarea *ngIf="edit" class="form-control secondary scroll-show" type="text" placeholder="Condiciones de uso" formControlName="useConditions" [readonly]="!edit"></textarea>
                </div>
                <div class="row lg-absolute start-0 bottom-0 start-0 end-0 w-100 m-auto" [class.position-absolute]="!edit" [class.position-static]="edit">
                    <div *ngIf="!regText">
                        <div class="col-12">
                            <div class="row">
                                <div class="col col-sm-6 col-lg-8" [class.mb-2]="edit">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="row">
                                                <div *ngIf="edit" class="col-12">
                                                    <label class="m-auto mx-2">Precio:</label>
                                                </div>
                                                <div *ngIf="edit" class="col-6">
                                                    <label class="m-auto mx-2">Inicial:</label>
                                                </div>
                                                <div *ngIf="edit" class="col-6" [class.col-12]="!edit">
                                                    <label class="m-auto mx-2">Final:</label>
                                                </div>
                                                <div *ngIf="!edit" class="col-6" [class.col-12]="!edit">
                                                    <label class="m-auto mx-2">Precio final:</label>
                                                </div>
                                            </div>
                                            <div *ngIf="!edit" class="row">
                                                <div class="col align-fe">
                                                    <small class="secondary text-decoration-line-through py-0 mx-2">{{ offer.price }} €</small>
                                                    <span class="secondary fw-bold py-0 mx-0 fs-3">{{ offer.finalPrice }} €</span>
                                                </div>
                                            </div>
                                            <div class="row" [class.show-none]="!edit">
                                                <div class="col align-fe">
                                                    <input class="form-control secondary py-0" type="number" placeholder="Precio inicial" formControlName="price" [readonly]="!edit" #price>
                                                </div>
                                                <div class="col" [class.show-none]="!edit">
                                                    <input class="form-control secondary py-0" type="number" placeholder="Precio final" formControlName="finalPrice" [readonly]="!edit" #finalPrice>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col col-sm-6 col-lg-4 align-fe" [class.mb-2]="edit">
                                    <div class="input-group" [class.justify-content-end]="!edit">
                                        <div class="row">
                                            <div class="col-12 text-end">
                                                <label class="m-auto mx-2">Descuento:</label>
                                            </div>
                                            <div class="col-12 text-end">
                                                <small class="secondary py-0 mx-2 text-end fw-bold fs-3">{{ (( 1 - finalPrice.value / price.value ) | percent:'.0-1') }}</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col col-sm-6 mb-2" [class.col-sm-12]="edit">
                                <div class="row">
                                    <div class="col-12">
                                        <label *ngIf="!edit" class="m-auto mx-2">Quedan:</label>
                                        <label *ngIf="edit" class="m-auto mx-2">Límite:</label>
                                    </div>
                                    <div class="col-12" [class.col-12]="!edit">
                                        <small *ngIf="!edit" class="secondary py-0 mx-2">{{ (offer.limit > 0 ? (offer.limit - offer.used) :  '&#x221e;' ) }}</small>
                                        <input *ngIf="edit" class="form-control secondary py-0" type="number" placeholder="Límite" formControlName="limit" [readonly]="!edit">
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="!edit" class=" col col-sm-6 mb-2 justify-content-end">
                                <div class="row">
                                    <div class="col-12 text-end ps-1">
                                        <label class="m-auto mx-2">Finaliza:</label>
                                    </div>
                                    <div class="col-12 text-end ps-1">
                                        <small class="secondary py-0 mx-2 text-end">{{ (offer.endDate === null ? 'Sin fecha' : (offer.endDate | date:"dd/MM/YYYY")) }}</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="edit" class="col-12 col-sm-6 mb-2" [class.col-sm-12]="edit">
                            <div class="row">
                                <label for="exampleInputuname">Espera</label>
                                <div class="col-6 pe-1 pe-sm-2">
                                    <label for="exampleInputuname">Tiempo</label>
                                    <div class="input-group">
                                        <input class="form-control" type="number" placeholder="Horas" formControlName="waitTime">
                                    </div>
                                </div>
                                <div class="col-6 ps-1 ps-sm-2">
                                    <label for="exampleInputuname">Usos</label>
                                    <div class="input-group">
                                        <input class="form-control" type="number" placeholder="Usos máximos" formControlName="maxUses">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="edit" class="row">
                            <div class="col col-sm-6 mb-2">
                                <div class="row">
                                    <div class="col-12 text-sm-end">
                                        <label class="m-auto mx-2">Fecha inicio:</label>
                                    </div>
                                    <div class="col-12 text-sm-end">
                                        <input class="form-control secondary text-end py-0" type="date" placeholder="Fecha de inicio" formControlName="startDate">
                                    </div>
                                    <div class="col-12 text-sm-end">
                                        <label class="m-auto mx-2">Hora inicio:</label>
                                    </div>
                                    <div class="col-12 text-sm-end">
                                        <input class="form-control secondary text-end py-0" type="time" placeholder="Hora de inicio" formControlName="startTime">
                                    </div>
                                </div>
                            </div>
                            <div class="col col-sm-6 mb-2 class.justify-content-end">
                                <div class="row">
                                    <div class="col-12 text-sm-end">
                                        <label class="m-auto mx-2">Fecha fin:</label>
                                    </div>
                                    <div class="col-12 text-end">
                                        <input class="form-control secondary text-end py-0" type="date" placeholder="Fecha de final" formControlName="endDate">
                                    </div>
                                    <div class="col-12 text-sm-end">
                                        <label class="m-auto mx-2">Hora fin:</label>
                                    </div>
                                    <div class="col-12 text-end">
                                        <input class="form-control secondary text-end py-0" type="time" placeholder="Hora de final" formControlName="endTime">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="edit" class="form-group mb-3 mb-3 col-12">
                            <label for="exampleInputuname">Etiquetas</label>
                            <div class="input-group border-bottom">
                                <div class="row">
                                    <div class="col-auto">
                                        <div class="my-auto ms-0">
                                            <span *ngFor="let tag of tags" class="badge rounded-pill bg-secondary h-50 me-1 mb-1">{{ tag | titlecase }}<i (click)="onDeleteTag(tag)" class="fas fa-times-circle ms-1 pointer"></i></span>
                                        </div>
                                    </div>
                                    <div class="col-auto">
                                        <input (change)="onTagsInput( tagsInput.value ); tagsInput.value=''" class="form-control border-0" list="tagsList" type="text" placeholder="Etiquetas" formControlName="tags" #tagsInput>
                                        <datalist id="tagsList">
                                    <option *ngFor="let tagListItem of tagsList" [value]="tagListItem">
                                    </datalist>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="!edit" class="col-12 px-0 w-100 text-center">
                        <button (click)="regText=!regText" type="button" class="btn btn-warning text-white fw-bolder text-y-shadow w-100 py-2">
                            <span *ngIf="!regText">
                                Canjear Oferta
                            </span>
                            <div *ngIf="regText">
                                <span>
                                    <span>{{ 'Código de Oferta: '+offer.reg }}</span>
                                    <br>
                                    <span>{{ 'Tú Código: '+user.reg }}</span>
                                </span>
                                <br>
                                <br>
                                <div class="mb-2">
                                    <qrcode [qrdata]="regBody" [cssClass]=" 'qr'" [elementType]=" 'img'" [errorCorrectionLevel]=" 'M'"></qrcode>
                                </div>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="!edit" class="row mt-3 m-auto justify-content-center animate__animated animate__fadeIn animated__faster">
        <div *ngFor="let contact of contacts, let i=index" class="col px-1 px-lg-2 px-md-3 mb-2" [class.show-none]="offer.offerer.contact[contact] ? false : true">
            <div *ngIf="offer.offerer.contact[contact]" class="text-center">
                <button (click)="contactIndex===i ? contactShow='' : (contactShow=offer.offerer.contact[contact]); contactIndex===i ? contactIndex=( this.contacts.length + 1) : contactIndex=i ; exist.checked=! exist.checked" type="button" class="btn btn-primary text-white fw-bolder w-100 mw-300-px">
                    <span *ngIf="windowWidth>= 850">{{ contactsSp[i] | titlecase}}</span>
                    <i [class]="contactsIcon[i] + ' ms-md-2'"></i>
                </button>
                <input type="checkbox" class="show-none" #exist>
                <div *ngIf="exist.checked && (windowWidth >= 1000)" class="text-center mt-2">
                    <small class="f-s">Click para contactar:</small>
                    <a [href]="contactsRef[i] + offer.offerer.contact[contact]" target="_blank">
                        <h3>{{ offer.offerer.contact[contact] }}</h3>
                    </a>
                </div>
            </div>
        </div>
        <a *ngIf="(windowWidth < 1000) && contactShow" [href]="contactsRef[contactIndex] + contactShow" target="_blank" class="text-center mt-3">
            <small class="f-s">Click para contactar:</small>
            <h3 class="text-break">{{ contactShow }}</h3>
        </a>
    </div>
    <div class="m-auto mt-3" [class.w-70]="edit">
        <h5>Descripción</h5>
        <div class="input-group desc-txt scroll-show" [class.edit]="edit">
            <p *ngIf="!edit" [innerText]="offer.description"></p>
            <textarea *ngIf="edit" class="form-control secondary scroll-show" type="text" placeholder="Descripción" formControlName="description" [readonly]="!edit"></textarea>
        </div>
    </div>
    <div *ngIf="edit" class="row animated m-auto w-70 mw-300-px">
        <div class="col">
            <!-- [disabled]="offerForm.invalid" -->
            <button (click)="saveOffer()" type="button" class="btn btn-warning fw-bolder my-3 w-100">
                Guardar cambios
            </button>
        </div>
    </div>
</form>