<label *ngIf='!isOpen'>
    Horario
    <i *ngIf="collapsed" (click)="collapsed=false;scheduleInit()" class="ms-2 text-secondary fas fa-plus-circle pointer"></i>
    <i *ngIf="!collapsed" (click)="collapsed=true;scheduleInit()" class="ms-2 text-secondary fas fa-minus-circle pointer"></i>
</label>
<form (change)="scheduleConstructor()" class="form position-relative" [formGroup]="scheduleForm">
    <div class="col-12 animated-menu my-2 m-auto" [class.show-none]="collapsed && !isOpen">
        <div class="row" [class.show-none]="!edit">
            <div class="col-12 col-md-4 my-2">
                <button (click)="match.checked=!match.checked" type="button" class="btn w-100" [class.btn-outline-primary]="!match.checked" [class.btn-primary]="match.checked">
                    Partido
                </button>
                <input class="show-none" type="checkbox" formControlName="match" #match>
            </div>
            <div class="col-12 col-sm-6 col-md-4 my-2">
                <button (click)="setW('week')" type="button" class="btn btn-outline-secondary w-100" [class.btn-outline-secondary]="!setWeek" [class.btn-secondary]="setWeek">
                    Entre Semana
                </button>
            </div>
            <div class="col-12 col-sm-6 col-md-4 my-2">
                <button (click)="setW('weekend')" type="button" class="btn btn-outline-secondary w-100" [class.btn-outline-secondary]="!setWeekend" [class.btn-secondary]="setWeekend">
                    Fin de Semana
                </button>
            </div>
        </div>
        <div class="text-center">
            <div class="row row-cols-3 fw-bold justify-content-center ">
                <div class="col-3 px-0">Día</div>
                <div class="col-4">Abre</div>
                <div class="col-4">Cierra</div>
            </div>
            <div class="row justify-content-center border-bottom py-3" [class.show-none]="!setWeek && !setWeekend">
                <div class="col-12 col-sm-3 mb-2 px-0">
                    <span *ngIf="setWeek">Entre Semana</span>
                    <span *ngIf="setWeekend">Fin de Semana</span>
                </div>
                <div class="col-10 col-sm-8">
                    <div class="row row-cols-2">
                        <div class="px-1 px-md-2">
                            <div class="input-group">
                                <input (change)="setHours('open', open.value)" class="form-control" type="time" #open>
                            </div>
                        </div>
                        <div class="px-1 px-md-2">
                            <div class="input-group">
                                <input (change)="setHours('close', close.value)" class="form-control" type="time" #close>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-3" [class.show-none]="!match.checked"></div>
                <div class="col-10 col-sm-8 mt-2" [class.show-none]="!match.checked">
                    <div class="row row-cols-2">
                        <div class="px-1 px-md-2">
                            <div class="input-group">
                                <input (change)="setHours('matchOpen', matchOpen.value)" class="form-control" type="time" #matchOpen>
                            </div>
                        </div>
                        <div class="px-1 px-md-2">
                            <div class="input-group">
                                <input (change)="setHours('matchClose', matchClose.value)" class="form-control" type="time" #matchClose>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngFor="let day of week; let i = index" class="row row-cols-3 justify-content-center my-3" [class.invalid]="invalid === i">
                <div class="col-12 col-sm-3 mb-2 px-0">
                    {{ day }}
                </div>
                <div class="col-10 col-sm-8">
                    <div class="row row-cols-2">
                        <div class="px-1 px-md-2">
                            <div class="input-group">
                                <input class="form-control" type="time" [formControlName]="'open'+i" [readonly]="!edit">
                            </div>
                        </div>
                        <div class="px-1 px-md-2">
                            <div class="input-group">
                                <input class="form-control" type="time" [formControlName]="'close'+i" [readonly]="!edit">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-3"></div>
                <div class="col-10 col-sm-8 mt-2" [class.show-none]="!match.checked">
                    <div class="row row-cols-2">
                        <div class="px-1 px-md-2">
                            <div class="input-group">
                                <input class="form-control" type="time" [formControlName]="'matchOpen'+i" [readonly]="!edit">
                            </div>
                        </div>
                        <div class="px-1 px-md-2">
                            <div class="input-group">
                                <input class="form-control" type="time" [formControlName]="'matchClose'+i" [readonly]="!edit">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-8 text-start text-danger" [class.show-none]="invalid !== i">
                    <p>* Revisa los tiempos de apertura y cierre</p>
                </div>
            </div>
        </div>
    </div>
</form>