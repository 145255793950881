import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pay-denied',
  templateUrl: './pay-denied.component.html',
  styles: [
  ]
})
export class PayDeniedComponent implements OnInit {

  constructor(  private router: Router,  ) { }

  ngOnInit(): void {
  }

  contact () {
    this.router.navigate( ['/user/contact'] );
  }

}
