import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

const base_url = environment.base_url;

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  constructor( private http: HttpClient) { }

  get token(): string {
    return localStorage.getItem('token') || '';
  }

  get headers() {
    return {
      headers: {
        'x-token': this.token
      }
    }
  }



  async uploadImg(
    file: File | Blob,
    type: 'users'|'offerers'|'offers'|'raffles'
  ) {

    try {
      
      const url = `${ base_url }/upload/${ type }`;
      
      const formData = new FormData();
      formData.append('image', file);

      const resp = await fetch( url, {
        method: 'POST',
        headers: {
          'x-token': localStorage.getItem('token') || ''
        },
        body: formData
      });

      const data = await resp.json();

      if ( data.ok ) {
        return data.fileName;
      } else {
        console.log(data.msg);
        return false;
      }

    } catch (error) {

      return false;

    }

  }

  async updateImg(
    file: File | Blob,
    type: 'users'|'offerers'|'offers'|'raffles',
    id: string
  ) {

    try {
      
      const url = `${ base_url }/upload/${ type }/${ id }`;
      
      const formData = new FormData();
      formData.append('image', file);

      const resp = await fetch( url, {
        method: 'PUT',
        headers: {
          'x-token': localStorage.getItem('token') || ''
        },
        body: formData
      });

      const data = await resp.json();
  
      if ( data.ok ) {
        return data.fileName;
      } else {
        console.log(data.msg);
        return false;
      }

    } catch (error) {
      return false;
    }

  }



}
