import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../environments/environment';

const base_url = environment.base_url;

@Pipe({
  name: 'img'
})
export class ImgPipe implements PipeTransform {

  transform( img: string, type: 'users'|'offerers'|'offers'|'raffles'): string {
    
    if ( !img ) {
        return `${ base_url }/upload/users/no-img`;
    } else if ( img.includes('https') ) {
        return img;
    } else if ( img ) {
        return `${ base_url }/upload/${ type }/${ img }`;
    } else {
        return `${ base_url }/upload/users/no-img`;
    }

  }

}
