import { Component, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Validators, FormBuilder } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styles: []
})
export class LoginComponent implements OnInit {

  public formSubmitted = false;
  public auth2: any;

  public loginForm = this.fb.group({
    access: [ localStorage.getItem('access') || '' , [ Validators.required ] ],
    password: ['', Validators.required ],
    remember: [false]
  });

  constructor(  private router: Router,
                private fb: FormBuilder,
                private authService: AuthService,
                private ngZone: NgZone  ) { }

  ngOnInit(): void {
  }

  onLogin() {

    this.authService.login( this.loginForm.value )
      .subscribe( resp => {

        if ( this.loginForm.get('remember').value ){ 
          localStorage.setItem('access', this.loginForm.get('access').value );
        } else {
          localStorage.removeItem('access');
        }

        // Navegar al Dashboard
        this.router.navigateByUrl('/');

      }, (err) => {
        // Si sucede un error
        Swal.fire('Error', err.error.msg, 'error' );
      });

  }
}
