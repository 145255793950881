import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateFormatService {

  constructor() { }

  dateDiff( date ) {

    const now = new Date();
    const nowDate = `${ now.getUTCFullYear() }-${ ( now.getUTCDay() < 10 ) ? ( '0' + now.getUTCDay() ) : now.getUTCDay() }-${now.getUTCDate()}`;
    const end = new Date( date);
    
    const diff = ( end.getTime() - now.getTime() )/( 1000 * 3600 * 24 );
    const days = Math.floor( diff );
    const hours = Math.round( ( ( diff % 1 ) * 24 ) * 100 ) / 100;  
    const minutes = Math.round( ( ( hours % 1 ) * 60 ) * 100 ) / 100;
    const finalDiff = `${ days }d/${ Math.floor( hours ) }h/${ Math.floor( minutes ) }m`

    return finalDiff;

  }

  getDateTime ( dateTime: Date = new Date() ) {

    const fullDate = new Date( dateTime );
    const date = fullDate.getFullYear() + '-' + this.leftZero( fullDate.getMonth() + 1 )  + '-' + this.leftZero( fullDate.getDate() );
    const time = fullDate.getHours() + ':' + fullDate.getMinutes();
    return { fullDate, date, time }

  }

  leftZero ( int: number ) {

    if ( int < 10 ) {
      return '0' + int;
    } else {
      return int;
    }

  }

  nowVsDate ( date: Date ) {

    const now = new Date();

    if (date === null ) {
      return false;
    }

    date = new Date( date );

    return now > date;

  }

}
