<div class="container-fluid w-95 mw-95-vw py-5">

    <div class="w-95 init-img-h text-center">
        <img class="img-fluid mh-40-vh m-auto" src="../../../assets/img/blanco-amarillo.svg" alt="LoCana">
    </div>
    <div class="container bg-light-o shadow rounded p-4 p-sm-5 position-relative">
        <h1>AVISO LEGAL</h1>
        <p></p>
        <p></p>
        <ol>
            <li>
                <h3>Titularidad de la web</h3>
                <p>
                    Cumpliendo el deber de información recogido en el artículo 10 de la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y del Comercio Electrónico (LSSICE), BOE N º 166, informa que el titular de esta página web es NICOLAS FEBLES
                    STORANI. (en adelante LOCANA), como persona física, con domicilio social en la calle Calafate nº1, C.P. 38400 Santa Cruz de Tenerife y con C.I.F. 78636786Q.
                </p>
                <p>Correo de contacto: soporte.locana@gmail.com</p>
                <p>El dominio locana.es es de la titularidad de ALEJO FEBLES STORANI.</p>
            </li>
            <li>
                <h3>La web y sus limitaciones de responsabilidad:</h3>
                <ol>
                    <li>
                        <p><b>Contenidos de la web.</b></p>
                        <p>
                            La web tiene contenidos para la promoción de comercios locales en las Islas Canarias, mediante ofertas o prestación de servicios, publicados directamente por LOCANA o por terceros (personas jurídicas o físicas) (de ahora en adelante terceros).
                        </p>
                        <p>
                            La utilización de la web y sus contenidos supone la aceptación plena por el usuario de todas las condiciones contenidas en la presente Información Legal, por lo que si el usuario no está de acuerdo con el contenido de la misma deberá abstenerse de hacer
                            uso de la misma.
                        </p>
                        <p>
                            Las limitaciones de responsabilidad de los contenidos de la web son:
                        </p>

                        <ol>
                            <li>
                                <p><b>Información contenida en la web.</b></p>
                                <p>
                                    La información contenida en la web puede ser proporcionada directamente por LOCANA o indirectamente a través de terceros.
                                </p>
                                <p>
                                    LOCANA no se hace responsable de la información de terceros ofrecida a través de la web, ni controla previamente, ni aprueba, ni hace propios la información de éstos recogidas en la web.
                                </p>
                            </li>
                            <li>
                                <p><b>Servicios y Productos de la web.</b></p>
                                <p>
                                    La web puede prestar servicios bien gestionados directamente por LOCANA o por terceros, de naturaleza gratuita, sin perjuicio de establecer precio a determinados servicios o productos ofrecidos, de lo cual informará oportunamente en las secciones destinadas
                                    a los mismos.
                                </p>
                                <p>
                                    LOCANA no asume ningún tipo de responsabilidad directa ni indirecta en relación con la licitud, veracidad, utilidad, calidad o fiabilidad de los servicios y su contenido que no sean propiedad de LOCANA o no estén directamente gestionados o controlados
                                    por LOCANA, aunque estos puedan ser accesibles a través de la web.
                                </p>
                                <p>
                                    Cualquier relación contractual o extracontractual que, en su caso, se formalice con las terceras personas contactadas a través de la web, así como, la participación en concursos, promociones, compraventa de bienes o servicios que éstos desarrollen, se
                                    entienden realizados única y exclusivamente entre el usuario y los terceros.
                                </p>
                                <p>
                                    El usuario acepta que LOCANA no tiene ningún tipo de responsabilidad sobre los daños o perjuicios de cualquier naturaleza ocasionados con motivo de sus negociaciones, conversaciones y/o relaciones contractuales o extracontractuales con terceros contactadas
                                    a través de la web.
                                </p>
                            </li>
                            <li>
                                <p><b>Enlaces a webs ajenas.</b></p>
                                <p>
                                    La web contiene enlaces a páginas webs ajenas a ésta que se ponen a disposición del usuario como única finalidad facilitar el acceso a otros contenidos y/o servicios que pudieran ser de su interés, no estando estos gestionados ni controlados por LOCANA.
                                </p>
                                <p>
                                    Una vez que un usuario se encuentre en estas webs ajenas deberá estar a las indicaciones, avisos o advertencias legales que esa otra web establezca para su uso.
                                </p>
                                <p>
                                    La inclusión de estos enlaces no implicará ningún tipo de vinculación de LOCANA con las entidades titulares de las webs enlazadas.
                                </p>
                            </li>
                        </ol>
                    </li>
                </ol>
                <li>
                    <p><b>Accesos a la web</b></p>
                    <ol>
                        <li>
                            <p><b>Usuarios.</b></p>
                            <p>
                                Toda persona interesada en la información relativa a ofertas y otros contenidos, podrá hacer uso de la web teniendo la consideración a los efectos de la presente Información legal como usuario.
                            </p>
                            <p>
                                LOCANA requerirá a los usuarios su identificación para su registro (de ahora en adelante usuario registrado) para lo que se deberá estar a lo dispuesto en el punto 4.1. y 4.2.
                            </p>
                            <p>
                                El acceso de los usuarios a la web es de la exclusiva responsabilidad de quien lo realiza.
                            </p>
                            <p>
                                LOCANA se reserva el derecho a denegar o retirar el acceso a su web y/o los servicios ofrecidos sin necesidad de preaviso, a instancia propia o de un tercero, a aquellos usuarios que incumplan las presentes Condiciones Generales de Uso.
                            </p>
                            <p>
                                Además, en los supuestos de que exista foros de discusión o servicios interactivos (blogs, chats, comunidades dónde se envían contenidos, mensajes, comentarios o cualquier otro elemento o material), de ahora en adelante áreas interactivas, el usuario
                                es el responsable directo del uso que realice de tales áreas interactivas. Al utilizarlas acepta no enviar, cargar, transmitir, distribuir, almacenar, crear o publicar cualquier mensaje, comentario, dato, información, texto,
                                música, sonido, fotografía, gráfico, código u otro material:
                            </p>
                            <ol>
                                <li>
                                    <p>Que sea ilegal, difamatorio, xenófobo, obsceno, pornográfico o que vulnere el derecho al honor o a la intimidad personal de terceras personas o atente contra la moral o que puedan resultar de mal gusto u ofensivas o
                                        atente contra la protección jurídica del menor o sea amenazador o constitutivo de acoso, que invada los derechos de privacidad o publicidad, que sea abusivo, hostil, fraudulento o censurable de algún modo
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Que constituya un ilícito penal, aliente o proporcione instrucciones para cometerlo, infrinja los derechos de cualquiera de las partes, cree algún tipo de responsabilidad o incumpla cualquier normativa o legislación de ámbito local, autonómico, estatal,
                                        nacional o internacional.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Que pueda infringir cualquier patente, marca, secreto comercial, derecho de autor u otra clase de propiedad intelectual o derecho contractual de cualquier parte.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Que suplante la personalidad de cualquier persona o entidad, o distorsione su afiliación o vinculación con una persona o entidad.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Que promueva promociones, campañas políticas o de opinión, publicidad, correo no deseado, spam, solicitudes, cartas encadenadas o esquemas piramidales no solicitados.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Que realice o divulga información privada de terceros, incluido, a modo enunciativo pero no limitativo, cualquier tipo de dato protegido legalmente tal como direcciones, números de teléfono, direcciones de correo electrónico, números de la seguridad social
                                        y números de tarjetas de crédito.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Que pueda contener virus, datos corruptos o archivos peligrosos, perjudiciales o destructivos.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Que el contenido, a juicio de LOCANA, sea inaceptable o restrinja o impida a cualquier persona el uso y disfrute de las áreas interactivas o de la web o que afecte de manera adversa a la disponibilidad de sus recursos para otros usuarios (por ejemplo,
                                        uso de un tono demasiado exaltado, uso de mayúsculas o publicación continua de texto repetitivo) o que pueda exponer a LOCANA o a sus usuarios a cualquier daño o responsabilidad de cualquier tipo.
                                    </p>
                                </li>
                            </ol>
                            <p>
                                LOCANA no tiene obligación de examinar, editar ni controlar el contenido del usuario publicado en cualquier área interactiva y se reserva el derecho y tiene absoluta discreción para eliminar, examinar o editar cualquier contenido del usuario publicado
                                o almacenado en la web en cualquier momento y por cualquier razón sin necesidad de previo aviso.
                            </p>
                            <p>
                                El uso de las áreas interactivas u otras partes de la web que incumpla lo anteriormente expuesto, infringirá, también, la información legal de la Web, lo que podría conllevar, entre otros, suspensión o cancelación de sus derechos respecto al uso de las
                                áreas interactivas o de la web.
                            </p>
                        </li>
                        <li>
                            <p><b>Condiciones de acceso a la web</b></p>
                            <p>
                                LOCANA no garantiza que el acceso a la web no pueda ser interrumpido, sufrir retrasos, contener errores u omisiones o contener virus u otros elementos de análoga naturaleza.
                            </p>
                            <p>
                                LOCANA no se hace responsable de los daños y/o perjuicios de cualquier naturaleza que pudiera sufrir el usuario o un tercero derivados de:
                            </p>
                            <ol>
                                <li>
                                    <p>
                                        La falta de disponibilidad, mantenimiento o efectivo funcionamiento de la web, de sus servicios o de sus contenidos
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        El quebrantamiento ajeno de las medidas de seguridad de la web para sus contenidos o servicios o para el tratamiento de los datos personales almacenados por LOCANA, de manera no autorizado
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        En general, de cualquier uso de la web, sus servicios y/o contenidos que pudiera realizar el usuario o un tercero, en contra de las condiciones de acceso y uso de los mismos establecidas por LOCANA
                                    </p>
                                </li>
                            </ol>
                        </li>
                    </ol>
                </li>
                <li>
                    <h3>Propiedad intelectual o industrial</h3>
                    <ol>
                        <li>
                            <p><b>Propiedad intelectual e industrial de la web y sus elementos.</b></p>
                            <p>
                                LOCANA por sí o como cesionaria, es titular de todos los derechos de propiedad intelectual e industrial de la web, así como, de los elementos contenidos en la misma (a título enunciativo y no limitativo, textos y tipografías; logotipos e iconografías,
                                eslogan, combinaciones de colores, estructura y diseño, selección de materiales usados, software, incluyendo aquellos necesarios para su funcionamiento, acceso y uso o elementos análogos).
                            </p>
                            <p>
                                En consecuencia, queda expresamente prohibido y será perseguido, el uso o cualquier tipo de reproducción, distribución, comunicación pública o transformación de los mismos, ya sea ésta total o parcial de los elementos contenidos a los que se tenga acceso
                                en la web.
                            </p>
                            <p>
                                El usuario podrá visualizar los elementos contenidos en la web e incluso imprimirlos, copiarlos y almacenarlos en cualquier dispositivo o soporte, siempre y cuando sea, única y exclusivamente, para su uso personal y privado, quedando, por tanto, terminantemente
                                prohibida la reproducción pública, distribución, comunicación pública y transformación, puesta a disposición o cualquier otra forma de explotación.
                            </p>
                        </li>
                        <li>
                            <p><b>Propiedad intelectual de las ofertas en la web. </b></p>
                            <p>
                                LOCANA por sí o como cesionaria, son titular de los derechos de propiedad intelectual del material promocional del destino Islas Canarias en la web, (a título enunciativo y no limitativo; fotografías, videos, música, locución, textos o análogos).
                            </p>
                            <p>
                                El usuario podrá visualizarlos en la web e incluso imprimirlos, copiarlos y almacenarlos en cualquier dispositivo o soporte, siempre y cuando sea, única y exclusivamente, para su uso personal y privado, quedando, por tanto, terminantemente prohibida la
                                reproducción pública, distribución, comunicación pública y transformación, puesta a disposición o cualquier otra forma de explotación.
                            </p>
                            <p>
                                Quedará exceptuado de lo anterior, aquellos apartados específicamente diseñados al efecto en la web, dónde el usuario y terceros podrán utilizar el citado material promocional para su reproducción, distribución y comunicación pública con finalidad de
                                la promoción de la web, en cualquier lugar del mundo y sin limitación temporal alguna.
                            </p>
                        </li>
                        <li>
                            <p><b>Propiedad intelectual de otros contenidos ajenos en la web</b></p>
                            <p>
                                LOCANA no se hace responsable de los contenidos ajenos ofrecidos a través de la web por usuarios o terceros, ni los hace propios, ni garantiza los derechos de propiedad intelectual de los mismos.
                            </p>
                        </li>
                        <li>
                            <p><b>Inserción de contenidos de propiedad intelectual de LOCANA.</b></p>
                            <p>
                                Los contenidos o materiales promocionales de LOCANA que sean insertados en cualquier web, sites, blogs, plataformas de medios sociales o medio análogo, mediante visor de inserciones, podrán ser modificados o cancelados, sin previo aviso por parte de LOCANA,
                                sin que ello implique derecho alguno de los titulares de los citados medios o usuarios de éstos.
                            </p>
                        </li>
                        <li>
                            <p><b>Incidencias relacionadas con los derechos de propiedad intelectual.</b></p>
                            <p>
                                En caso de que el usuario estime que algún contenido de la web infringe cualquier derecho de propiedad intelectual, incluido el derecho de autor, podrá comunicar a LOCANA esta incidencia mediante el envío de un correo electrónico a la siguiente dirección
                                soporte.locana@gmail.com, especificando en el asunto “Incidencia derechos de propiedad intelectual”. Además, deberá especificar en el correo el fundamento de su incidencia, con citación de los derechos legales que posee
                                para formular esta incidencia.
                            </p>
                            <p>
                                Tras la verificación oportuna, LOCANA dará de baja el contenido de la web que infringe los citados derechos.
                            </p>
                        </li>
                    </ol>
                </li>
                <li>
                    <h3>Política de privacidad</h3>
                    <p>
                        La Política de Privacidad y Cookies de la web ha sido desarrollada teniendo en cuenta lo dispuesto por la Ley Orgánica 15/1999, de 13 de diciembre, de Protección de Datos de carácter personal, las Recomendaciones núm. 2/2001, de 17 de mayo, y 1/1999,
                        de 23 de febrero, emitidas por el Grupo Europeo del Artículo 29 de conformidad con la Directiva 95/46/CE del Parlamento Europeo y del Consejo de 24 de octubre y la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la
                        Información y Comercio Electrónico.
                    </p>
                    <ol>
                        <li>
                            <p><b>Protección de datos y confidencialidad</b></p>
                            <p>
                                LOCANA informa que, a través de la web, se procederá al tratamiento de los datos personales de los usuarios mediante formularios, conforme a la Información Adicional relativa a la Protección de Datos publicada en esta web.
                            </p>
                            <p>
                                LOCANA será responsable y garantizará la confidencialidad y seguridad respecto de los datos de carácter personal que recabe del usuario a través de la web y se compromete al cumplimiento de sus obligaciones de secreto con respecto a los datos de carácter
                                personal y al deber de tratarlos con la debida confidencialidad. A estos efectos, adoptará las medidas necesarias y adecuadas al nivel de seguridad exigible a los datos que el usuario le facilita, de conformidad con lo
                                previsto en el artículo 9 de la Ley Orgánica 15/1999, de 13 de diciembre, de Protección de Datos de carácter personal y su Reglamento de desarrollo, aprobado por el Real Decreto 1720/2007, de 21 de diciembre, para evitar
                                su alteración, pérdida, tratamiento o acceso no autorizado, así como en lo dispuesto en el Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo de 27 de Abril de 2016.
                            </p>
                            <ol>
                                <li>
                                    <p><b>Datos de registro suministrados por el usuario.</b></p>
                                    <p>
                                        Los datos de registro que se soliciten a los usuarios a través de la web, consistirán únicamente en aquellos estrictamente imprescindibles para identificarlos y prestarles el servicio solicitado
                                    </p>
                                    <p>El usuario debe:</p>
                                    <ol>
                                        <li>
                                            <p>
                                                proporcionar información exacta, actual y completa cuando se le solicite en cualquier formulario de registro en la web ("Datos de registro");
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                mantener y actualizar, sin dilación, los Datos de registro y cualquier otra información que proporcione a LOCANA para que éstos sean exactos, actuales y completos;
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                mantener la seguridad de su contraseña e identificación, si la hubiere, conforme a lo establecido en el punto 4.2
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                notificar a LOCANA inmediatamente sobre cualquier uso no autorizado de su cuenta o sobre cualquier otro problema de seguridad;
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                aceptar toda la responsabilidad por todas o parte de las actividades que se realicen en su cuenta
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                aceptar todos los riesgos derivados del acceso no autorizado a los Datos de registro y a cualquier otra información que proporcione a LOCANA.
                                            </p>
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <p><b>Privacidad de las claves de acceso</b></p>
                                    <p>
                                        LOCANA para el acceso y/o uso de los servicios o contenido de la web, solicitará el registro en el mismo, el usuario deberá establecer sus claves, las cuales serán confidenciales, secretas, personales e intransferibles para acceder y/o disfrutar del citado
                                        servicio o contenido como usuario registrado.
                                    </p>
                                    <p>
                                        Dicho acceso podrá ser bloqueado por LOCANA en el supuesto de que se produzcan un número determinado de errores sucesivos en la consignación de la clave. El bloqueo se levantará exclusivamente una vez que se haya comprobado satisfactoriamente la identidad
                                        del usuario.
                                    </p>
                                    <p>
                                        El usuario es responsable de la confidencialidad de sus claves de acceso y ningún caso, debe darse a conocer a terceras personas, ni dar facilidades para su descubrimiento grabándolas en cualquier tipo de soporte.
                                    </p>
                                    <p>
                                        LOCANA pondrá a disposición del usuario mecanismos de modificación de las claves de acceso cuando así lo disponga éste.
                                    </p>
                                </li>
                                <li>
                                    <p><b>Política de Cookies</b></p>
                                    <p>
                                        LOCANA informa que, a través de la web, no se hará uso de cookies, no recopilamos información de los usuarios. Las ofertas que visualizará en la página no dependerán de su navegación sino de lo que quiera buscar.
                                    </p>
                                </li>
                            </ol>
                        </li>
                    </ol>
                </li>
                <li>
                    <h3>Modificaciones de la información legal de la web.</h3>
                    <p>
                        LOCANA se reserva el derecho a modificar cualquier determinación de la presente información legal o cualquier política o directriz de la web, en cualquier momento, a criterio de LOCANA.
                    </p>
                    <p>
                        Las citadas modificaciones serán válidas y eficaces tras su publicación en la web, en la que figurará la fecha de actualización de la misma.
                    </p>
                    <p>
                        El uso de la web después de la publicación de las modificaciones constituirá la aceptación plena por el usuario de éstas, para lo que el usuario se compromete a verificar el estado de la información legal en cada momento.
                    </p>
                </li>
                <li>
                    <h3>Fuero</h3>
                    <p>
                        Los usuarios de la web renuncian, de forma expresa, a cualquier otro fuero que pudiera corresponderles, sin perjuicio de la competencia que resulte por ministerio de la ley y se someten al de los Juzgados y Tribunales de la ciudad de Santa Cruz de Tenerife
                        para la resolución de cualquier cuestión que pudiera suscitarse sobre la interpretación, aplicación y cumplimiento de estos términos y condiciones o resolución de cualquier controversia que pudiera suscitarse en relación a la web.
                    </p>
                </li>
        </ol>
    </div>
</div>