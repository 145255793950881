<div *ngIf="contents.length > 0 || (searchTerm ? searchTerm.length : '') > 0 || loading" class="p-2">

    <div [class.search]="fixed">
        <div class="row animated fast" [class.py-3]="!fixed" [class.search-fixed]="fixed">
            <div class="col-12">

                <input type="text" class="form-control" placeholder="Buscar..." (keyup)="search( txtTerm.value )" #txtTerm/>

            </div>
        </div>
    </div>

    <div *ngIf="fixed" class="py-4 my-1">
        <hr>
    </div>

    <app-card [contents]="contents" [type]="type" [animated]="animated"></app-card>

    <!-- <app-slider [contents]="contents"></app-slider> -->

</div>

<div *ngIf="(contents.length <= 0 && empty)" class="row animated fadeIn fast py-4">
    <div class="col-12">

        <div class="text-center h-80-vh">
            <h4 class="alert-heading">No se encuentran registros</h4>
            <i class="fas fa-exclamation-circle fa-2x"></i>
            <p class="mb-0">Pruebe con otra búsqueda o categoría</p>
        </div>

    </div>
</div>

<div class="row animated fadeIn fast py-4" *ngIf="loading && !empty">
    <div class="col-12">

        <div class="text-center h-80-vh">
            <h4 class="alert-heading">Cargando</h4>
            <i class="fa fa-spin fa-refresh fa-2x"></i>
            <p class="mb-0">Por favor espere</p>
        </div>

    </div>
</div>