import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';
import Swal from 'sweetalert2';

import { Offer } from '../../../models/offer.model';

import { SearchService } from '../../../services/search.service';
import { OfferService } from '../../../services/offer.service';
import { ModalImageService } from '../../../services/modal-image.service';

@Component({
  selector: 'app-offers-maintenance',
  templateUrl: './offers.maintenance.component.html',
  styles: [
  ]
})
export class OffersMaintenanceComponent implements OnInit, OnDestroy {

  public offers: Offer[] = [];
  public offersTemp: Offer[] = [];

  public loading: boolean = true;
  private imgSubs: Subscription;

  public totalOffers: number = 0;
  public from: number = 0;
  public limit: number = 5;
  public to: number = this.limit;

  public searchTerm: string;

  constructor( private offerService: OfferService,
               private modalImageService: ModalImageService,
               private searchService: SearchService ) { }

  ngOnDestroy(): void {
    this.imgSubs.unsubscribe();
  }

  ngOnInit(): void {
    this.loadOffers();

    this.imgSubs = this.imgSubs = this.modalImageService.newImage
      .pipe(delay(100))
      .subscribe( img => this.loadOffers() );
  }

  loadOffers() {

    this.loading = true;
    if ( this.searchTerm ) {
      this.searchService.search( 'offers', this.searchTerm, this.from, this.limit )
      .subscribe( ( resp: Offer[] ) => {
        this.offers = resp;
        this.loading = false;
      });
    } else {
      this.offerService.loadOffers( this.from, this.limit )
      .subscribe( ({offers, total}) => {
        this.totalOffers = total;
        this.offers = offers;
        this.offersTemp = offers;
        this.loading = false;
    });
    }
    
  }
  
  search( term: string ) {

    if ( term.length === 0 ) {
      return this.offers = this.offersTemp
    }

    this.searchTerm = term;
    this.from = 0;
    this.to = this.limit;

    this.searchService.search( 'offers', term, this.from, this.limit )
        .subscribe( ( resp: Offer[] ) => {
          this.offers = resp;
        });
  }

  deleteOffer( offer: Offer ) {
    Swal.fire({
      title: '¿Borrar Oferta?',
      text: `Esta a punto de borrar a ${ offer.name }`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Si, borrarlo'
    }).then((result) => {
      if (result.value) {
        this.offerService.deleteOffer( offer.id )
            .subscribe( resp => {
              this.loadOffers();
              Swal.fire( 'Borrado', offer.name, 'success' );
            });
          }
        });

  }

  pageChange( valor: number ) {

    this.from += valor * this.limit;
    this.to = this.from + this.limit;

    if ( this.from < 0 ) {
      this.from = 0;
    } else if ( this.from >= this.totalOffers ) {
      this.from -= valor * this.limit;
    }

    if ( this.to > this.totalOffers ) {
      this.to = this.totalOffers;
    } else if ( this.to <= 0 ) {
      this.to += this.limit;
    }

    this.loadOffers();

  }

}
 