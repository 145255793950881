import { Injectable, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';

import { User } from '../models/user.model';
import { AuthService } from './auth.service';
import { LoadUsers } from '../interfaces/load-users.interface';

const base_url = environment.base_url;

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public user: User;

  constructor(  private http: HttpClient,
                private authService: AuthService  ) {
                  this.user = this.authService.user;                  
                }

  get id(): string {
    return this.authService.user.id || '';
  }

  get role(): 'ADMIN_ROLE' | 'OFFERER_ROLE' | 'USER_ROLE' {
    return this.authService.user.role;
  }

  get token(): string {
    return localStorage.getItem('token') || '';
  }

  get headers() {
    return {
      headers: {
        'x-token': this.token
      }
    }
  }
  
  loadUsers( from: number = 0, limit: number = 10 ) {

    const url = `${ base_url }/users?from=${ from }&limit=${ limit }`;
    return this.http.get<LoadUsers>( url, this.headers )
            .pipe(
              map( (resp: {ok: boolean, users: User[], total: number }) => {
                return {
                  total: resp.total,
                  users: resp.users
                };
                resp
              })
            );
  }

  updateProfile( data: { email: string, nonamembre: string, role: string } ) {

    data = {
      ...data,
      role: this.authService.user.role
    };

    return this.http.put(`${ base_url }/users/${ this.id }`, data, this.headers );

  }

  updateFavs( data ) {

    return this.http.put(`${ base_url }/users/favs/${ this.id }`, data, this.headers );

  }

  deleteUser( user: User ) {

    const url = `${ base_url }/users/${ user.id }`;
    return this.http.delete( url, this.headers );

  }

  saveUser( user: User ) {

    return this.http.put(`${ base_url }/users/${ user.id }`, user, this.headers );

  }

  contentsReset() {

    const items = ['token', 'subscription', 'rafflesType', 'offererType', 'searchTags'];
    let keeper = [];
    let i = 0;

    for ( let item of items ) {
      if ( localStorage.getItem(item) ) {
        keeper.push( item );
        keeper.push( localStorage.getItem(item) );
      }
    }
    
    localStorage.clear();
    
    for ( let keep of keeper ) {
      for ( let item of items ) {
        if ( keep === item) {
          localStorage.setItem( item, keeper[ keeper.indexOf(keep) + 1 ] );
          break;
        }
      }
    }

  }

}
