import { Component, OnInit } from '@angular/core';
import { User } from '../../models/user.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MailService } from '../../services/mail.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-recover',
  templateUrl: './recover.component.html',
  styles: [
  ]
})
export class RecoverComponent implements OnInit {
  
  public formSubmitted = false;
  public user: User;
  public contactForm: FormGroup;

  constructor(  private router: Router,
                private fb: FormBuilder,
                private mailService: MailService  ) {}

  ngOnInit(): void {

    this.contactForm = this.fb.group({
      email: [ '' , [ Validators.required ] ]
    });

  }

  onSend() {
    
    this.mailService.forgotten( this.contactForm.value )
      .subscribe( resp => {

        this.router.navigateByUrl('/init/auth/login');
        Swal.fire('Enviado', 'La nueva contraseña llegará a tu correo electrónico', 'success');

      }, (err) => {

        Swal.fire('Error', err.error.msg, 'error' );

      });

  }

}
