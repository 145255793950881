import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

import { AuthGuard } from '../../guards/auth.guard';

import { MaintenanceComponent } from './maintenance/maintenance.component';
import { UsersMaintenanceComponent } from './users.maintenance/users.maintenance.component';
import { OfferersMaintenanceComponent } from './offerers.maintenance/offerers.maintenance.component';
import { OffersMaintenanceComponent } from './offers.maintenance/offers.maintenance.component';
import { RafflesMaintenanceComponent } from './raffles.maintenance/raffles.maintenance.component';



const routes: Routes = [

    {
        path: 'maintenance',
        component: MaintenanceComponent,
        canActivate: [ AuthGuard ],
        children: [
            { path: '', redirectTo: '', pathMatch: 'full' },
            { path: 'users', component: UsersMaintenanceComponent },
            { path: 'offerers', component: OfferersMaintenanceComponent },
            { path: 'offers', component: OffersMaintenanceComponent },
            { path: 'raffles', component: RafflesMaintenanceComponent },
            { path: '**', redirectTo: 'users', pathMatch: 'full' },
        ]
    },

];

@NgModule({
    imports: [ RouterModule.forChild(routes) ],
    exports: [ RouterModule ]
})
export class MaintenanceRoutingModule {}