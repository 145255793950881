import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';
import Swal from 'sweetalert2';

import { Raffle } from '../../../models/raffle.model';

import { SearchService } from '../../../services/search.service';
import { RaffleService } from '../../../services/raffle.service';
import { ModalImageService } from '../../../services/modal-image.service';

@Component({
  selector: 'app-raffles.maintenance',
  templateUrl: './raffles.maintenance.component.html',
  styles: [
  ]
})
export class RafflesMaintenanceComponent implements OnInit {

  public raffles: Raffle[] = [];
  public rafflesTemp: Raffle[] = [];

  public loading: boolean = true;
  private imgSubs: Subscription;

  public totalRaffles: number = 0;
  public from: number = 0;
  public limit: number = 5;
  public to: number = this.limit;

  public searchTerm: string;

  constructor( private raffleService: RaffleService,
               private modalImageService: ModalImageService,
               private searchService: SearchService ) { }

  ngOnDestroy(): void {
    this.imgSubs.unsubscribe();
  }

  ngOnInit(): void {
    this.loadRaffles();

    this.imgSubs = this.imgSubs = this.modalImageService.newImage
      .pipe(delay(100))
      .subscribe( img => this.loadRaffles() );
  }

  loadRaffles() {

    this.loading = true;
    if ( this.searchTerm ) {
      this.searchService.search( 'raffles', this.searchTerm, this.from, this.limit )
      .subscribe( ( resp: Raffle[] ) => {
        this.raffles = resp;
        this.loading = false;
      });
    } else {
      this.raffleService.loadRaffles( this.from, this.limit )
      .subscribe( ({raffles, total}) => {
        this.totalRaffles = total;
        this.raffles = raffles;
        this.rafflesTemp = raffles;
        this.loading = false;
    });
    }
    
  }
  
  search( term: string ) {

    if ( term.length === 0 ) {
      return this.raffles = this.rafflesTemp
    }

    this.searchTerm = term;
    this.from = 0;
    this.to = this.limit;

    this.searchService.search( 'raffles', term, this.from, this.limit )
        .subscribe( ( resp: Raffle[] ) => {
          this.raffles = resp;
        });
  }

  deleteRaffle( raffle: Raffle ) {
    Swal.fire({
      title: '¿Borrar Oferta?',
      text: `Esta a punto de borrar a ${ raffle.name }`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Si, borrarlo'
    }).then((result) => {
      if (result.value) {
        this.raffleService.deleteRaffle( raffle.id )
            .subscribe( resp => {
              this.loadRaffles();
              Swal.fire( 'Borrado', raffle.name, 'success' );
            });
          }
        });

  }

  pageChange( valor: number ) {

    this.from += valor * this.limit;
    this.to = this.from + this.limit;

    if ( this.from < 0 ) {
      this.from = 0;
    } else if ( this.from >= this.totalRaffles ) {
      this.from -= valor * this.limit;
    }

    if ( this.to > this.totalRaffles ) {
      this.to = this.totalRaffles;
    } else if ( this.to <= 0 ) {
      this.to += this.limit;
    }

    this.loadRaffles();

  }

}
