<div class="navbar-footer-m"></div>
<!--  fixed-bottom -->
<footer class="footer mt-auto py-3 bg-primary text-center animated text-white">
    <div class="container">
        <span class="text-white">© Locana {{ year }}</span>
    </div>
    <hr class="my-2 border-light">
    <div>
        <ul class="no-li-bullets px-0">
            <li>
                <a class="text-white" routerLink="/init/about">¿Quienes somos?</a>
            </li>
            <li>
                <a class="text-white" routerLink="contact">Contacto</a>
            </li>
        </ul>
    </div>
</footer>