import { Component, Input, OnInit } from '@angular/core';
import { User } from '../../models/user.model';
import { Offerer } from '../../models/offerer.model';
import { Offer } from '../../models/offer.model';
import { DateFormatService } from '../../services/date-format.service';
import { AuthModule } from '../../auth/auth.module';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styles: [
  ]
})
export class CardComponent implements OnInit {

  @Input() contents: User[] | Offerer[] | Offer[] | any[] = [];
  @Input() type: 'users'|'offerers'|'offers'|'raffles';
  @Input() animated: boolean = true;

  // public typeSlice: string;
  public windowWidth: number;
  public maxLength: number = 200;
  public user: User;

  constructor(  private dateFormatService: DateFormatService,
                private authService: AuthService,  ) {

    this.windowWidth = window.innerWidth;
    this.user = this.authService.user;

   }

  ngOnInit(): void {

    if ( this.windowWidth < 576) {
      this.maxLength = this.windowWidth * 19.8 / 100;
    } else if ( this.windowWidth < 768 ) {
      this.maxLength = this.windowWidth * 7.5 / 100;
    } else if ( this.windowWidth < 992 ) {
      this.maxLength = this.windowWidth * 5.75 / 100;
    } else if ( this.windowWidth < 1200 ) {
      this.maxLength = this.windowWidth * 5 / 100;
    } else if ( this.windowWidth < 1500 ) {
      this.maxLength = this.windowWidth * 3.8 / 100;
    } else {
      this.maxLength = this.windowWidth * 3.8 / 100;
    }

  }

  dateTime( date ) {
    
    const now = this.dateFormatService.getDateTime();
    const end = this.dateFormatService.getDateTime( date );
    
    if ( !end ) { 
      return 'Sin fecha';
    } else if ( now.date < end.date ) {
      return 'date';
    } else if ( now.date === end.date ) {      
      if ( now.time <= end.time ) {
        return 'time';
      } else {
        return 'Finalizado';
      }
    } else if ( now.date > end.date) {
      return 'Finalizado';
    } else {
      return 'Sin fecha';
    }

  }

  dateDiff( endDate: Date ) {

    return this.dateFormatService.dateDiff( endDate );

  }

  finished( endDate: Date ) {

    return this.dateFormatService.nowVsDate( endDate );

  }

}
