import { Component, OnInit, ViewChild, Pipe } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import Swal from 'sweetalert2';

import { UploadService } from '../../services/upload.service';

import { AuthService } from '../../services/auth.service';
import { Offer } from '../../models/offer.model';
import { User } from 'src/app/models/user.model';
import { OfferService } from '../../services/offer.service';
import { MainGridComponent } from '../../components/grids/main-grid/main-grid.component';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { DateFormatService } from '../../services/date-format.service';
import { environment } from '../../../environments/environment';


@Component({
  selector: 'app-offer',
  templateUrl: './offer.component.html',
  styles: [
  ]
})
export class OfferComponent implements OnInit {
  
  @ViewChild(MainGridComponent) mainGridComponent: MainGridComponent;

  public offerForm: FormGroup;
  public offer: Offer;
  public user: User;
  public newImg: File;
  public tempImg: any = null;

  public owner: boolean = false;
  public edit: boolean = false;

  public tags: string[] = ['general'];
  public tagsList: string[] = ['Comida','Deporte','Moda','Ocio','Otros']

  public fav: boolean = false;
  public regText: boolean = false;
  public contacts: string[] = ['email','phone','whatsApp', 'webSite'];
  public contactsSp: string[] = ['Email','Teléfono','WhatsApp', 'Sitio web'];
  public contactsRef: string[] = ['mailTo:','tel:','https://api.whatsapp.com/send?phone=', 'https://'];
  public contactsIcon: string[] = ['fas fa-envelope','fas fa-phone','fab fa-whatsapp', 'fas fa-globe'];
  public contactShow: string;
  public contactIndex: number = this.contacts.length + 1;

  public windowWidth: number;
  public maxLength: number = 200;

  public base_url: string = environment.base_url;
  public regBody: string;
  
  constructor( private fb: FormBuilder,
               private offerService: OfferService,
               private authService: AuthService,
               private fileUploadService: UploadService,
               private activatedRoute: ActivatedRoute,
               private router: Router,
               private userServive: UserService,
               private dateFormatService: DateFormatService, ) {

                this.user = this.authService.user;
                this.windowWidth = window.innerWidth;

              }

  async ngOnInit() {
    
    if ( this.windowWidth < 575) {
      this.maxLength = this.windowWidth * 5 / 100;
    } else if ( this.windowWidth < 992 ) {
      this.maxLength = this.windowWidth * 4.5 / 100;
    } else if ( this.windowWidth < 768 ) {
      this.maxLength = this.windowWidth * 3.5 / 100;
    } else {
      this.maxLength = this.windowWidth * 3.75 / 100;
    }
    
    this.offerForm = this.fb.group({
      name: [ '', Validators.required ],
      price: [ '', [ Validators.required, Validators.min(0), Validators.pattern('[0-9.,]*') ] ],
      finalPrice: [ '', [ Validators.required, Validators.min(0), Validators.max(100), Validators.pattern('[0-9.,]*') ] ],
      startDate: [ '' ],
      endDate: [ '' ],
      startTime: [ '' ],
      endTime: [ '' ],
      limit: [ '', [ Validators.min(1), Validators.pattern('[0-9]*') ] ],
      waitTime: [ '', [ Validators.pattern('[0-9]*') ] ],
      // executionTime: [ '', [ Validators.min(0), Validators.pattern('[0-9]*') ] ],
      maxUses: [ '', [ Validators.min(1), Validators.pattern('[0-9]*') ] ],
      useConditions: [ '', [ Validators.required, Validators.minLength(8) ] ],
      description: [ '', Validators.minLength(8) ],
      offerer: [ '' ],
      img: [ 'no-img' ],
      tags: [ this.tags ],
    });

    const { id } = this.activatedRoute.snapshot.params;
    
    this.offerService.loadOffersById( id )
          .subscribe( ( offer ) => {
    
            this.offer = offer;       
            
            this.tags = offer.tags;
            if ( this.tags.length === 0 ) {
              this.tags = ['general'];
            }

            const start = this.dateFormatService.getDateTime( this.offer.startDate );
            const end = this.offer.endDate === null ? null : this.dateFormatService.getDateTime( this.offer.endDate );

            this.offerForm = this.fb.group({
              name: [ this.offer.name, Validators.required ],
              price: [ this.offer.price, [ Validators.required, Validators.min(0), Validators.pattern('[0-9.,]*') ] ],
              finalPrice: [ this.offer.finalPrice, [ Validators.required, Validators.min(0), Validators.pattern('[0-9.,]*') ] ],
              startDate: [ start ? start.date : '' ],
              startTime: [ start ? start.time : '' ],
              endDate: [ end ? end.date : '' ],
              endTime: [ end ? end.time : '' ],
              limit: [ this.offer.limit, [ Validators.min(1), Validators.pattern('[0-9]*') ] ],
              waitTime: [ this.offer.waitTime, [ Validators.pattern('[0-9]*') ] ],
              maxUses: [ this.offer.maxUses, [ Validators.min(1), Validators.pattern('[0-9]*') ] ],
              // executionTime: [ this.offer.executionTime, [ Validators.min(0), Validators.pattern('[0-9]*') ] ],
              useConditions: [ this.offer.useConditions, [ Validators.required, Validators.minLength(8) ] ],
              description: [ this.offer.description ],
              offerer: [ this.offer.offerer._id ],
              img: [ this.offer.img ],
              tags: [ ]
            });           
            
            this.regBody = `https://locana.es/user/offer-checker/${this.offer.reg}/${this.user.reg}`
            
            try {
              if ( this.user.offerFavs.includes( id ) ) {
                this.fav = true;            
              }
            } catch {}
    
            if ( this.user.id === this.offer.user._id || this.user.role === "ADMIN_ROLE" ) {
              this.owner = true;
            }
            
          } );

  }
  
  ngOnDestroy() {
    this.fav = false;
  }

  saveOffer() {
    Swal.fire('Actualizando', 'Modificando datos...', 'info');
    if ( this.newImg ) {

      this.updateImg();
      
    } else {

      this.updateOffer();

    }

  }

  updateOffer() {
    
    const formControls = this.offerForm.controls;
    const now = this.dateFormatService.getDateTime();

    if ( formControls['waitTime'].value > 0 && formControls['maxUses'].value === null || formControls['waitTime'].value > 0 && formControls['maxUses'].value <= 0 ) {
      formControls['maxUses'].setValue( 1 );
    }

    formControls['startDate'].setValue( new Date( ( ( formControls['startDate'].value !== '' ) ? formControls['startDate'].value : now.date ) + ' ' + ( formControls['startTime'].value.length > 3 ? formControls['startTime'].value : now.time ) ) );
    formControls['endDate'].setValue( new Date( formControls['endDate'].value !== '' ? (formControls['endDate'].value + ' ' + ( formControls['endTime'].value.length > 3 ? formControls['endTime'].value : '23:59' ) ) : '' ) );
    formControls['tags'].setValue( this.tags );
    
    this.offerService.updateOffer( this.offer.id, this.offerForm.value )
        .subscribe( () => {
          this.userServive.contentsReset();
          Swal.fire('Guardado', 'Cambios fueron guardados', 'success');
          this.router.navigate( [ '/user/reload' ] )
          setTimeout( () => { 
            this.router.navigate( [ `/user/offer`, this.offer.id ] ); 
          }, 100)

        }, (err) => {

          Swal.fire('Error', err.error.msg, 'error');

        });
      
    formControls['tags'].setValue( '' );

  }

  updateImg() {

    this.fileUploadService
      .updateImg( this.newImg, 'offers', this.offer.id )
      .then( ( img: string ) => {
        
        if (!img) {
          img = 'no-img'
        } 

        this.offerForm.controls[ 'img' ].setValue( img );
    
        this.updateOffer();

      }).catch( err => {

        console.log(err);
        Swal.fire('Error', 'No se pudo subir la imagen', 'error');
        
      });
  
  }

  changeImg( event: Event ) {
    const target = event.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];
    this.newImg = file;

    if ( !file ) {
      return this.tempImg = null;
    }

    const reader = new FileReader();
    reader.readAsDataURL( file );

    reader.onloadend = () => {
      this.tempImg = reader.result;
    }

  }

  delete() {

    Swal.fire({
      title: 'Eliminar Oferta?',
      text: `Esta a punto de Eliminar "${ this.offer.name }"`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Si, borrarla'
    }).then((result) => {
      if (result.value) {

        this.offerService.deleteOffer( this.offer.id )
          .subscribe( resp => {
  
            this.userServive.contentsReset();
            
            Swal.fire(
              'Oferta Eliminada',
              `"${ this.offer.name }" fue eliminada correctamente`,
              'success'
            );

            this.router.navigateByUrl('/user/offers');

          });

      }
    });

  }

  onTagsInput( tagsInput: string ) {
    
    let valid: boolean = true;

    if ( !this.tagsList.includes( tagsInput ) ) {
      return;
    }

    tagsInput = tagsInput.toLowerCase();

    for( let tag of this.tags ) {
      if ( tag === tagsInput ) {
        valid = false;
        return;
      }
    }
    
    if ( !valid ) {
      return;
    }
    
    for( let tag of this.tags ) {
      if ( tag === 'general' ) {        
        const index = this.tags.indexOf( tag );
        this.tags.splice( index, 1 );
      }
    }

    this.tags = [ ...this.tags, tagsInput ];

  }

  onDeleteTag( tagsDeleted: string ) {

    for( let tag of this.tags ) {
      
      if ( tag === tagsDeleted ) {        
        const index = this.tags.indexOf( tag );
        this.tags.splice( index, 1 )
        return;
      }

    }
  }

  addFavs() {
    
    const { id } = this.activatedRoute.snapshot.params;
    let favs = [];
    
    try {
      if ( this.user.offerFavs.includes( id ) ) {              
        favs = this.user.offerFavs;
        favs = favs.filter(function(fav) { return fav !== id });
        this.fav = false;
      } else {        
        favs = [ ...this.user.offerFavs, id ];
        this.fav = true;
      }
    } catch {
      Swal.fire('Error', 'Hablar con el Administrador','error');
      return;
    }
    
    const data = { offerFavs: favs, offererFavs: this.user.offererFavs, id: id };
    
    this.userServive.updateFavs( data )
          .subscribe( async() => {
            return;
          }, (err) => {
      
            Swal.fire('Error', err.error.msg, 'error');
      
          });

    this.user.offerFavs = favs;
    
    localStorage.removeItem('favsContents');

  }

  report() {

    Swal.fire({
      title: 'Reportar Oferta?',
      text: `Quiere Reportar la oferta "${ this.offer.name }"`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Si, Reportar'
    }).then((result) => {
          if (result.value) {
            this.router.navigateByUrl('/user/contact/' + this.offer.reg);
          }
        });

  }

}
