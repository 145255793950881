import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { MainGridComponent } from '../../components/grids/main-grid/main-grid.component';
import { ActivatedRoute, Router } from '@angular/router';
import { RaffleService } from '../../services/raffle.service';
import { AuthService } from '../../services/auth.service';
import { User } from '../../models/user.model';
import { ModalService } from '../../services/modal.service';
import { RedsysService } from '../../services/redsys.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styles: []
})
export class HomeComponent implements OnInit {

  @ViewChild( MainGridComponent ) mainGridComponent: MainGridComponent;
  @ViewChild( 'catMenu' ) catMenu: ElementRef;
  
  public collapsedCat: boolean = false;
  public collapsedCatTemp: boolean = false;
  public tagsList: string[] = ['Comida','Deporte','Moda','Ocio','Salud','Otros'];
  public type: string;
  public renew: boolean = true;

  public slide: boolean = false;
  public startPointX: number;
  public startPointY: number;
  
  public slidePage: string[] = ['offers', 'offerers', 'raffles', 'favs'];

  public selectedType: 'raffles'|'myRaffles'|string;

  public user: User;

  public windowWidth: number;

  constructor(  private activatedRoute: ActivatedRoute,
                private router: Router,
                private raffleService: RaffleService,
                private authService: AuthService,
                private modalService: ModalService,
                private redsysService: RedsysService  ) {

                  this.user = this.authService.user;
                  this.windowWidth = window.innerWidth;

                }

  ngOnInit() {

    this.raffleService.winnersUpdate().subscribe();
    
    const nexDate = new Date(this.user.payment.nextPay);
    const now = new Date();
    
    if ( this.user.payment.active && nexDate < now ) {
      this.redsysService.noRenovation().subscribe();
    }

    this.type = this.activatedRoute.routeConfig.path;

    this.selectedType = localStorage.getItem( 'rafflesType' ) || 'raffles';
    
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;

    this.subsModalInit()

  }

  catClick( tagInput: string ) {
    
    let valid: boolean = true;

    if ( !this.tagsList.includes( tagInput ) ) {
      return;
    }

    tagInput = tagInput.toLowerCase();
    
    let nowTags = [];

    if ( localStorage.getItem('searchTags') ) {
      nowTags = localStorage.getItem('searchTags').split(';');
    }

    for( let tag of nowTags ) {
      if ( tag === tagInput ) {
        valid = false;
        return;
      }
    }
    
    for( let tag of nowTags ) {
      if ( tag === 'general' ) {        
        const i = nowTags.indexOf( tag );
        nowTags.splice( i, 1 );
      }
    }
  
    this.mainGridComponent.restartTagsFilter();
    const oldTags = nowTags.join(';');
    let newTags = '';
    
    if ( oldTags ) {    
      newTags = oldTags + ';' + tagInput;
    } else {
      newTags = tagInput;
    }
            
    localStorage.setItem('searchTags', newTags);

    this.mainGridComponent.renew = true;
    this.mainGridComponent.loadContents();

  }

  @HostListener( 'document: click', ['$event'] )
  clickOut( event ) {
    
    if ( this.collapsedCatTemp ) {
      if ( this. collapsedCat ) {
        if (this.catMenu.nativeElement.contains( event.target )) {
          return this.collapsedCatTemp = true;
        }
        this.collapsedCatTemp = false;
        return  this.collapsedCat = false;
      }
    }

    this.collapsedCatTemp = this.collapsedCat;

  }

  // @HostListener('mousedown', ['$event'])
  // onMouseDown() {  
  //   this.slide = true;
  // }

  // @HostListener('mouseup', ['$event'])
  // onMouseUp() {
  //   this.slide = false;
  // }

  // @HostListener('mousemove', ['$event'])
  // onMouseMove( event: MouseEvent ) {
  //   if ( this.slide ) { 
  //     let index = this.slidePage.indexOf(this.type)
  //     event.preventDefault();
  //     if ( event.movementX > 20 && Math.abs(event.movementY) < 2 ) {
  //       if ( index === 0 ) {
  //         index = this.slidePage.length;
  //       }
  //       this.router.navigateByUrl('/user/' + this.slidePage[index - 1]);
  //     } else if ( event.movementX < -10 && Math.abs(event.movementY) < 2) {
  //       this.router.navigateByUrl('/user/' + this.slidePage[index + 1]);
  //     }
  //   }
  // }
  
  @HostListener('touchstart', ['$event'])
  onTouchStart( event: TouchEvent ) {
    this.startPointX = event.touches.item(0).screenX;
    this.startPointY = event.touches.item(0).screenY;
    this.slide = true;
  }
  
  @HostListener('touchmove', ['$event'])
  onTouchMove( event: TouchEvent ) {
    if ( this.slide ) {
      let index = this.slidePage.indexOf(this.type)
      if ( (event.touches.item(0).screenX - this.startPointX) > 50 && Math.abs(event.touches.item(0).screenY - this.startPointY) < 20 ) {
        if ( index === 0 ) {
          index = this.slidePage.length;
        }
        this.router.navigateByUrl('/user/' + this.slidePage[index - 1])
      } else if ( (event.touches.item(0).screenX - this.startPointX) < -50 && Math.abs(event.touches.item(0).screenY - this.startPointY) < 20) {
          this.router.navigateByUrl('/user/' + this.slidePage[index + 1])
      }
    }
  }

  typeChange() {

    if ( this.selectedType === 'raffles' ) {
      this.selectedType='myRaffles'
    } else {
      this.selectedType='raffles'
    }
    
    localStorage.setItem( 'rafflesType', this.selectedType )

    const MG = this.mainGridComponent;

    MG.pageType = this.selectedType;
    MG.empty = false;
    MG.from = 0;
    MG.to = MG.limit;
    MG.contents = [];
    MG.contentsTemp = [];
    if ( MG.searchTerm ) {
      MG.loadContents();
      MG.search( MG.searchTerm );
    } else {
      MG.loadContents();
    }

  }

  async subsModalInit() {

    const now = new Date();
    const nextPay = new Date(this.user.payment.nextPay);

    if ( this.user.level === 0 || ( this.user.payment.active && this.user.payment.nextLevel > 0 && nextPay < now ) ) {
      
      const subs = await localStorage.getItem( 'subscription' );

      if ( parseInt( subs ) < now.getTime() || !subs) {
        
        this.modalService.openModal();
        localStorage.setItem( 'subscription', String( now.getTime() + 1000 * 60 * 60 * 24 ) );

      }
      
    }

  }
  
}
