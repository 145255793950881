<div class="row animated fadeIn fast">
    <div class="col-12">
        <div class="card">
            <div class="card-body">

                <input type="text" class="form-control" placeholder="Buscar..." (keyup)="search( txtTerm.value )" #txtTerm/>

            </div>
        </div>
    </div>
</div>

<div class="row animated fadeIn fast" *ngIf="loading">
    <div class="col-12">

        <div class="alert alert-info text-center">
            <h4 class="alert-heading">Cargando</h4>
            <i class="fa fa-spin fa-refresh fa-2x"></i>
            <p class="mb-0">Por favor espere</p>
        </div>

    </div>
</div>


<div class="row animated fadeIn fast" *ngIf="!loading">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Total de Usuarios ({{ totalUsers }})</h4>
                <h6 class="card-subtitle">Usurios registrados en mi aplicación</h6>
                <div class="table-responsive">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th class="w100">Avatar</th>
                                <th>Correo</th>
                                <th>Usuario</th>
                                <th class="w140">Rol</th>
                                <th>Reg</th>
                                <!-- <th class="w100">Auth</th> -->
                                <th class="text-nowrap w100">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let user of users">
                                <td class="text-center">
                                    <img [src]="user.img | img: 'users'" alt="Avatar de user" class="avatar">
                                </td>
                                <td>{{ user.email }}</td>
                                <td>{{ user.nickName }}</td>

                                <td>
                                    <select class="form-control w-auto" [(ngModel)]="user.role" (change)="changeRole(user)">
                                        <option value="ADMIN_ROLE">Administrador</option>
                                        <option value="OFFERER_ROLE">Proveedor</option>
                                        <option value="USER_ROLE">Usuario</option>
                                    </select>
                                </td>

                                <td>{{ user.reg }}</td>

                                <!-- <td>
                                    <span *ngIf="user.google" class="label label-danger">Google</span>
                                    <span *ngIf="!user.google" class="label label-info">Email</span>
                                </td> -->

                                <td class="text-nowrap text-center">
                                    <a data-toggle="tooltip" data-original-title="Borrar" class="cursor" (click)="deleteUser(user)">
                                        <i class="fa fa-close text-danger"></i>
                                    </a>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>

                <div class="row">
                    <!-- <div *ngIf="txtTerm.value.length === 0" class="row"> -->
                    <div class="col-6">
                        <button (click)="pageChange(-1)" class="btn btn-secondary me-3 mb-2">Anterior</button>
                        <button (click)="pageChange(1)" class="btn btn-secondary mb-2">Siguiente</button>
                    </div>
                    <div class="col-6 text-right">
                        {{ from }} - {{ to }}
                    </div>
                </div>


            </div>
        </div>
    </div>
</div>