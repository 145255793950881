<div *ngIf="((searchTags ? searchTags.length : 0) > 0) && (['raffles', 'myRaffles', 'favs'].indexOf(pageType) < 0)" class="input-group">
    <div class="my-auto ms-0">
        <span *ngFor="let tag of searchTags" class="badge rounded-pill bg-secondary h-50 me-1">{{ tag | titlecase }}<i (click)="deleteTag(tag)" class="fas fa-times-circle ms-1 pointer"></i></span>
    </div>
</div>

<div *ngIf="(contents ? contents.length : 0)> 0 || (searchTerm ? searchTerm.length : 0) > 0" class="p-2">

    <div [class.search]="fixed">
        <div class="row animated fast" [class.py-3]="!fixed" [class.search-fixed]="fixed">
            <div class="col-12">

                <input (keyup)="search( txtTerm.value )" type="text" class="form-control search-input" placeholder="Buscar..." #txtTerm/>

            </div>
        </div>
    </div>

    <div *ngIf="fixed" class="py-4 my-1">
        <hr>
    </div>

    <app-card [contents]="contents" [type]="type" [animated]="animated"></app-card>

</div>

<div *ngIf="((contents ? contents.length : 0) <= 0 && empty)" class="row animated fadeIn fast py-4">
    <div class="col-12">

        <div class="text-center h-80-vh">
            <h4 class="alert-heading">No se encuentran registros</h4>
            <i class="fas fa-exclamation-circle fa-2x"></i>
            <p class="mb-0">Pruebe con otra búsqueda o categoría</p>
        </div>

    </div>
</div>

<div class="row animated fadeIn fast py-4" *ngIf="loading && !empty">
    <div class="col-12">

        <div class="text-center h-80-vh">
            <h4 class="alert-heading">Cargando</h4>
            <i class="fa fa-spin fa-refresh fa-2x"></i>
            <p class="mb-0">Por favor espere</p>
        </div>

    </div>
</div>