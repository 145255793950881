import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { User } from '../../models/user.model';
import { RedsysService } from '../../services/redsys.service';

@Component({
  selector: 'app-pay-cards',
  templateUrl: './pay-cards.component.html',
  styles: [
  ]
})
export class PayCardsComponent implements OnInit {
  
  @Input() level: number;
  @Output() levelSelected: EventEmitter< number > = new EventEmitter();
  @Output() typeSelected: EventEmitter< string > = new EventEmitter();

  public amount: number;
  public user: User;
  public includes: string[][] = [
    ['Acceso a ofertas', 'Sin ofertas especiales'],
    ['Acceso anticipado 1 hora', 'Sin ofertas especiales'],
    ['Acceso anticipado 1 día', 'Ofertas especiales']
  ];
  public type: string = 'monthly';

  constructor(  private authService: AuthService,
                private redsysService: RedsysService  ) { 

                  this.user = this.authService.user;  
                                  
                }

  ngOnInit(): void {

    this.amountCalc();

  }

  amountCalc() {

    if (this.level === 1) {
      this.amount = 100;
    } else if (this.level === 2) {
      this.amount = 200;
    } else if (this.level === 3) {
      this.amount = 500;
    } else {
        return
    }

    if (this.type === 'yearly') {
      this.amount = this.amount * 11;
    }

  }

  onClick() {

    this.typeSelected.emit(this.type)
    this.levelSelected.emit(this.level)

  }

  btnText() {

    return this.redsysService.downGradeQuestion( this.level, this.type, this.user );

  }

}
