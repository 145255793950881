import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home/home.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ComponentsModule } from '../components/components.module';
import { RouterModule } from '@angular/router';
import { ProfileComponent } from './profile/profile.component';
import { SharedModule } from '../shared/shared.module';
import { PagesComponent } from './pages/pages.component';
import { NewOffererComponent } from './new-offerer/new-offerer.component';
import { MaintenanceModule } from './maintenance/maintenance.module';
import { PipesModule } from '../pipes/pipes.module';
import { OfferComponent } from './offer/offer.component';
import { OffererComponent } from './offerer/offerer.component';
import { ReloadComponent } from './reload/reload.component';
import { ContactComponent } from './contact/contact.component';
import { OfferCheckerComponent } from './offer-checker/offer-checker.component';
import { FavsComponent } from './favs/favs.component';
import { QRCodeModule } from 'angularx-qrcode';
import { RaffleComponent } from './raffle/raffle.component';
import { PricingComponent } from './pricing/pricing.component';



@NgModule({
  declarations: [
    PagesComponent,
    HomeComponent,
    ProfileComponent,
    NewOffererComponent,
    OfferComponent,
    OffererComponent,
    ContactComponent,
    ReloadComponent,
    OfferCheckerComponent,
    FavsComponent,
    RaffleComponent,
    PricingComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ComponentsModule,
    RouterModule,
    SharedModule,
    MaintenanceModule,
    PipesModule,
    QRCodeModule
  ],
  exports: [
    HomeComponent,
    ProfileComponent,
    NewOffererComponent,
    OfferComponent,
    OffererComponent,
    ContactComponent,
    ReloadComponent,
    OfferCheckerComponent,
    FavsComponent,
    RaffleComponent,
    PricingComponent
  ]
})
export class PagesModule { }
