import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { InitComponent } from '../init/init/init.component';
import { ContactComponent } from '../init/contact/contact.component';
import { InitHomeComponent } from '../init/init-home/init-home.component';
import { LoginComponent } from '../auth/login/login.component';
import { RegisterComponent } from '../auth/register/register.component';
import { RecoverComponent } from '../auth/recover/recover.component';
import { AuthRoutingModule } from '../auth/auth.routing.module';
import { AboutComponent } from './about/about.component';
import { TermsComponent } from './terms/terms.component';



const routes: Routes = [

    { 
        path: 'init',
        component: InitComponent,
        children: [
            { path: '', redirectTo: 'home', pathMatch: 'full' },
            { path: 'home', component: InitHomeComponent },
            { path: 'contact', component: ContactComponent },
            // { path: 'about', component: AboutComponent },
            { path: 'terms', component: TermsComponent },
            {   
                path: 'auth',
                children:[ 
                    { path: '', redirectTo: 'login', pathMatch: 'full' },
                    { path: 'login', component: LoginComponent },
                    { path: 'register', component: RegisterComponent },
                    { path: 'recover', component: RecoverComponent },
                    { path: '**', redirectTo: 'login', pathMatch: 'full' },
                ] 
            },
            { path: '**', redirectTo: 'home', pathMatch: 'full' },
        ]
    }

];

@NgModule({
    imports: [ RouterModule.forChild(routes) ],
    exports: [ RouterModule ]
})
export class InitRoutingModule {}