import { Component, OnInit } from '@angular/core';
import { RedsysService } from '../../services/redsys.service';

@Component({
  selector: 'app-pay-success',
  templateUrl: './pay-success.component.html',
  styles: [
  ]
})
export class PaySuccessComponent implements OnInit {

  constructor(  private redsysService: RedsysService  ) { }

  ngOnInit(): void {}

}
