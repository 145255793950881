<div class="m-auto mt-5 w-70">

    <div class="d-flex align-center position-relative">
        <div>
            <h1 *ngIf="type === 'offers'" class="right-line" [class.pe-1]="windowWidth < 350" [class.pe-2]="windowWidth >= 350">Ofertas</h1>
            <h1 *ngIf="type === 'offerers'" class="right-line" [class.pe-1]="windowWidth < 350" [class.pe-2]="windowWidth >= 350">Locales</h1>
            <h1 *ngIf="type === 'raffles'" class="right-line" [class.pe-1]="windowWidth < 350" [class.pe-2]="windowWidth >= 350"><span *ngIf="selectedType === 'myRaffles'">Mis</span> Sorteos</h1>
            <button *ngIf="type==='raffles'" (click)="typeChange()" class="btn btn-outline-secondary position-absolute end-0 top-0 bottom-0" [class.my-1]="windowWidth >= 575 && windowWidth < 1000" [class.my-2]="windowWidth >= 1000">
                <div *ngIf="selectedType==='raffles'">
                    <i class="fas fa-sign-in-alt me-2"></i><i class="fas fa-user-alt" [class.me-2]="windowWidth >= 400"></i><span *ngIf="windowWidth >= 400">Mis Sorteos</span>
                </div>
                <div *ngIf="selectedType==='myRaffles'">
                    <i class="fas fa-sign-in-alt me-2"></i><i class="fas fa-users" [class.me-2]="windowWidth >= 400"></i><span *ngIf="windowWidth >= 400">Sorteos</span>
                </div>
            </button>
        </div>

        <div class="position-relative justify-content-bottom">
            <a (click)="collapsedCat=!collapsedCat" class="nav-link pointer text-secondary pb-1" [class.px-1]="windowWidth < 350"><i class="far fa-caret-square-down fa-1-5x" [class.fas]="collapsedCat"></i></a>
        </div>
    </div>
    <div class="position-relative">
        <div class="cat-pos-middle">
            <ul class="show-none cat-pos mb-0" [class.show-opt]="collapsedCat" aria-labelledby="dropdownMenuOffset" #catMenu>
                <p class="list-title">Categorías</p>
                <hr class="mt-2 mb-1">
                <li *ngFor="let tag of tagsList"><a (click)="catClick(tag)" class="dropdown-item pointer cat-item">{{ tag | titlecase }}</a></li>
            </ul>
        </div>
    </div>

    <app-main-grid [pageType]="type"></app-main-grid>

</div>

<!-- <app-modal-subscription [user]="user"></app-modal-subscription> -->