import { Component, OnInit, Input, HostListener } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { SearchService } from '../../../services/search.service';
import { ModalImageService } from '../../../services/modal-image.service';
import { Subscription } from 'rxjs';
import { User } from '../../../models/user.model';
import { delay } from 'rxjs/operators';
import { OffererService } from '../../../services/offerer.service';
import { Offerer } from 'src/app/models/offerer.model';
import { OfferService } from '../../../services/offer.service';
import { Offer } from '../../../models/offer.model';
import { Router } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { Raffle } from '../../../models/raffle.model';


@Component({
  selector: 'app-settings-grid',
  templateUrl: './settings-grid.component.html',
  styles: []
})
export class SettingsGridComponent implements OnInit {

  @Input() type: 'users'|'offerers'|'offers';
  public typeSlice: string;
  public filterType: string = 'user';

  public totalContents: number = 0;
  public contents: User[] | Offerer[] | Offer[] | any[] = [];
  public contentsTemp: User[] | Offerer[] | Offer[] | any[]  = [];
  
  public imgSubs: Subscription;
  
  public loading: boolean = true;
  public scrollCheck: boolean = true;
  public empty: boolean = false;

  
  public limit: number = 12;
  public from: number = 0;
  public to: number = this.limit;
  public searchFrom: number = 0;
  
  public userAuth: boolean = false;

  public user: User;
  
  public searchTerm: string;
  public windowHeigth: number;

  constructor(  private userService: UserService,
                private searchService: SearchService,
                private modalImageService: ModalImageService,
                private offererService: OffererService,
                private offerService: OfferService,
                private router: Router,
                private authService: AuthService, ) {

                  this.user = this.authService.user;
                  this.windowHeigth = window.innerHeight;

                }

  ngOnDestroy(): void {

    this.imgSubs.unsubscribe();

  }

  ngOnInit(): void {

    this.typeSlice = this.type.slice(0, -1);

    this.loadContents();

    this.imgSubs = this.modalImageService.newImage
      .pipe(delay(100))
      .subscribe( img => this.loadContents() );

  }

  public fixed: boolean = false;

  // window scroll
  @HostListener('window:scroll', ['$event'])

  onScroll() {

    const pos = ( document.documentElement.scrollTop || document.body.scrollTop ) + this.windowHeigth;
    const max = ( document.documentElement.scrollHeight || document.body.scrollHeight );

    if ( pos - this.windowHeigth > this.windowHeigth * 1.75 && this.scrollCheck) {
      this.fixed = true; 
      this.scrollCheck = false;
    } else if ( pos - this.windowHeigth <= this.windowHeigth * 1.75 && !this.scrollCheck ) {
      this.fixed = false; 
      this.scrollCheck = true;
    }

    if ( pos >= max && !this.empty ) {
      if ( !this.loading ) {
        this.from += this.limit;
        if ( this.searchTerm ) {
          this.searchPush();
        } else {
          this.loadContents();
        }
      }
    }
    
  }

  loadContents() {
  
    this.loading = true;
    
    switch ( this.type ) {
      case 'users':
        return this.userService.loadUsers( this.from, this.to )
                  .subscribe( ({ total, users }) => {
                    if ( users.length > 0) {
                      this.totalContents = total;
                      this.contents.push(...users);
                      this.contentsTemp.push(...users);
                    } else {
                      this.empty = true;
                    }
                    this.loading = false;
                  });
      case 'offerers':
        return this.offererService.loadUserOfferers( this.from, this.to, this.user.id, this.filterType )
        .subscribe( ({ total, offerers }) => {
                    if ( offerers.length > 0) {
                      this.totalContents = total;
                      this.contents.push(...offerers);
                      this.contentsTemp.push(...offerers);         
                    } else {
                      this.empty = true;
                    }    
                    this.loading = false;      
                  });
      case 'offers':
        return this.offerService.loadOffersByType( this.from, this.to, this.user.id, this.filterType )
        .subscribe( ({ total, offers }) => {
                    if ( offers.length > 0) {
                      this.totalContents = total;
                      this.contents.push(...offers);
                      this.contentsTemp.push(...offers);       
                    } else {
                      this.empty = true;
                    } 
                    this.loading = false;        
                  });

      default:
        return [];
    }

  }

  search( term: string ) {
    
    this.searchTerm = term;
    this.searchFrom = 0;

    if ( term.length === 0 ) {
      return this.contents = this.contentsTemp;
    }
    
    this.searchService.selectionSearch( 'user', this.type, term, this.user.id, this.searchFrom, this.limit )
    .subscribe( ( resp:  User[] | Offerer[] | Offer[] | Raffle[] ) => {
      this.contents = resp;      
    });
    
  }

  searchPush() {

    this.searchFrom += this.limit;

    if ( this.searchTerm.length === 0 ) {
      return this.contents = this.contentsTemp;
    }
    
    this.searchService.search( this.type, this.searchTerm, this.searchFrom, this.limit )
    .subscribe( ( resp:  User[] | Offerer[] | Offer[] | Raffle[] ) => {
      this.contents.push(...resp);
    });

  }

  pageChange( valor: number ) {

    this.from += valor * this.limit;
    this.to = this.from + this.limit;

    if ( this.from < 0 ) {
      this.from = 0;
    } else if ( this.from >= this.totalContents ) {
      this.from -= valor * this.limit;
    }

    if ( this.to > this.totalContents ) {
      this.to = this.totalContents;
    } else if ( this.to <= 0 ) {
      this.to += this.limit;
    }

    this.loadContents();

  }

  openModal( content: User | Offerer | Offer ) {
    
    this.modalImageService.openModal(this.type, content.id, content.img );

  }

}
