import { Component, OnInit, HostListener } from '@angular/core';

@Component({
  selector: 'app-up-button',
  templateUrl: './up-button.component.html',
  styles: [
  ]
})
export class UpButtonComponent implements OnInit {

  public visible = false;

  constructor() { }

  ngOnInit(): void {
  }

   // window scroll
   @HostListener('window:scroll', ['$event'])

  onScroll() {

    const pos = ( document.documentElement.scrollTop || document.body.scrollTop );

    if ( pos > 250 ) {
      this.visible = true; 
    } else if ( pos <= 250 ) {
      this.visible = false; 
    }

  }

  scrollUp() {

    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;

  }

}
