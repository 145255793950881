import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { User } from '../../models/user.model';
import { ActivatedRoute } from '@angular/router';
import { RedsysService } from '../../services/redsys.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styles: [
  ]
})
export class ContactComponent implements OnInit {

  public user: User;
  public regReport: string;

  constructor(  private authService: AuthService,
                private activatedRoute: ActivatedRoute  ) { 
      this.user = authService.user;
   }

  ngOnInit(): void {

    const { report } = this.activatedRoute.snapshot.params;
    this.regReport = report;

  }

}
