<div class="m-auto mt-5 w-70">

    <div class="d-flex align-center">
        <div>
            <h1>Favoritos</h1>
        </div>
        <!-- <div>
            <h1 class="pe-2 right-line">Favoritos</h1>
        </div> -->

        <!-- <div class="position-relative justify-content-bottom">
            <a (click)="collapsedCat=!collapsedCat" class="nav-link pointer text-secondary pb-1"><i class="far fa-caret-square-down fa-1-5x" [class.fas]="collapsedCat"></i></a>
        </div> -->
    </div>
    <!-- <div class="position-relative">
        <div class="cat-pos-middle">
            <ul class="show-none cat-pos mb-0" [class.show-opt]="collapsedCat" aria-labelledby="dropdownMenuOffset" #catMenu>
                <p class="list-title">Categorías</p>
                <hr class="mt-2 mb-1">
                <li *ngFor="let tag of tagsList"><a (click)="catClick(tag)" class="dropdown-item pointer cat-item">{{ tag | titlecase }}</a></li>
            </ul>
        </div>
    </div> -->

    <app-main-grid [pageType]="'favs'"></app-main-grid>

</div>