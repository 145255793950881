import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { MailForm } from '../interfaces/mail-form.interface';

const base_url = environment.base_url;

@Injectable({
  providedIn: 'root'
})
export class MailService {
  
  constructor(  private http: HttpClient ) { }

  sendMail( formData: MailForm ) {
    
    return this.http.post(`${ base_url }/mail`, formData );
  
  }

  forgotten( formData: MailForm ) {
    
    return this.http.put(`${ base_url }/auth/forgotten`, formData );
  
  }

}
