import { Component, ViewChild, ElementRef, Input, EventEmitter, Output, OnChanges } from '@angular/core';

import { UploadService } from '../../../services/upload.service';
import Swal from 'sweetalert2';
import { ModalService } from '../../../services/modal.service';

@Component({
  selector: 'app-modal-img-processing',
  templateUrl: './modal-img-processing.component.html',
  styles: [ 
  ]
})
export class ModalImgProcessingComponent implements OnChanges {

  @ViewChild('modelImgInput') modelImgInput: ElementRef;
  
  @Input('imgSrc') imgSrc: Blob;
  @Output() newImg: EventEmitter< File | Blob > = new EventEmitter();
  @Output() imgEmit: EventEmitter< string | Blob > = new EventEmitter();
  @Output() cropEmit: EventEmitter< boolean > = new EventEmitter();
  @Output() update: EventEmitter< boolean > = new EventEmitter();

  public croppedImg: File | Blob;

  public tempImg: any = null;
  @ViewChild("image", { static: false }) imageElement: ElementRef;

  public imgDestination: string;
  public cropper: Cropper;

  public loading: boolean = false;
  public windowWidth: number;
  public windowHeight: number;
  
  constructor( public modalService: ModalService,
               public uploadService: UploadService,  ) { 

                this.imgDestination = ""; 
                this.windowWidth = innerWidth;
                this.windowHeight = innerHeight;

              }

  public ngAfterViewInit() {

      let resizable = true;
      if (this.windowWidth <= 992) {
        resizable = false;
      }

      this.cropper = new Cropper(this.imageElement.nativeElement, {
          dragMode: 'move',
          zoomable: true,
          scalable: true,
          movable: true,
          responsive: true,
          aspectRatio: 1,
          cropBoxMovable: resizable,
          cropBoxResizable: resizable,
          crop: () => {
              const canvas = this.cropper.getCroppedCanvas();
              this.imgDestination = canvas.toDataURL("image/jpg");
              this.cropper.getCroppedCanvas().toBlob( blob => {
                this.croppedImg = blob;
              }, 'image/jpg' );
              // }, 'image/jpg', 0.75 );
          }
      });

  }

  setCropArea() {
    
    if (this.windowWidth > 992) {
      return;
    }

    let cropSize = Math.max( Math.min( this.windowHeight * 0.45, this.windowWidth * 0.7), 175 );
    let boxSize = Math.max( Math.min( this.windowHeight * 0.5, this.windowWidth * 0.8 ), 200 );
    let transformSize = Math.max( ( boxSize - cropSize ) / 2, 12.5 );
    
    this.cropper.setCropBoxData(
      {
        "left":transformSize,
        "top":transformSize,
        "width":cropSize,
        "height":cropSize,
      }
    );

  }
  
  ngOnChanges() {
    
    this.loading = true;
    setTimeout( () => { 
      this.cropper.replace( this.imageElement.nativeElement.src ); 
      this.loading = false;
    }, 100 );

  }

  closeModal() {
    this.tempImg = null;
    this.modalService.closeModal();
  }

  modifyImg() {

    this.setCropArea()
    setTimeout( () => {
  
      this.imgEmit.emit( this.imgDestination );
      this.newImg.emit( this.croppedImg );
      this.cropEmit.emit( false );
      this.update.emit(true);
      
      this.modalService.closeModal();

    }, 200 )

  }

}
