<form *ngIf="offerer" class="form" [formGroup]="offererForm">
    <div class="w-100 h-15-vw bg-warning bg-gradient position-relative animate__animated animate__fadeIn animated fast">
        <div class="row pt-5 pb-3 w-70 m-auto" [class.o-5]="edit">
            <div class="col-auto">
                <div class="bg-light offerer-img">
                    <img [src]="(offerer.img) | img: 'offerers'" class="offerer-img">
                </div>
            </div>
            <div class="of-wrap col-auto my-2 my-sm-auto w-100">
                <h1>{{ (offerer.name) | titlecase}}</h1>
                <h6>{{ (((offerer.description.length > maxLength * 2.5) ? ((offerer.description | slice:0:(maxLength * 2.5)) + '...') : offerer.description)) | titlecase}}
                    <i *ngIf="(offerer.description.length) > (maxLength * 2.5) && fullDescription === false" (click)="fullDescription = true" class="fas fa-plus-circle pointer"></i>
                    <i *ngIf="(offerer.description.length) > (maxLength * 2.5) && fullDescription === true" (click)="fullDescription = false" class="fas fa-minus-circle pointer"></i>
                </h6>
                <p [class.show-none]="!fullDescription" class="offerer-description">{{ (offerer.description) | titlecase}}</p>
                <a [href]="('https://www.google.es/maps/place/'+offerer.address.address+', '+offerer.address.pc)" [title]="offerer.address.address  + ', ' + offerer.address.town" target="_blank" class="pointer f-s">
                    <i class="me-2 far fa-compass"></i>
                    <small>{{ offerer.address.address + ', en ' + offerer.address.town }}</small>
                </a>
            </div>
        </div>
        <div class="position-absolute top-0 end-0 mt-2 me-5">
            <button *ngIf="edit" (click)="delete()" type="button" class="btn btn-danger py-1 px-2 me-2">
                <i class="fas fa-trash-alt"></i>
            </button>
            <button *ngIf="owner" (click)="edit=!edit" type="button" class="btn btn-outline-secondary py-1 px-2" [class.bg-secondary]="edit" [class.text-white]="edit">
                <i class="fas fa-edit"></i>
            </button>
        </div>
    </div>
    <div *ngIf="!edit && offerer.contact ? true : false" class="w-70 m-auto">
        <div class="row mt-3 m-auto justify-content-center">
            <div *ngFor="let contact of contacts, let i = index" class="col px-1 px-sm-2 px-md-3" [class.show-none]="offerer.contact[contact] ? false : true">
                <div *ngIf="offerer.contact[contact]" class="text-center">
                    <button (click)="
                        contactIndex === i ? contactShow = '' : (contactShow = offerer.contact[contact]); 
                        contactIndex === i ? contactIndex = (this.contacts.length + 1) : contactIndex = i; 
                        exist.checked = !exist.checked" type="button" class="btn btn-primary text-white fw-bolder w-100 mw-300-px">
                        <span *ngIf="windowWidth >= 1200">{{ contactsSp[i] | titlecase}}</span>
                        <i [class]="contactsIcon[i] + ' ms-lg-2'"></i>
                    </button>
                    <input type="checkbox" class="show-none" #exist>
                    <!-- <div *ngIf="exist.checked && (windowWidth >= 1000)" class="w-100 h-0 position-relative">
                        <div class="position-absolute w-100 mt-2" id="talkbubble"></div>
                        <div class="position-absolute w-100 mt-2 talkbubble-text">
                            <div class="position-relative d-flex h-100 w-100">
                                <a [href]="contactsRef[i] + offerer.contact[contact]" target="_blank" class="m-auto">
                                    <h3>{{ offerer.contact[contact] }}</h3>
                                </a>
                            </div>
                        </div>
                    </div> -->
                    <div *ngIf="exist.checked && (windowWidth >= 1000)" class="text-center mt-2">
                        <a [href]="contactsRef[i] + offerer.contact[contact]" target="_blank">
                            <small class="f-s">Click para contactar:</small>
                            <h3>{{ offerer.contact[contact] }}</h3>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col text-center px-1 px-sm-2 px-md-3">
                <button (click)="
                contactIndex === 'schedule' ? contactShow = '' : contactShow = 'Horario'; 
                contactIndex === 'schedule' ? contactIndex = (this.contacts.length + 1) : contactIndex = 'schedule'" type="button" class="btn btn-primary text-white fw-bolder w-100 mw-300-px">
                    <span *ngIf="windowWidth >= 1200">Horario</span>
                    <i class="fas fa-calendar-alt ms-lg-2"></i>
                </button>
            </div>
            <div class="text-center mt-3" [class.show-none]="!contactShow">
                <a *ngIf="(windowWidth < 1000) && contactIndex !== 'schedule'" [href]="contactsRef[contactIndex] + contactShow" target="_blank">
                    <small>Click para contactar:</small>
                    <h3>{{ contactShow }}</h3>
                </a>
                <app-schedule [class.show-none]="contactIndex !== 'schedule'" [isOpen]="true" [edit]="edit" [scheduleInput]="offerer.schedule" (scheduleEmit)='schedule=$event' class="mb-3"></app-schedule>
            </div>
        </div>
    </div>
    <div class="row w-70 m-auto mt-2" [class.show-none]="!edit">
        <h1 class="pt-3">Editar Campos</h1>
        <div class="pt-3 pb-5">
            <h4 class="pb-2">Cambiar imagen</h4>
            <div class="bg-light img-avatar rounded m-auto">
                <app-img-select [img]="offerer.img" [type]="'offerers'" [edit]="edit" (newImg)="newImg = $event"></app-img-select>
            </div>
        </div>
        <div class="form-group mb-3 col-6">
            <label for="exampleInputuname">Nombre</label>
            <div class="input-group">
                <div class="input-group-addon"><i class="ti-user"></i></div>
                <input class="form-control main" type="text" placeholder="Nombre" formControlName="name" contenteditable="false" [readonly]="!edit">
            </div>
        </div>
        <div class="form-group mb-3 col-6">
            <label for="exampleInputuname">NIF</label>
            <div class="input-group">
                <div class="input-group-addon"><i class="ti-user"></i></div>
                <input class="form-control secondary" type="text" placeholder="NIF" formControlName="nif" [readonly]="!edit">
            </div>
        </div>
        <div class="form-group mb-3 col-6">
            <label for="exampleInputuname">Email</label>
            <div class="input-group">
                <div class="input-group-addon"><i class="ti-user"></i></div>
                <input class="form-control secondary" type="email" placeholder="Email" formControlName="email" [readonly]="!edit">
            </div>
        </div>
        <div class="form-group mb-3 col-6">
            <label for="exampleInputuname">Teléfono</label>
            <div class="input-group">
                <div class="input-group-addon"><i class="ti-user"></i></div>
                <input class="form-control secondary" type="tel" placeholder="Teléfono" formControlName="phone" [readonly]="!edit">
            </div>
        </div>
        <div class="form-group mb-3 col-6">
            <label for="exampleInputuname">WhatsApp Empresa</label>
            <div class="input-group">
                <div class="input-group-addon"><i class="ti-user"></i></div>
                <input class="form-control secondary" type="tel" placeholder="WhatsApp" formControlName="whatsApp" [readonly]="!edit">
            </div>
        </div>
        <div class="form-group mb-3 col-6">
            <label for="exampleInputuname">Sitio Web</label>
            <div class="input-group">
                <div class="input-group-addon"><i class="ti-user"></i></div>
                <input class="form-control secondary" type="text" placeholder="Sitio web" formControlName="webSite" [readonly]="!edit">
            </div>
        </div>
        <div class="form-group mb-3 col-6">
            <label for="exampleInputuname">Dirección</label>
            <div class="input-group">
                <div class="input-group-addon"><i class="ti-user"></i></div>
                <a [href]="('https://www.google.es/maps/place/'+offerer.address.address+' '+offerer.address.pc)" target="_blank" class="pointer text-primary" [class.show-none]="edit">{{ offerer.address.address }}<i class="ms-2 far fa-compass"></i></a>
                <input class="form-control secondary" type="text" placeholder="Dirección" formControlName="address" [class.show-none]="!edit" [readonly]="!edit">

            </div>
        </div>
        <div class="form-group mb-3 col-6">
            <label for="exampleInputuname">CP</label>
            <div class="input-group">
                <div class="input-group-addon"><i class="ti-user"></i></div>
                <input class="form-control secondary" type="text" placeholder="Código postal" formControlName="pc" [readonly]="!edit">
            </div>
        </div>

        <app-schedule [edit]="edit" [scheduleInput]="offerer.schedule" (scheduleEmit)='schedule=$event' class="mb-3"></app-schedule>

        <div class="form-group mb-3 col-12">
            <label for="exampleInputuname"><small>Descripción</small></label>
            <div class="input-group">
                <div class="input-group-addon"><i class="ti-user"></i></div>
                <textarea class="form-control secondary" type="time" placeholder="Descripción" formControlName="description" [readonly]="!edit"></textarea>
            </div>
        </div>
        <div class="form-group mb-3 mb-3 col-12">
            <label for="exampleInputuname">Etiquetas</label>
            <div class="input-group" [class.border-bottom]="edit">
                <div class="input-group-addon"><i class="ti-user"></i></div>
                <div class="my-auto ms-0">
                    <span *ngFor="let tag of (tags ? tags : [])" class="badge rounded-pill bg-secondary h-50 me-1 mb-1" [class.show-none]="!edit">{{ ((tag !== "general" || tags.length === 1) ? tag : '') | titlecase }}<i *ngIf="edit && (tag ? tag !== 'general' : '' || offer ? offer.tags.length === 1 : '')" (click)="onDeleteTag(tag)" class="fas fa-times-circle ms-1 pointer"></i></span>
                </div>
                <input (change)="onTagsInput( tagsInput.value ); tagsInput.value = ''" class="form-control border-0" list="tagsList" type="text" placeholder="Etiquetas" formControlName="tags" [class.show-none]="!edit" [readonly]="!edit" #tagsInput>
                <datalist id="tagsList">
                    <option *ngFor="let tagListItem of tagsList" [value]="tagListItem">
                </datalist>
            </div>
        </div>
        <div class="my-auto ms-0">
            <span *ngFor="let tag of (offerer.tags)" class="badge rounded-pill bg-secondary h-50 me-1" [class.show-none]="edit">{{ (tag !== "general" || offerer.tags.length === 1 ? tag : '') | titlecase }}</span>
        </div>
    </div>
    <div *ngIf="edit" class="row animated m-auto w-70 mw-300-px">
        <div class="col">
            <div class="">
                <!-- [disabled]="(offererForm.untouched || !offererForm.controls) && !newImg" -->
                <button (click)="saveOfferer()" type="submit" class="btn btn-warning fw-bolder my-3 w-100">
                    Guardar cambios
                </button>
            </div>
        </div>
    </div>
</form>

<div class="w-70 m-auto">
    <button *ngIf="owner" (click)="openModal();selectedType='offers'" class="btn btn-warning rounded-pill mt-3 ms-2">
        <span><i class="fas fa-plus me-2"></i>
            <i class="fas fa-tags me-2"></i><span *ngIf="windowWidth >= 400">Nueva oferta</span>
        </span>
    </button>
    <button *ngIf="owner" (click)="openModal();selectedType='raffles'" class="btn btn-secondary rounded-pill mt-3 ms-2">
        <span><i class="fas fa-plus me-2"></i>
            <i class="fas fa-medal me-2"></i><span *ngIf="windowWidth >= 400">Nuevo sorteo</span>
        </span>
    </button>

    <div class="row w-100 m-auto mt-3">
        <div class="col-auto">
            <h1 *ngIf="selectedType==='offers'">Ofertas</h1>
            <h1 *ngIf="selectedType==='raffles'">Sorteos</h1>
        </div>
        <div class="col-auto m-auto me-0">
            <button (click)="typeChange()" class="btn btn-outline-secondary rounded-pill ms-sm-2">
                <small *ngIf="windowWidth < 300" class="f-s"><i class="fas fa-sign-in-alt me-2"></i></small>
                <span *ngIf="selectedType==='offers'">
                    <i class="fas fa-medal" [class.me-2]="windowWidth >= 300"></i>
                    <span *ngIf="windowWidth >= 300">Ver sorteos</span>
                </span>
                <span *ngIf="selectedType==='raffles'">
                    <i class="fas fa-tags" [class.me-2]="windowWidth >= 300"></i>
                    <span *ngIf="windowWidth >= 300">Ver ofertas</span>
                </span>
            </button>
        </div>
    </div>

    <app-offerer-filter-grid [hostContentId]="offererID"></app-offerer-filter-grid>
</div>
<div *ngIf="offerer">
    <app-modal-new [offererID]="offererID" [address]="offerer.address" [selectedType]="selectedType" (created)="created()"></app-modal-new>
</div>