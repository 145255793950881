import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, OnChanges } from '@angular/core';
import { ModalImgProcessingComponent } from '../modals/modal-img-processing/modal-img-processing.component';
import { ActivatedRoute } from '@angular/router';
import { ModalService } from '../../services/modal.service';

@Component({
  selector: 'app-img-select',
  templateUrl: './img-select.component.html',
  styles: [
  ]
})
export class ImgSelectComponent implements OnInit, OnChanges {
  @ViewChild("image", { static: false }) imageElement: ElementRef;
  @Input() type: 'users'|'offerers'|'offers';
  @Input() img?: string = 'no-img';
  @Input() edit?: boolean = true;
  @Input() circle?: boolean = false;
  @Output() newImg: EventEmitter< File | Blob > = new EventEmitter();

  @ViewChild(ModalImgProcessingComponent) modalImgProcessingComponent: ModalImgProcessingComponent;

  public uploadFile: File | Blob;
  public tempImg: any = null;
  public lastTempImg: any = null;
  public tempCroppedImg: File | Blob;
  public lastTempCroppedImg: File | Blob;
  public lastNewImg: File | Blob;
  public cropper: Cropper;
  public imgDestination: string;
  public crop: boolean = false;
  public profile: boolean = false;
  
  constructor(  private modalService: ModalService,
                private activatedRoute: ActivatedRoute,  ) { }

  ngOnInit(): void {
    
    const page = this.activatedRoute.snapshot.routeConfig.path;
    if ( page === 'profile' ) {
      this.profile = true;
    }
    
  }
  ngOnChanges() {    
  }
  
  changeImg( event: Event ) {
    
    if ( this.tempImg ) {
      this.lastTempImg = this.tempImg;
      this.lastTempCroppedImg = this.tempCroppedImg;
      this.lastNewImg = this.uploadFile;
    }
    
    const target = event.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];
    this.uploadFile = file;

    if ( !file ) {
      return this.tempImg = null;
    }

    const reader = new FileReader();
    reader.readAsDataURL( file );

    reader.onload = (event: any) => {
      const imgElement = document.createElement("img");
      imgElement.src = event.target.result;

      imgElement.onload = (e: any) => {
        const canvas = document.createElement("canvas");
        const maxWidth = 400;
        const scale = maxWidth / e.target.width;
        canvas.width = maxWidth;
        canvas.height = e.target.height * scale;

        const ctx = canvas.getContext("2d");
        ctx.fillStyle = '#fff';
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(e.target, 0, 0, canvas.width, canvas.height);
        const srcEncoded = ctx.canvas.toDataURL("image/jpeg", 0.75);
        // const srcEncoded2 = ctx.canvas.toDataURL(e.target, "image/jpeg");
        
        this.tempImg = srcEncoded;

        this.tempCroppedImg = this.tempImg;
        this.modalService.openModal();
      }

    }

    // reader.onloadend = () => {
    //   this.tempImg = reader.result;
    //   this.tempCroppedImg = this.tempImg;
    //   this.modalImgProcessingService.openModal();    
    // }

  }

  imgEditor() {
    this.crop = !this.crop;
    if ( this.tempImg ) {
      this.modalImgProcessingComponent.ngOnChanges();
    }
    this.modalService.openModal();    
  }

  updateImg( update ) {
    if (update) {
      this.lastTempImg = this.tempImg;
      this.lastTempCroppedImg = this.tempCroppedImg;
      this.lastNewImg = this.uploadFile;
    } else {
      this.tempImg = this.lastTempImg;
      this.tempCroppedImg = this.lastTempCroppedImg;
      this.uploadFile = this.lastNewImg;
    }
  }

}
