import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ConfigDropdownComponent } from './config-dropdown/config-dropdown.component';
import { PipesModule } from '../pipes/pipes.module';
import { MainGridComponent } from './grids/main-grid/main-grid.component';
import { UpButtonComponent } from './up-button/up-button.component';
import { SettingsGridComponent } from './grids/settings-grid/settings-grid.component';
import { OffererFilterGridComponent } from './grids/offerer-filter-grid/offerer-filter-grid.component';
import { SwiperModule } from 'swiper/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ImgProcessingComponent } from './img-processing/img-processing.component';
import { ImgSelectComponent } from './img-select/img-select.component';
import { ModalNewComponent } from './modals/modal-new/modal-new.component';
import { ModalImgProcessingComponent } from './modals/modal-img-processing/modal-img-processing.component';
import { SliderComponent } from './slider/slider.component';
import { ScheduleComponent } from './schedule/schedule.component';
import { ContactFormComponent } from './contact-form/contact-form.component';
import { CardComponent } from './card/card.component';
import { RedsysComponent } from './redsys/redsys.component';
import { PayCardsComponent } from './pay-cards/pay-cards.component';
import { PayComponent } from './pay/pay.component';
import { ModalSubscriptionComponent } from './modals/modal-subscription/modal-subscription.component';
import { RedsysRenoComponent } from './redsys-reno/redsys-reno.component';

@NgModule({
  declarations: [
    ConfigDropdownComponent,
    UpButtonComponent,
    MainGridComponent,
    SettingsGridComponent,
    OffererFilterGridComponent,
    ModalNewComponent,
    ModalImgProcessingComponent,
    ImgProcessingComponent,
    ImgSelectComponent,
    SliderComponent,
    ScheduleComponent,
    ContactFormComponent,
    CardComponent,
    RedsysComponent,
    PayCardsComponent,
    PayComponent,
    ModalSubscriptionComponent,
    RedsysRenoComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    PipesModule,
    SwiperModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    ConfigDropdownComponent,
    UpButtonComponent,
    MainGridComponent,
    SettingsGridComponent,
    OffererFilterGridComponent,
    ModalNewComponent,
    ModalImgProcessingComponent,
    ImgProcessingComponent,
    ImgSelectComponent,
    SliderComponent,
    ScheduleComponent,
    ContactFormComponent,
    RedsysComponent,
    PayCardsComponent,
    PayComponent,
    ModalSubscriptionComponent,
    RedsysRenoComponent,
  ]
})
export class ComponentsModule { }
