<nav class="navbar navbar-expand-sm navbar-dark bg-primary fixed-top py-0" [class.navbar-h]="!collapsed">
    <div class="container-fluid w-95">
        <a class="navbar-brand big-resolution" routerLink="/"><img class="navbar-logo right-line" src="../../../assets/img/blanco-amarillo.svg" alt="LoCana"></a>
        <a class="navbar-brand small-resolution" routerLink="/"><img class="navbar-logo small-logo right-line" src="../../../assets/img/Locana-person-icon.svg" alt="LoCana"></a>
        <button (click)="collapse()" class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="navbar-collapse overflow-auto" [class.show-none]="!collapsed" id="navbarTogglerDemo02" #smMenu>
            <div class="d-flex text-center me-auto">
                <form class="d-flex me-sm-3 navbar-search">
                    <input (click)="collapsedSearch = true; collapsedOpt = false" (keyup)="search( txtTerm.value )" class="form-control me-2" type="search" placeholder="Buscar..." aria-label="Search" #txtTerm>
                    <button (click)="collapsedSearch = true; collapsedOpt = false" class="btn btn-outline-light navbar-search-btn" type="button">Buscar</button>
                </form>
            </div>

            <div *ngIf="(searchTerm ? searchTerm.length : 0)>0" class="mt-3">
                <div class="show-none cat2-pos animated" [class.show-opt]="collapsedSearch" aria-labelledby="dropdownMenuOffset" #searchMenu>
                    <ul class="nav nav-tabs">
                        <li class="nav-item w-50">
                            <a (click)="selection = 'offers'" class="nav-link pointer text-secondary" [class.active]="selection==='offers'" [class.text-dark]="selection==='offers'" [class.cat2-active]="selection==='offers'">Ofertas</a>
                        </li>
                        <li class="nav-item w-50">
                            <a (click)="selection = 'offerers'" class="nav-link pointer text-secondary" [class.active]="selection==='offerers'" [class.text-dark]="selection==='offerers'" [class.cat2-active]="selection==='offerers'">Locales</a>
                        </li>
                    </ul>
                    <div class="pb-3">
                        <div *ngIf="selection==='offers'" class="cat2-ul animated-menu overflow-auto">
                            <div class="no-li-style">
                                <div *ngFor="let offer of offers" (click)="openContent(offer, 'offer')" class="row row-cols-1 dropdown-item pointer py-2">
                                    <div class="col">
                                        <img class="img-fluid navbar-img dropdown-toggle" [src]="offer.img | img: 'offers'">
                                    </div>
                                    <div class="col">
                                        <span class="text-dark">{{ offer.name | titlecase }}</span>
                                    </div>
                                    <div class="col">
                                        <span class="f-s"><small>{{ offer.offerer.name | titlecase }}</small></span>
                                    </div>
                                    <hr class="w-30">
                                </div>
                            </div>
                        </div>
                        <div *ngIf="selection==='offerers'" class="cat2-ul overflow-auto">
                            <div class="no-li-style">
                                <div *ngFor="let offerer of offerers" (click)="openContent(offerer, 'offerer')" class="py-2">
                                    <img class="img-fluid navbar-img dropdown-toggle" [src]="offerer.img | img: 'offerers'">
                                    <span class="dropdown-item pointer text-dark">{{ offerer.name | titlecase }}</span>
                                    <span class="f-s"><small>{{ offerer.address.town | titlecase }}</small></span>
                                    <hr class="w-30">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ul *ngIf="(windowWidth > 750) || (windowWidth <= 575)" class="navbar-nav my-auto text-center me-sm-3">
                <li class="nav-item">
                    <a (click)="collapse()" class="nav-link" routerLink="/user/offers" routerLinkActive="active">Ofertas</a>
                </li>
                <li class="nav-item">
                    <a (click)="collapse()" class="nav-link" routerLink="/user/offerers" routerLinkActive="active">Locales</a>
                </li>
                <li class="nav-item">
                    <a (click)="collapse()" class="nav-link" routerLink="/user/raffles" routerLinkActive="active">Sorteos</a>
                </li>
                <li class="nav-item">
                    <a (click)="collapse()" class="nav-link" routerLink="/user/favs" routerLinkActive="active">Favoritos</a>
                </li>
            </ul>

            <div *ngIf="(windowWidth <= 750) && (windowWidth > 575)" class="text-center my-2 my-sm-0">
                <div>
                    <a (click)="collapsedSubMenu=!collapsedSubMenu; collapsedSearch = false" class="text-white me-3">Páginas<i class="fas fa-sort-down ms-1"></i></a>
                    <ul class="dropdown-menu opt-pos animated-menu" [class.show-opt]="collapsedSubMenu" aria-labelledby="dropdownMenuOffset" #subMenu>
                        <li><a (click)="collapse()" class="dropdown-item nav-link" routerLink="/user/offers" routerLinkActive="active">Ofertas</a></li>
                        <li><a (click)="collapse()" class="dropdown-item nav-link" routerLink="/user/offerers" routerLinkActive="active">Locales</a></li>
                        <li><a (click)="collapse()" class="dropdown-item nav-link" routerLink="/user/raffles" routerLinkActive="active">Sorteos</a></li>
                        <li class="border-top"><a (click)="collapsed=!collapsed" class="dropdown-item nav-link" routerLink="/user/favs" routerLinkActive="active">Favoritos</a></li>
                    </ul>
                </div>
            </div>
            <div class="text-center my-2 my-sm-0">
                <div class="position-relative" [class.pb-2]="!collapsedOpt && windowWidth <= 575" [class.pb-3]="collapsedOpt && windowWidth <= 575">
                    <button (click)="collapsedOpt=!collapsedOpt; collapsedSearch = false" class="btn btn-circle navbar-circle-btn">
                        <img  class="img-fluid navbar-img dropdown-toggle" [src]="user.img | img: 'users'" alt="Perfil">
                    </button>
                    <div *ngIf="windowWidth <= 575" class="position-absolute text-center bottom-0 start-0 end-0 o-5">
                        <i *ngIf="!collapsedOpt" class="fas fa-sort-down text-white"></i>
                        <i *ngIf="collapsedOpt" class="fas fa-sort-up text-white mt-2"></i>
                    </div>
                </div>

                <ul class="dropdown-menu opt-pos animated-menu" [class.show-opt]="collapsedOpt" aria-labelledby="dropdownMenuOffset" #menu>
                    <li><a (click)="collapse()" class="dropdown-item nav-link pt-0" routerLink="/user/profile" routerLinkActive="active">Perfil</a></li>
                    <li><a *ngIf="user.role==='ADMIN_ROLE' || user.role==='OFFERER_ROLE'" (click)="collapse()" class="dropdown-item nav-link" routerLink="/user/new-offerer" routerLinkActive="active">Mis Locales</a></li>
                    <li><a *ngIf="user.role==='ADMIN_ROLE' || user.role==='OFFERER_ROLE'" (click)="collapse()" class="dropdown-item nav-link" routerLink="/user/offer-checker" routerLinkActive="active">Procesar Oferta</a></li>
                    <li class="border-top"><a *ngIf="user.role==='ADMIN_ROLE'" (click)="collapsed=!collapsed" class="dropdown-item nav-link" routerLink="/user/maintenance/users" routerLinkActive="active">Mantenimiento</a></li>
                    <!--<li class="border-top"><a (click)="collapse()" class="dropdown-item nav-link" routerLink="/user/pricing" routerLinkActive="active">Tarifas</a></li>-->
                    <li class="border-top"><a (click)="collapse()" class="dropdown-item nav-link" routerLink="/user/contact" routerLinkActive="active">Contacto</a></li>
                    <li class="border-top"><a (click)="logout()" class="dropdown-item nav-link" routerLink="/init" routerLinkActive="active">Salir</a></li>
                </ul>
            </div>
        </div>
    </div>
</nav>

<div class="navbar-header-m "></div>