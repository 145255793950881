import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ComponentsModule } from '../components/components.module';
import { InitComponent } from '../init/init/init.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { PipesModule } from '../pipes/pipes.module';
import { ContactComponent } from '../init/contact/contact.component';
import { InitHomeComponent } from '../init/init-home/init-home.component';
import { AuthModule } from '../auth/auth.module';
import { AboutComponent } from './about/about.component';



@NgModule({
  declarations: [
    InitComponent,
    ContactComponent,
    InitHomeComponent,
    AboutComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ComponentsModule,
    RouterModule,
    SharedModule,
    PipesModule,
    AuthModule
  ],
  exports: [
    InitComponent,
    ContactComponent,
    InitHomeComponent,
    AboutComponent
  ]
})
export class InitModule { }
