<div class="row mh-30-vh">
    <div class="col mb-3 text-center">
        <img class="img-fluid mh-30-vh" src="../../../assets/img/azul-amarillo.svg" alt="LoCana">
    </div>
</div>
<h1 class="text-center mt-2 mb-4">Procesar oferta</h1>
<div class="container-fluid w-70">
    <div class="container text-center text-white login w-100">
        <form class="form-horizontal form-material" [formGroup]="checkForm" (ngSubmit)="onSend()" id="loginform">
            <div class="form-group m-t-40 mb-3">
                <div class="col-xs-12" [class.invalid]="invalidField('offerReg')">
                    <input class="form-control text-center fs-2 fw-bold w-70 h-15-vh m-auto" type="text" placeholder="Código de oferta" formControlName="offerReg">
                </div>
            </div>
            <div class="form-group mb-3">
                <div class="col-xs-12" [class.invalid]="invalidField('clientReg')">
                    <input class="form-control text-center fs-2 fw-bold w-70 h-15-vh m-auto" type="text" placeholder="Código de cliente" formControlName="clientReg">
                </div>
            </div>

            <div class="form-group text-center m-t-20">
                <div class="col-xs-12">
                    <button class="btn btn-warning text-white fw-bolder text-y-shadow px-5 my-3" type="submit">Procesar</button>
                </div>
            </div>
        </form>
    </div>
</div>