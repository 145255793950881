import { Component, OnInit, OnDestroy } from '@angular/core';
import Swal from 'sweetalert2';
import { delay } from 'rxjs/operators';

import { User } from '../../../models/user.model';


import { UserService } from '../../../services/user.service';
import { SearchService } from '../../../services/search.service';
import { ModalImageService } from '../../../services/modal-image.service';
import { Subscription } from 'rxjs';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-users-maintenance',
  templateUrl: './users.maintenance.component.html',
  styles: [
  ]
})
export class UsersMaintenanceComponent implements OnInit, OnDestroy {

  public users: User[] = [];
  public usersTemp: User[] = [];
  
  public imgSubs: Subscription;
  public loading: boolean = true;

  public totalUsers: number = 0;
  public from: number = 0;
  public limit: number = 5;
  public to: number = this.limit;

  public searchTerm: string;

  constructor(  private authService: AuthService,
                private userService: UserService,
                private searchService: SearchService,
                private modalImageService: ModalImageService ) { }

  ngOnDestroy(): void {

    this.imgSubs.unsubscribe();

  }

  ngOnInit(): void {

    this.loadUsers();

    this.imgSubs = this.modalImageService.newImage
      .pipe(delay(100))
      .subscribe( img => this.loadUsers() );

  }

  loadUsers() {

    this.loading = true;

    if ( this.searchTerm ) {
      this.searchService.search( 'users', this.searchTerm, this.from, this.limit )
      .subscribe( ( resp: User[] ) => {
        this.users = resp;
        this.loading = false;
      });
    } else {
      this.userService.loadUsers( this.from, this.limit )
        .subscribe( ({ total, users }) => {
          this.totalUsers = total;
          this.users = users;
          this.usersTemp = users;
          this.loading = false;
        });
    }

  }

  search( term: string ) {

    if ( term.length === 0 ) {
      return this.users = this.usersTemp;
    }
    
    this.searchTerm = term;
    this.from = 0;
    this.to = this.limit;

    this.searchService.search( 'users', term, this.from, this.limit )
        .subscribe( ( resp: User[] ) => {
          this.users = resp;          
        });

  }

  deleteUser( user: User ) {

    if ( user.id === this.authService.uid || user.role === 'ADMIN_ROLE') {
      return Swal.fire('Error', 'No puede borrarse a si mismo o a un administrador', 'error');
    }

    Swal.fire({
      title: '¿Borrar Usuario?',
      text: `Esta a punto de borrar a ${ user.name }`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Si, borrarlo'
    }).then((result) => {
      if (result.value) {

        this.userService.deleteUser( user )
          .subscribe( resp => {

            this.loadUsers();
            Swal.fire(
              'User borrado',
              `${ user.name } fue eliminado correctamente`,
              'success'
            );

          });

      }
    });

  }

  changeRole( user: User ) {

    this.userService.saveUser( user )
      .subscribe( resp => {
        console.log(resp);
      });

  }

  pageChange( value: number ) {

    this.from += value * this.limit;
    this.to = this.from + this.limit;

    if ( this.from < 0 ) {
      this.from = 0;
    } else if ( this.from >= this.totalUsers ) {
      this.from -= value * this.limit;
    }

    if ( this.to > this.totalUsers ) {
      this.to = this.totalUsers;
    } else if ( this.to <= 0 ) {
      this.to += this.limit;
    }

    this.loadUsers();

  }

}
