<div class="grid row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-3 row-cols-xl-4 g-4 animate__fadeInUp animate__faster" [class.animate__animated]="animated">
    <div *ngFor="let content of contents" class="col">
        <div class="card h-100 m-auto mw-50-vh" [class.o-5]="finished(content.endDate) && (content.winners ? (content.winners.indexOf(user.id) < 0) : true)">
            <a [routerLink]=" '/user/' + type.slice(0,-1) + '/' + content.id">
                <div class="p-2 pb-0 ms-card">
                    <img [src]="content.img | img: type" class="card-img-top card-img rounded animate__animated animate__fadeIn animate__faster" alt="content.name">
                </div>
            </a>
            <div *ngIf="type==='offers'" class="card-body p-2 pt-0 mt-1">
                <a [routerLink]=" '/user/' + type.slice(0,-1) + '/' + content.id">
                    <h5 class="card-title h-64px">{{ ((content.name.length > (maxLength / 1.8)) ? (content.name | slice:0:(maxLength / 1.8)) + '...' : content.name) | titlecase }}</h5>
                </a>
                <div class="text-secondary">
                    <a [routerLink]=" '/user/' + type.slice(0,-1) + '/' + content.id">
                        <h5 class="card-text mb-1"><small class="me-3 text-decoration-line-through">{{ content.price }}€</small>{{ content.finalPrice }}€</h5>
                        <p class="card-text mb-0 f-s"><small>{{ (content.offerer.name.length > maxLength ? (content.offerer.name | slice:0:maxLength) + '...' : content.offerer.name) | titlecase }}</small></p>
                    </a>
                    <a [href]="content.offerer ? ('https://www.google.es/maps/place/'+content.offerer.address.address+', '+content.offerer.address.pc) : ''" title="Ir a Maps" target="_blank" class="pointer my-auto f-s" [class.show-none]="edit">
                        <i class="far fa-compass"></i>
                        <small>
                            {{ ((content.offerer.address ? (content.offerer.address.town.length > maxLength ? ((content.offerer.address.town | slice:0:maxLength) + '...'): content.offerer.address.town) : '')) | titlecase }}
                        </small>
                    </a>
                    <div class="row mt-0">
                        <div class="col align-self-start">
                            <small class="f-xs">Quedan:</small>
                            <p class="card-text">
                                <small class="d-block">
                                    {{ (content.limit && content.limit > 0) ? (content.limit - content.used) : '&#x221e;' }}
                                    <!-- <span *ngIf="content.limit > 0">/</span>
                                    <small class="f-xxs"><small class="xxs text-decoration-line-through">{{ content.limit }}</small></small> -->
                                </small>

                            </p>
                        </div>
                        <div class="col align-self-end text-end">
                            <small class="text-end f-xs">Fecha fin:</small>
                            <p class="card-text text-end">
                                <small *ngIf="dateTime(content.endDate) === 'date'">{{ content.endDate | date:'dd/MM/YYYY' }}</small>
                                <small *ngIf="dateTime(content.endDate) === 'time'">{{ content.endDate | date:'h:mm a' }}</small>
                                <small *ngIf="dateTime(content.endDate) !== 'time' && dateTime(content.endDate) !== 'date'">{{ content.endDate ? dateTime(content.endDate) : 'Sin fecha' }}</small>
                            </p>
                        </div>
                    </div>
                </div>
                <!-- <p class="card-text"><small>{{ dateDiff(content.endDate) }}</small></p> -->
                <h4 class="text-white position-absolute top-0 end--1 px-3 mt-3 card-label">{{ ( 1 - content.finalPrice / content.price ) | percent:'.0-1' }}</h4>
            </div>
            <div class="h-100"></div>
            <div *ngIf="type==='offerers'" class="card-body pt-0">
                <a [routerLink]=" '/user/' + type.slice(0,-1) + '/' + content.id">
                    <h5 class="card-title">{{ ((content.name.length > (maxLength / 1.8)) ? (content.name | slice:0:(maxLength / 1.8)) + '...' : content.name) | titlecase }}</h5>
                    <p class="card-text">{{ ((content.address.town.length > (maxLength / 1.25)) ? (content.address.town | slice:0:(maxLength / 1.25)) + '...' : content.address.town) | titlecase }}</p>
                </a>
            </div>
            <div *ngIf="type==='raffles'" class="card-body pt-0">
                <a [routerLink]=" '/user/' + type.slice(0,-1) + '/' + content.id">
                    <h5 class="card-title">{{ ((content.name.length > (maxLength / 1.8)) ? (content.name | slice:0:(maxLength / 1.8)) + '...' : content.name) | titlecase }}</h5>
                    <p class="card-text">{{ content.offerer.name | titlecase }}</p>
                    <div class="col align-self-end text-start">
                        <small class="text-start f-xs">Fecha fin:</small>
                        <p class="card-text text-start">
                            <small *ngIf="dateTime(content.endDate) === 'date'">{{ content.endDate | date:'dd/MM/YYYY' }}</small>
                            <small *ngIf="dateTime(content.endDate) === 'time'">{{ content.endDate | date:'h:mm a' }}</small>
                            <small *ngIf="dateTime(content.endDate) !== 'time' && dateTime(content.endDate) !== 'date'">{{ content.endDate ? dateTime(content.endDate) : 'Sin fecha' }}</small>
                        </p>
                    </div>
                    <!-- <p class="card-text">{{ content.endDate | date:'dd/MM/YYYY' }}</p> -->
                </a>
                <h4 *ngIf="content.winners ? (content.winners.indexOf(user.id) >= 0) : false" class="text-white position-absolute top-0 end--1 px-3 mt-3 card-label py-2">Ganado<i class="fas fa-medal ms-2"></i></h4>
            </div>
            <!-- <div class="card-footer px-2 h-100">
                <div class="x-slider">
                    <small *ngFor="let tag of content.tags; let i=i ndex" class="text-muted pe-1" [class.ps-1]="i!==0 && (tag!=='general' || content.tags.length===1)" [class.right-line]="i!=(content.tags.length-1) && (tag!=='general' || content.tags.length===1)
               ">{{ ((tag !=="general" || content.tags.length === 1) ? tag : '') | titlecase }}</small>
                </div>
            </div> -->
            <a *ngIf="content.winners ? (content.winners.indexOf(user.id) < 0) : false" [routerLink]=" '/user/' + type.slice(0,-1) + '/' + content.id">
                <div class="border border-secondary rounded position-absolute top-0 bottom-0 start-0 end-0" [class.show-none]="!finished(content.endDate)" [class.bg-light]="finished(content.endDate)" [class.o-5]="finished(content.endDate)">
                </div>
            </a>
        </div>
    </div>
</div>