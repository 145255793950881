import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { User } from '../../models/user.model';
import { AuthService } from '../../services/auth.service';
import { MainGridComponent } from '../../components/grids/main-grid/main-grid.component';
import { Router } from '@angular/router';
import { SearchService } from '../../services/search.service';
import { Offer } from '../../models/offer.model';
import { Offerer } from '../../models/offerer.model';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styles: [
  ]
})
export class NavbarComponent {
  
  @ViewChild( 'smMenu' ) smMenu: ElementRef;
  @ViewChild( 'menu' ) menu: ElementRef;
  @ViewChild( 'subMenu' ) subMenu: ElementRef;
  @ViewChild( 'searchMenu' ) searchMenu: ElementRef;

  public collapsed: boolean = false;
  public collapsedTemp: boolean = false;
  public collapsedOpt: boolean = false;
  public collapsedOptTemp: boolean = false;
  public collapsedSubMenu: boolean = false;
  public collapsedSubMenuTemp: boolean = false;
  public collapsedSearch: boolean = false;
  public collapsedSearchTemp: boolean = false;
  public user: User;
  public users: User[];
  public offers: Offer[];
  public offerers: Offerer[];
  
  public searchTerm: string;
  public selection: string = 'offers';

  public windowWidth: number;

  class: string;

  constructor(  private authService: AuthService,
                private searchService: SearchService,
                private router: Router, ) {

    this.user = authService.user;
    this.windowWidth = innerWidth;

  }

  logout() {
    this.authService.logout();
  }

  @HostListener( 'document: click', ['$event'] )
  clickOut( event ) {

    if ( this.collapsedTemp ) {
      if ( this. collapsed ) {
        if (this.smMenu.nativeElement.contains( event.target )) {
          return this.collapsedTemp = true;
        }
        this.collapsedTemp = false;
        return  this.collapsed = false;
      }
    }

    this.collapsedTemp = this.collapsed;

    if ( this.collapsedOptTemp ) {
      if ( this. collapsedOpt ) {
        if (this.menu.nativeElement.contains( event.target )) {
          return this.collapsedOptTemp = true;
        }
        this.collapsedOptTemp = false;
        return  this.collapsedOpt = false;
      }
    }

    this.collapsedOptTemp = this.collapsedOpt;

    if ( this.collapsedSearchTemp ) {
      if ( this. collapsedSearch ) {
        if (this.searchMenu.nativeElement.contains( event.target )) {        
          return this.collapsedSearchTemp = true;
        }
        this.collapsedSearchTemp = false;
        return  this.collapsedSearch = false;
      }
    }

    this.collapsedSearchTemp = this.collapsedSearch;

    if ( this.collapsedSubMenuTemp ) {
      if ( this. collapsedSubMenu ) {
        if (this.subMenu.nativeElement.contains( event.target )) {        
          return this.collapsedSubMenuTemp = true;
        }
        this.collapsedSubMenuTemp = false;
        return  this.collapsedSubMenu = false;
      }
    }

    this.collapsedSubMenuTemp = this.collapsedSubMenu;

  }

  collapse() {

    this.collapsed=!this.collapsed;
    this.collapsedSearch = false;
    
  }

  searchClick( val: boolean ){

    this.collapsedSearch = val;

  }

  search( term: string ) {
    
    this.searchTerm = term;

    if ( term.length === 0 ) {
      this.users = [];
      this.offerers = [];
      this.offers = [];
      return
    }
    
    this.searchService.searchAll( term )
    .subscribe( resp => {
  
      this.offerers = resp.offerers;
      this.offers = resp.offers;
           
    });

  }

  openContent( content: User | Offerer | Offer, type: any ) {

    this.router.navigate( [ '/user/reload' ] )
    setTimeout( () => { this.router.navigate( [ `/user/${ type }`, content.id ] ) }, 100)

  }

}
