<div class="h-100-vh w-100-vw">
    <div class="row animated fadeIn fast py-4">
        <div class="col-12">
            <div class="text-center h-80-vh mt-5">
                <h4 class="alert-heading">Cargando</h4>
                <i class="fa fa-spin fa-refresh fa-2x"></i>
                <p class="mb-0">Por favor espere</p>
            </div>
        </div>
    </div>
</div>