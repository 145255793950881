<div class="m-auto w-70 mt-5">

    <button (click)="newOffer = !newOffer" class="btn btn-warning rounded-pill mb-4">
        <span>
            <i class="fas me-2" [class.fa-plus]="!newOffer" [class.fa-minus]="newOffer"></i>
            <i class="fas fa-store-alt me-2"></i>Crear local
        </span>
    </button>
    <!-- <h1 class="mb-4">Crear Local</h1> -->
    <div *ngIf="newOffer" class="animated-menu">
        <div class="row m-auto mb-5">
            <div class="card">
                <div class="card-body px-0 px-sm-3">
                    <form class="form" [formGroup]="offererForm" (submit)="newOfferer()">
                        <div class="row">
                            <div class="col-12 my-3">
                                <div class="card h-100">
                                    <div class="card-body">
                                        <h4 class="card-title">Avatar</h4>
                                        <h6 class="card-subtitle">Imagen del Local</h6>
                                        <div class="mt-3 mb-lg-3">
                                            <app-img-select [type]="'offerers'" (newImg)="newImg=$event"></app-img-select>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="col-12 my-3">
                                <div class="card h-100">
                                    <div class="card-body">
                                        <h4 class="card-title">Perfil de Local</h4>
                                        <h6 class="card-subtitle">Ajustes</h6>
                                        <div class="row mt-3">
                                            <div class="form-group mb-3 col-12 col-sm-6">
                                                <label for="exampleInputuname">Nombre</label>
                                                <div class="input-group">
                                                    <div class="input-group-addon"><i class="ti-user"></i></div>
                                                    <input class="form-control" type="text" placeholder="Nombre del local" formControlName="name">
                                                </div>
                                            </div>
                                            <div class="form-group mb-3 col-12 col-sm-6">
                                                <label for="exampleInputEmail1">NIF</label>
                                                <div class="input-group">
                                                    <div class="input-group-addon"><i class="ti-email"></i></div>
                                                    <input class="form-control" type="text" placeholder="NIF" formControlName="nif">
                                                </div>
                                            </div>
                                            <div class="form-group mb-3 col-12 col-sm-8">
                                                <label for="exampleInputEmail1">Dirección</label>
                                                <div class="input-group">
                                                    <div class="input-group-addon"><i class="ti-email"></i></div>
                                                    <input class="form-control" type="text" placeholder="Dirección" formControlName="address">
                                                </div>
                                            </div>
                                            <div class="form-group mb-3 col-12 col-sm-4">
                                                <label for="exampleInputEmail1">CP</label>
                                                <div class="input-group">
                                                    <div class="input-group-addon"><i class="ti-email"></i></div>
                                                    <input class="form-control" type="text" placeholder="Código Postal" formControlName="pc">
                                                </div>
                                            </div>
                                            <div class="form-group mb-3 col-12 col-sm-6">
                                                <label for="exampleInputEmail1">Email</label>
                                                <div class="input-group">
                                                    <div class="input-group-addon"><i class="ti-email"></i></div>
                                                    <input class="form-control" type="email" placeholder="Email" formControlName="email">
                                                </div>
                                            </div>
                                            <div class="form-group mb-3 col-12 col-sm-6">
                                                <label for="exampleInputEmail1">Teléfono</label>
                                                <div class="input-group">
                                                    <div class="input-group-addon"><i class="ti-email"></i></div>
                                                    <input class="form-control" type="text" placeholder="Teléfono" formControlName="phone">
                                                </div>
                                            </div>
                                            <div class="form-group mb-3 col-12 col-sm-6">
                                                <label for="exampleInputEmail1">WhatsApp</label>
                                                <div class="input-group">
                                                    <div class="input-group-addon"><i class="ti-email"></i></div>
                                                    <input class="form-control" type="email" placeholder="WhatsApp" formControlName="whatsApp">
                                                </div>
                                            </div>
                                            <div class="form-group mb-3 col-12 col-sm-6">
                                                <label for="exampleInputEmail1">Sitio web</label>
                                                <div class="input-group">
                                                    <div class="input-group-addon"><i class="ti-email"></i></div>
                                                    <input class="form-control" type="text" placeholder="Sitio web" formControlName="webSite">
                                                </div>
                                            </div>

                                            <app-schedule (scheduleEmit)="schedule=$event"></app-schedule>

                                            <div class="form-group mb-3">
                                                <label for="exampleInputuname">Etiquetas</label>
                                                <div class="input-group border-bottom">
                                                    <div class="input-group-addon"><i class="ti-user"></i></div>
                                                    <div class="my-auto ms-0">
                                                        <span *ngFor="let tag of tags" class="badge rounded-pill bg-secondary h-50 me-1 mb-1">{{ tag | titlecase }}<i (click)="onDeleteTag(tag)" class="fas fa-times-circle ms-1 pointer"></i></span>
                                                    </div>
                                                    <input (change)="onTagsInput( tagsInput.value ); tagsInput.value = ''" class="form-control border-0" list="tagsList" type="text" placeholder="Etiquetas" formControlName="tags" #tagsInput>
                                                    <datalist id="tagsList">
                                                <option *ngFor="let tagListItem of tagsList" [value]="tagListItem">
                                            </datalist>
                                                </div>
                                            </div>
                                            <div class="form-group mb-3 col-12">
                                                <label for="exampleInputEmail1">Descripción</label>
                                                <div class="input-group">
                                                    <div class="input-group-addon"><i class="ti-email"></i></div>
                                                    <textarea class="form-control" type="text" placeholder="Descripción" formControlName="description"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col text-warning">

                                <p *ngIf="invalidField('name')">* Comprobar nombre</p>
                                <p *ngIf="invalidField('nif') || invalidNIF()">* Comprobar NIF</p>
                                <p *ngIf="invalidField('address')">* Comprobar dirección</p>
                                <p *ngIf="invalidField('pc')">* Comprobar CP</p>
                                <p *ngIf="invalidField('email')">* Comprobar email</p>
                                <p *ngIf="invalidField('phone')">* Comprobar teléfono</p>

                            </div>
                        </div>

                        <div class="row">
                            <div class="col">
                                <!-- [disabled]="offererForm.invalid" -->
                                <button type="submit" class="btn btn-warning text-white fw-bolder text-y-shadow my-3 w-100" [disabled]="offererForm.untouched || !offererForm.controls">
                                Crear Local
                            </button>
                            </div>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    </div>
    <h1 class="mb-4">Mis Locales</h1>
    <app-settings-grid [type]="'offerers'"></app-settings-grid>

</div>