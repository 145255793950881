<div class="modal-background animated fadeIn fast" [class.show-none]="modalService.hideModal">

    <div class="fade show" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel1" style="display: block;">

        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title" id="exampleModalLabel1">Cortar imagen</h4>
                    <button type="button" class="btn close" data-dismiss="modal" aria-label="Close" (click)="this.update.emit(false);this.cropEmit.emit(false);closeModal()">
                        <span aria-hidden="true">×</span>
                </button>
                </div>
                <div class="modal-body m-auto shadow rounded overflow-hidden my-3 p-0">

                    <div class="img-container bg-dark" [class.crop-style]="windowWidth <= 992">
                        <img #image [src]="imgSrc" class="show-none" crossorigin>
                    </div>
                    <div *ngIf=(loading) class="img-container bg-light position-absolute d-flex">
                        <span class="m-auto"><i class="fas fa-spinner fa-spin fa-2x"></i></span>
                    </div>

                </div>
                <div class="w-100 text-center">
                    <div class="w-70 m-auto">
                        <span (click)="cropper.setDragMode('move') && cropper.crop" class="btn btn-primary m-1"><i class="fas fa-arrows-alt"></i></span>
                        <span (click)="cropper.setDragMode('crop')" class="btn btn-primary m-1"><i class="fas fa-crop-alt"></i></span>
                        <span (click)="cropper.reset()" class="btn btn-primary m-1"><i class="fas fa-undo-alt"></i></span>
                    </div>
                </div>

                <!-- <img [src]="imgDestination" class="img-preview"> -->
                <div class="modal-footer">
                    <button (click)="this.update.emit(false);this.cropEmit.emit(false);closeModal();" type="button" class="btn btn-outline-secondary" data-dismiss="modal">
                    Cancelar
                </button>
                    <button (click)="modifyImg()" type="button" class="btn btn-warning fw-bolder">Actualizar foto</button>
                </div>
            </div>
        </div>
    </div>
</div>