<div class="container text-center text-white w-95 mt-5">
    <div class="row">
        <div class="col">
            <img class="img-fluid mh-20-vh" src="../../../assets/img/blanco-amarillo.svg" alt="LoCana">
        </div>
    </div>

    <form class="form-horizontal form-material position-relative" id="loginform" autocomplete="off" [formGroup]="registerForm" (ngSubmit)="createUser()">

        <h3 class="box-title">Registrarse</h3><small>Crea una cuenta y disfruta</small>
        <div class="form-group mt-3">
            <div class="row">
                <div class="col-12 col-sm-6 pe-sm-1 mb-2 mb-sm-0">
                    <input class="form-control rounded-pill" type="text" placeholder="Nombre" formControlName="name">
                </div>
                <div class="col-12 col-sm-6 mb-2 ps-sm-1">
                    <input class="form-control rounded-pill" type="text" placeholder="Apellidos" formControlName="lastName">
                </div>
            </div>
        </div>
        <div class="form-group">
            <div class="row">
                <div class="col-12 col-sm-6 pe-sm-1 mb-2 mb-sm-0">
                    <input class="form-control rounded-pill" type="text" placeholder="Usuario" formControlName="nickName">
                </div>
                <div class="col-12 col-sm-6 mb-2 ps-sm-1">
                    <input class="form-control rounded-pill" type="text" placeholder="DNI/NIF" formControlName="identificationCode">
                </div>
            </div>
        </div>
        <div class="form-group">
            <div class="row">
                <div class="col-12 col-sm-6 pe-sm-1 mb-2 mb-sm-0">
                    <input class="form-control rounded-pill" type="tel" placeholder="Móvil" formControlName="phone">
                </div>
                <div class="col-12 col-sm-6 mb-2 ps-sm-1">
                    <input class="form-control rounded-pill" type="email" placeholder="Email" formControlName="email">
                </div>
            </div>
        </div>
        <div class="form-group">
            <div class="row">
                <div class="col-12 col-sm-6 pe-sm-1 mb-2 mb-sm-0">
                    <input class="form-control rounded-pill" type="password" placeholder="Contraseña" formControlName="password">
                </div>
                <div class="col-12 col-sm-6 mb-2 ps-sm-1">
                    <input class="form-control rounded-pill" type="password" placeholder="Confirmar Contraseña" formControlName="password2">
                </div>
            </div>
        </div>

        <div class="form-group">
            <div class="row">
                <div class="col-12 col-sm-6 m-auto my-3">
                    <input class="form-control rounded-pill" type="text" placeholder="Referencia" formControlName="reference" title="Introduce la matrícula de tu amigo para conseguir un mes gratis">
                </div>
            </div>
        </div>

        <div class="form-group row">
            <div class="col-12 mb-2">
                <div class="checkbox checkbox-primary p-t-0">
                    <input class="form-check-input checkbox-bg" type="checkbox" formControlName="terms" id="checkbox-signup">
                    <label for="checkbox-signup" class="ms-2"> Acepto los <a class="text-warning" routerLink="/init/terms" target="_blank" ><b>Terminos y Condiciones</b></a></label>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col text-warning">

                <p *ngIf="invalidField('name')">* El nombre es obligatorio</p>
                <p *ngIf="invalidField('lastName')">* El apellido es obligatorio</p>
                <p *ngIf="invalidField('nickName')">* El nickName es obligatorio</p>
                <p *ngIf="invalidField('email')">* El email es obligatorio y tiene que ser válido</p>
                <p *ngIf="invalidField('identificationCode') || invalidIdentification()"><span>* El {{ identificationType === 'error' ? 'DNI/NIE' : (identificationType | uppercase) }} es obligatorio y tiene que ser válido</span></p>
                <p *ngIf="invalidField('phone')">* El teléfono tiene que ser válido</p>
                <p *ngIf="invalidPassword()">* Las contraseñas deben de ser iguales</p>
                <p *ngIf="acceptTerm()">* Debe de aceptar los términos de uso</p>

            </div>
        </div>

        <div class="form-group text-center my-2">
            <div class="col-12">
                <button class="btn btn-warning text-white fw-bolder text-y-shadow" type="submit">Registrarse</button>
            </div>
        </div>
        <div class="form-group">
            <div class="col-12 text-center">
                <p>¿Ya tienes una cuenta? <a routerLink="/init/auth/login" class="text-warning text-decoration-none ms-2"><b>Iniciar</b></a></p>
            </div>
        </div>
        <div class="form-group pb-3">
            <div class="col-12 text-center">
                <p>¿Problemas para registrarte? <a routerLink="/init/contact" class="text-warning text-decoration-none ms-2"><b>Contactar</b></a></p>
            </div>
        </div>
    </form>
</div>