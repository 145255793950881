<div class="card text-center mw-300-px m-auto px-3 py-4">
    <h3>Nivel {{ level }}</h3>
    <select (change)="type=typeSel.value;amountCalc();" name="typeSel" [id]="'typeSel'+level" #typeSel>
        <option value="monthly">Mensual</option>
        <option value="yearly">Anual</option>
    </select>
    <p class="f-xxl"><b>{{ amount / 100 }}€</b></p>
    <p class="o-075 f-s">{{ includes[ level - 1 ][0] }}</p>
    <p class="o-075 f-s">{{ includes[ level - 1 ][1] }}</p>
    <button (click)="onClick()" type="button" class="btn btn-primary" [disabled]="btnText()==='vigente'||btnText()==='prox. renovación'">{{ btnText() | titlecase }}</button>
</div>