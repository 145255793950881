import { Component, Input, OnInit, OnChanges, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styles: [
  ]
})
export class ScheduleComponent implements OnInit {

  @Input() edit: boolean = true;
  @Input() scheduleInput: object;
  @Input() isOpen: boolean = false;
  @Output() scheduleEmit: EventEmitter< object > = new EventEmitter();

  public week: string[] = ['lunes','martes','miercoles','jueves','viernes','sabado','domingo'];
  public weekShort: string[] = ['mon','tue','wed','thu','fri','sat','sun'];
  // public week: string[] = ['l','m','x','j','v','s','d'];
  public collapsed: boolean = true;
  public scheduleForm: FormGroup;
  public init: object;
  public schedule: object;
  public invalid: number = 8;

  public setWeek: boolean = false;
  public setWeekend: boolean = false;

  constructor(  private fb: FormBuilder  ) {}

  async ngOnInit() {
    
    
    this.init = { match: false };
    for ( let i = 0; i<7; i++ ) {
      this.init = { ...this.init, ['open' + i]: [''] };
      this.init = { ...this.init, ['close' + i]: [''] };
      this.init = { ...this.init, ['matchOpen' + i]: [''] };
      this.init = { ...this.init, ['matchClose' + i]: [''] };
    }

    this.scheduleForm = this.fb.group( this.init );

    this.scheduleInit();

  }

  scheduleInit() {

    setTimeout( () => {
      if ( this.scheduleInput ) {
        let i = 0;
        this.init = { match: this.scheduleInput['match'] };
        this.weekShort.forEach( ( day ) => {
          this.init = { ...this.init, ['open' + i]: [this.scheduleInput['days'][day].open] };
          this.init = { ...this.init, ['close' + i]: [this.scheduleInput['days'][day].close] };
          if ( this.scheduleInput['match'] ) {
            this.init = { ...this.init, ['matchOpen' + i]: [this.scheduleInput['ays'][day].matchOpen] };
            this.init = { ...this.init, ['matchClose' + i]: [this.scheduleInput['days'][day].matchClose] };
          }
          i++;
        });
      this.scheduleForm = this.fb.group( this.init );
      }
    }, 500);
    
  }

  scheduleConstructor () {

    const formControls = this.scheduleForm.controls;

    if ( this.validateSchedule() ) {
      return;
    }

    this.schedule = { match: this.scheduleForm.controls['match'].value };
    let days = {};
    let i = 0;
    if ( this.schedule['match'] ) {
      this.weekShort.forEach( day => {
        days = { ...days, [day]: {
          open: formControls['open' + i].value,
          close: formControls['close' + i].value,
          matchOpen: formControls['matchOpen' + i].value,
          matchClose: formControls['matchClose' + i].value
        }}
        i++;
      });
    } else {
      this.weekShort.forEach( day => {
        days = { ...days, [day]: {
          open: formControls['open' + i].value,
          close: formControls['close' + i].value,
        }}
        i++;
      });
    }

    this.schedule = { ...this.schedule, days };
    
    this.scheduleEmit.emit( this.schedule );

  }

  validateSchedule() {
    
    const formControls = this.scheduleForm.controls;

    for ( let i = 0; i < 7; i++ ) {
      
      if ( formControls['matchOpen' + i].value ) {
        if (  formControls['open' + i].value > formControls['close' + i].value || 
              formControls['close' + i].value > formControls['matchOpen' + i].value || 
              formControls['matchOpen' + i].value > formControls['matchClose' + i].value ) {
          this.invalid = i;
          return true;
        } else {
          this.invalid = 8;
        }
      } else {
        if (  formControls['open' + i].value > formControls['close' + i].value ) {
          this.invalid = i;
          return true;
        } else {
          this.invalid = 8;
        }
      }
      
    }
    
  }

  setHours( option: string, hour: string ) {
    
    let start = 0
    let end = 5
    if ( this.setWeekend ) {
      start = 5
      end = 7
    }

    for ( let i = start; i < end; i++ ) {
      this.scheduleForm.controls[option + i].setValue(hour)
    }

  }

  @ViewChild('open') open: ElementRef;
  @ViewChild('close') close: ElementRef;
  @ViewChild('matchOpen') matchOpen: ElementRef;
  @ViewChild('matchClose') matchClose: ElementRef;

  setW( period: string ) {

    const formControls = this.scheduleForm.controls;
    let i = 0;

    if ( period === 'week' ) {
      this.setWeek =  !this.setWeek;
      this.setWeekend = false
    } else {
      this.setWeekend =  !this.setWeekend;
      this.setWeek = false
      i = 5;
    }
    
    this.open.nativeElement.value = formControls['open' + i].value
    this.close.nativeElement.value = formControls['close' + i].value
    this.matchOpen.nativeElement.value = formControls['matchOpen' + i].value
    this.matchClose.nativeElement.value = formControls['matchClose' + i].value

  }

}
