import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ModalNewService {

  private _hideModal: boolean = true;
  public type: 'users'|'offerers'|'offers'|'raffles';
  public id: string;
  public img: string;
  public changes: boolean = false;

  public newImage: EventEmitter<string> = new EventEmitter<string>();

  get hideModal() {
    return this._hideModal;
  }

  openModal() {
    this._hideModal = false;
  }

  closeModal() {
    this._hideModal = true;
    this.changes = true;
  }

  constructor() { }

}
