import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

import { AuthGuard } from '../guards/auth.guard';

import { ProfileComponent } from './profile/profile.component';
import { HomeComponent } from './home/home.component';
import { InitComponent } from '../init/init/init.component';
import { PagesComponent } from './pages/pages.component';
import { NewOffererComponent } from './new-offerer/new-offerer.component';
import { UsersMaintenanceComponent } from './maintenance/users.maintenance/users.maintenance.component';
import { MaintenanceModule } from './maintenance/maintenance.module';
import { MaintenanceRoutingModule } from './maintenance/maintenance-routing.module';
import { MaintenanceComponent } from './maintenance/maintenance/maintenance.component';
import { OfferersMaintenanceComponent } from './maintenance/offerers.maintenance/offerers.maintenance.component';
import { OffersMaintenanceComponent } from './maintenance/offers.maintenance/offers.maintenance.component';
import { OfferComponent } from './offer/offer.component';
import { OffererComponent } from './offerer/offerer.component';
import { AdminGuard } from '../guards/admin.guard';
import { OffererGuard } from '../guards/offerer.guard';
import { ReloadComponent } from './reload/reload.component';
import { InitHomeComponent } from '../init/init-home/init-home.component';
import { ContactComponent } from './contact/contact.component';
import { OfferCheckerComponent } from './offer-checker/offer-checker.component';
import { FavsComponent } from './favs/favs.component';
import { RaffleComponent } from './raffle/raffle.component';
import { RafflesMaintenanceComponent } from './maintenance/raffles.maintenance/raffles.maintenance.component';
import { PaySuccessComponent } from './pay-success/pay-success.component';
import { PayDeniedComponent } from './pay-denied/pay-denied.component';
import { PricingComponent } from './pricing/pricing.component';



const routes: Routes = [

    {
        path: 'user',
        canActivate: [ AuthGuard ],
        component: PagesComponent,
        children: [
            { path: '', redirectTo: 'offers', pathMatch: 'full' },
            // { path: 'grid/:type', component: HomeComponent },
            { path: 'offers', component: HomeComponent },
            { path: 'offerers', component: HomeComponent },
            { path: 'raffles', component: HomeComponent },
            { path: 'profile', component: ProfileComponent },
            { path: 'offerer/:id', component: OffererComponent },
            { path: 'offer/:id', component: OfferComponent },
            { path: 'raffle/:id', component: RaffleComponent },
            { path: 'favs', component: FavsComponent},
            { path: 'pricing', component: PricingComponent},
            { path: 'pay-success', component: PaySuccessComponent},
            { path: 'pay-denied', component: PayDeniedComponent},
            { path: 'new-offerer', component: NewOffererComponent, canActivate: [ OffererGuard ] },
            { path: 'offer-checker', component: OfferCheckerComponent, canActivate: [ OffererGuard ] },
            { path: 'offer-checker/:offerReg/:clientReg', component: OfferCheckerComponent, canActivate: [ OffererGuard ] },
            // { path: 'maintenance', component: MaintenanceRoutingModule },
            // Maintenance
            { path: 'maintenance',
              canActivate: [ AdminGuard ],
              component: MaintenanceComponent,
              children: [
                { path: '', redirectTo: 'users', pathMatch: 'full' },
                { path: 'users', component: UsersMaintenanceComponent },
                { path: 'offerers', component: OfferersMaintenanceComponent },
                { path: 'offers', component: OffersMaintenanceComponent },
                { path: 'raffles', component: RafflesMaintenanceComponent },
                { path: '**', redirectTo: 'users', pathMatch: 'full' },
                ]
            },
            { path: 'contact', component: ContactComponent },
            { path: 'contact/:report', component: ContactComponent },
            { path: 'reload', component: ReloadComponent },
            { path: '**', redirectTo: '', pathMatch: 'full' },
        ]
    }

];

@NgModule({
    imports: [ RouterModule.forChild(routes) ],
    // , MaintenanceRoutingModule],
    exports: [ RouterModule ]
})
export class PagesRoutingModule {}