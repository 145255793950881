<div class="init-bg d-flex justify-content-center align-items-center">
    <div class="container text-center text-white login position-relative">
        <div class="row">
            <div class="col mb-3">
                <img class="img-fluid mh-20-vh" src="../../../assets/img/blanco-amarillo.svg" alt="LoCana">
            </div>
        </div>
        <form class="form-horizontal form-material" [formGroup]="loginForm" (ngSubmit)="onLogin()" id="loginform">

            <div class="form-group m-t-40 mb-3">
                <div class="col-xs-12">
                    <input class="form-control rounded-pill" type="text" placeholder="Email o Usuario" autocomplete="username" formControlName="access">
                </div>
            </div>
            <div class="form-group mb-3">
                <div class="col-xs-12">
                    <input class="form-control rounded-pill" type="password" placeholder="Contraseña" autocomplete="current-password" formControlName="password">
                </div>
            </div>
            <div class="form-group row mb-3">
                <div class="col-md-12">
                    <div class="checkbox checkbox-primary pull-left p-t-0">
                        <input class="form-check-input checkbox-bg mb-3" id="checkbox-signup" type="checkbox" formControlName="remember">
                        <label for="checkbox-signup" class="ms-2"> Recuerdame </label>
                    </div>
                    <a routerLink="/init/auth/recover" id="to-recover" class="text-white text-decoration-none"><i class="fa fa-lock m-r-5"></i> Olvidó la contraseña?</a> </div>
            </div>
            <div class="form-group text-center m-t-20">
                <div class="col-xs-12">
                    <button class="btn btn-warning text-white fw-bolder text-y-shadow px-5 my-3" type="submit">Iniciar</button>
                </div>
            </div>
            <div class="form-group text-center mb-3">
                <div class="col-sm-12 text-center">
                    No tienes cuenta? <a routerLink="/init/auth/register" class="text-warning text-decoration-none ms-2"><b>Registrarse</b></a>
                </div>
            </div>
        </form>
    </div>

</div>