import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { PcLocationForm } from '../interfaces/pc-location.interface';

@Injectable({
  providedIn: 'root'
})
export class PcLocationService {

  constructor(  private http: HttpClient ) { }

  location() {

    const url = `../../assets/pc-location.json`;
    return this.http.get<PcLocationForm[]>( url )
        .pipe(
          map( resp => resp )
        );
            
  }

}
