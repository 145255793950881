
  import { Injectable } from '@angular/core';
  import { HttpClient } from '@angular/common/http';
  import { map } from 'rxjs/operators';
  
  import { environment } from '../../environments/environment';
  import { Offer } from '../models/offer.model';
  import { NewOfferForm } from '../interfaces/new-offer-form.interface';
  import { LoadOffers } from '../interfaces/load-offers.inteface';
  import { Offerer } from '../models/offerer.model';
  
  const base_url = environment.base_url;


@Injectable({
  providedIn: 'root'
})
export class OfferService {
  
    constructor(  private http: HttpClient ) { }
  
    get token(): string {
      return localStorage.getItem('token') || '';
    }
  
    get headers() {
      return {
        headers: {
          'x-token': this.token
        }
      }
    }
  
    loadOffers( from: number = 0, limit: number = 10 ) {

      let searchTags = [];
      if ( localStorage.getItem('searchTags') ) {
        searchTags = localStorage.getItem('searchTags').split(';');
      }

      const url = `${ base_url }/offers/${ searchTags }?from=${ from }&limit=${ limit }`;
      return this.http.get<LoadOffers>( url, this.headers )
              .pipe(
                map( (resp: {ok: boolean, offers: Offer[], total: number }) => resp )
              );
    }

    loadOffersById( id: string ) {

      const url = `${ base_url }/offers/offer/${ id }`;
      return this.http.get( url, this.headers )
              .pipe(
                map( (resp: {ok: boolean, offer: Offer }) => resp.offer)
              );
    }

    loadOffersByType( from: number = 0, limit: number = 10, id: string, type: string ) {

      const url = `${ base_url }/offers/${ type }/${ id }?from=${ from }&limit=${ limit }`;
      return this.http.get( url, this.headers )
              .pipe(
                map( (resp: {ok: boolean, offers: Offerer[] | Offer[], total: number }) => resp)
              );

    }

    loadFavs( from: number = 0, limit: number = 10, id: string, type: string ) {

      const url = `${ base_url }/offers/favs/${ type }/${ id }?from=${ from }&limit=${ limit }`;
      return this.http.get( url, this.headers )
              .pipe(
                map( (resp: {ok: boolean, favs: Offerer[] | Offer[], total: number }) => resp )
              );

    }
    
    // Load if there is no pagination
    // loadOffers() {
  
    //   const url = `${ base_url }/offers`;
    //   return this.http.get( url, this.headers )
    //             .pipe(
    //               map( (resp: {ok: boolean, offers: Offer[] }) => {console.log(resp);
                  
    //                 return resp.offers} )
    //             );
    // }
  
    createOffer( formData: NewOfferForm ) {
      
      return this.http.post(`${ base_url }/offers`, formData, this.headers );
  
    }
    
    updateOffer( _id: string, data: object  ) {

      const url = `${ base_url }/offers/${ _id }`;
      return this.http.put( url, data, this.headers );

    }

    checkOffer( data: object ) {

      const url =`${base_url}/offers/check/reg`;
      return this.http.put( url, data, this.headers );

    }
  
    deleteOffer( _id: string ) {
  
      const url = `${ base_url }/offers/${ _id }`;
      return this.http.delete( url, this.headers );
      
    }
  
  }
  