import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { tap } from 'rxjs/operators';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(  private authService: AuthService,
                private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {
      
      var token = this.authService.token
            
      if ( token === '' || !token) { return this.router.navigateByUrl('/init'); };

      return this.authService.validateToken()
        .pipe(
          tap( isAuth =>  {
            if ( !isAuth ) {
              this.router.navigateByUrl('/init');
            }
          })
        );
  }

}
