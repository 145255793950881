import { Component, OnInit, ViewChild, HostListener, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import Swal from 'sweetalert2';

import { UploadService } from '../../services/upload.service';

import { AuthService } from '../../services/auth.service';
import { Offerer } from '../../models/offerer.model';
import { User } from 'src/app/models/user.model';
import { OffererService } from '../../services/offerer.service';
import { delay } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalNewService } from '../../services/modal-new.service';
import { OffererFilterGridComponent } from '../../components/grids/offerer-filter-grid/offerer-filter-grid.component';
import { LoadOfferers } from '../../interfaces/load-offerers.inteface';
import { PcLocationService } from '../../services/pc-location.service';
import { ValidatorsService } from '../../services/validators.service';
import { UserService } from '../../services/user.service';
import { MainGridComponent } from '../../components/grids/main-grid/main-grid.component';

@Component({
  selector: 'app-offerer',
  templateUrl: './offerer.component.html',
  styles: [
  ]
})
export class OffererComponent implements OnInit {
  
  @ViewChild(OffererFilterGridComponent) offererFilterGridComponent: OffererFilterGridComponent;
  @ViewChild(MainGridComponent) mainGridComponent: MainGridComponent;

  public offererForm: FormGroup;
  public offerer: Offerer;
  public user: User;
  public newImg: File;
  public tempImg: any = null;

  public owner: boolean = false;
  public edit: boolean = false;

  public offererID: string;

  public tags: string[] = ['general'];
  public tagsList: string[] = ['Comida','Deporte','Moda','Ocio', 'Salud','Otros']

  public schedule: object;
  public address: object;
  public contact: object;
  public town: string;
  public province: string;

  public contacts: string[] = ['email','phone','whatsApp', 'webSite'];
  public contactsSp: string[] = ['Email','Teléfono','WhatsApp', 'Sitio web'];
  public contactsRef: string[] = ['mailTo:','tel:','https://api.whatsapp.com/send?phone=', 'https://'];
  public contactsIcon: string[] = ['fas fa-envelope','fas fa-phone','fab fa-whatsapp', 'fas fa-globe'];
  public contactShow: string;
  public contactIndex: number = this.contacts.length + 1;

  public windowWidth: number;
  public maxLength: number = 200;
  public offererImg: string;
  public fullDescription: boolean = false;
  public formSubmitted: boolean = false;
    
  public slidePage: string[] = ['offers', 'raffles'];
  public selectedType: 'offers'|'raffles'|string = 'offers';
  
  public slide: boolean = false;
  public startPointX: number;
  public startPointY: number;
  public changed: boolean = true;

  constructor( private fb: FormBuilder,
               private offererService: OffererService,
               private authService: AuthService,
               private fileUploadService: UploadService,
               private activatedRoute: ActivatedRoute,
               private modalNewService: ModalNewService,
               private router: Router,
               private pcLocationService: PcLocationService,
               private validatorsService: ValidatorsService,
               private userServive: UserService, ) {
          
                this.user = this.authService.user;
                this.windowWidth = window.innerWidth;
            
              }

  ngOnInit() {

    this.selectedType = localStorage.getItem( 'offererType' ) || 'offers';

    if ( this.windowWidth < 575) {
      this.maxLength = this.windowWidth * 5 / 100;
    } else if ( this.windowWidth < 768 ) {
      this.maxLength = this.windowWidth * 4 / 100;
    } else {
      this.maxLength = this.windowWidth * 3.5 / 100;
    }
    
    const { id } = this.activatedRoute.snapshot.params;
    
    this.offererID = id;

    this.offererForm = this.fb.group({
      name: [ '' , Validators.required ],
      nif: [ '', [ Validators.required, Validators.pattern('[0-9a-z]{7,12}$') ] ],
      pc: [ '', [ Validators.required, Validators.pattern('[0-9]{5}$') ] ],
      address: [ '', Validators.minLength(5) ],
      contact: [ '' ],
      email: [ '', Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$') ],
      phone: [ '', Validators.pattern('[+0-9]{8,15}$') ],
      whatsApp: [ '', Validators.pattern('[+0-9]{8,15}$') ],
      webSite: [ '' ],
      schedule: [ {} ],
      description: [ '' ],
      img: [ 'no-img' ],
      tags: [ this.tags ],
    });

    this.offererService.loadOfferersById( id )
      .subscribe( ( offerer ) => {

        this.offerer = offerer

        this.tags = offerer.tags;
        if ( this.tags.length === 0 ) {
          this.tags = ['general'];
        }

        let offererContacts = [];

        if ( offerer.contact ) {
          for ( let i = 0; i < this.contacts.length; i++ ) {
            if ( offerer.contact[ this.contacts[ i ] ] ) {
              offererContacts = [ ...offererContacts, offerer.contact[ this.contacts[ i ] ] ]
            } else {
              offererContacts = [ ...offererContacts, '' ]
            }
          }
        } else {
          offererContacts = [ '', '', '', '' ];
        }
        
        this.offererForm = this.fb.group({
          name: [ offerer.name ,  Validators.required ],
          nif: [ offerer.nif, Validators.required ],
          pc: [ offerer.address.pc, [ Validators.required, Validators.pattern('[0-9]{5}$') ] ],
          address: [ offerer.address.address, Validators.minLength(5) ],
          contact: [ '' ],
          email: [ offererContacts[ 0 ], Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$') ],
          phone: [ offererContacts[ 1 ],  Validators.pattern('[+0-9]{8,15}$') ],
          whatsApp: [ offererContacts[ 2 ],  Validators.pattern('[+0-9]{8,15}$') ],
          webSite: [ offererContacts[ 3 ] ],
          schedule: [ this.schedule ],
          description: [ offerer.description ],
          img: [ offerer.img],
          tags: [ ],
        }, {
          validators: [ this.nifValidator( 'nif' ) ]
        });

        if ( this.user.id === this.offerer.user._id || this.user.role === "ADMIN_ROLE"  ) {
          this.owner = true;
        }

        this.offererImg = offerer.img;
        
      });

  }
    
  async saveOfferer() {

    const formControls = this.offererForm.controls;
    this.formSubmitted = true;

    this.format();

    if ( this.offererForm.invalid) {
      Swal.fire('Error', 'Compruebe los campos', 'error');
      return
    }

    const pc = formControls['pc'].value;

    await this.pcLocationService.location().subscribe( data => {
      this.town = data.filter( f => { return f.pc === pc })[0].town;
      this.province = data.filter( f => { return f.pc === pc })[0].province;
      this.address = {
        pc: pc,
        address: formControls['address'].value,
        town: this.town,
        province: this.province
      }
  
      this.contact = {
        phone: formControls[ 'phone' ].value,
        email: formControls[ 'email' ].value,
        whatsApp: formControls[ 'whatsApp' ].value,
        webSite: formControls[ 'webSite' ].value,
      }
      
      formControls[ 'schedule' ].setValue( this.schedule );
      formControls[ 'address' ].setValue( this.address );    
      formControls[ 'contact' ].setValue( this.contact );  
      formControls[ 'tags' ].setValue( this.tags );
  
      if ( this.newImg ) {
  
        this.updateImg();
        
      } else {
        
        this.updateOfferer();
  
      }
      
      return;
    });


  }

  updateOfferer() {

    this.offererService.updateOfferer( this.offerer.id, this.offererForm.value )
        .subscribe( () => {

          this.formSubmitted = false;
          this.userServive.contentsReset();
          Swal.fire('Guardado', 'Cambios fueron guardados', 'success');
          this.router.navigate( [ '/user/reload' ] );
          setTimeout( () => { 
            this.router.navigate( [ `/user/offerer`, this.offerer.id ] )
          }, 100);

        }, (err) => {

          console.log(err);
          Swal.fire('Error', err.error.msg, 'error');

        });

    this.offererForm.controls['tags'].setValue( '' );
  }

  updateImg() {
  
    this.fileUploadService
      .updateImg( this.newImg, 'offerers', this.offerer.id )
      .then( ( img: string ) => {
        
        if (!img) {
          img = 'no-img'
        }

        this.offererForm.controls[ 'img' ].setValue( img );

        this.updateOfferer();

      }).catch( err => {

        console.log(err);
        Swal.fire('Error', 'No se pudo subir la imagen', 'error');
        
      });
  
  }

  changeImg( event: Event ) {
    const target = event.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];
    this.newImg = file;

    if ( !file ) {
      return this.tempImg = null;
    }

    const reader = new FileReader();
    reader.readAsDataURL( file );

    reader.onloadend = () => {
      this.tempImg = reader.result;
    }

  }

  delete() {

    Swal.fire({
      title: 'Eliminar Local?',
      text: `Esta a punto de Eliminar a ${ this.offerer.name }`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Si, borrarlo'
    }).then((result) => {
      if (result.value) {
        this.offererService.deleteOfferer( this.offerer.id )
          .subscribe( resp => {

            this.userServive.contentsReset();
            
            Swal.fire(
              'Local Eliminado',
              `${ this.offerer.name } fue eliminado correctamente`,
              'success'
            );

            this.router.navigateByUrl('/user/offerers');

          });

      }
    });

  }

  openModal() {
    
    this.modalNewService.openModal();

  }

  created() {
    this.router.navigate( [ '/user/reload' ] );
    setTimeout( () => { this.router.navigate( [ `/user/offerer`, this.offerer.id ] ) }, 100);
  }

  onTagsInput( tagsInput: string ) {
    
    let valid: boolean = true;

    if ( !this.tagsList.includes( tagsInput ) ) {
      return;
    }

    tagsInput = tagsInput.toLowerCase();
        
    for( let tag of this.tags ) {
      if ( tag === tagsInput ) {
        valid = false;
        return;
      }
    }
    
    if ( !valid ) {
      return;
    }

    for( let tag of this.tags ) {
      if ( tag === 'general' ) {        
        const index = this.tags.indexOf( tag );
        this.tags.splice( index, 1 );
      }
    }

    this.tags = [ ...this.tags, tagsInput ];

  }

  onDeleteTag( tagsDeleted: string ) {

    for( let tag of this.tags ) {
      
      if ( tag === tagsDeleted ) {        
        const index = this.tags.indexOf( tag );
        this.tags.splice( index, 1 );
        if ( this.tags.length === 0 ) {
          this.tags = ['general'];
        }
        return;
      }

    }

  }

  invalidField( field: string ): boolean {

    if ( this.offererForm.get( field ).invalid && this.formSubmitted ) {
      return true;
    } else {
      return false;
    }

  }

  nifValidator ( nif: string ) {
    
    return ( formGroup: FormGroup ) => {
      const nifControl = formGroup.get( nif );
      if ( !this.formSubmitted ) {
        return nifControl.setErrors( null );
      }

      const { ok } = this.validatorsService.validateSPIdentification( nifControl.value );
      
      if ( ok ) {
        return nifControl.setErrors( null );
      } else {
        return nifControl.setErrors( { invalidNIF: true } );
      }

    }

  }

  invalidNIF() {

    if ( !this.formSubmitted ) {
      return false;
    }

    const { ok } = this.validatorsService.validateSPIdentification( this.offererForm.controls['nif'].value );
    return !ok

  }

  format() {
    
    this.itemFormat('name');
    this.itemFormat('nif');
    this.itemFormat('address');
    this.itemFormat('email');
    this.itemFormat('webSite');

  }

  itemFormat( item ) {

    const registerForm = this.offererForm.controls;

    let finalValue = registerForm[ item ].value.trim();

    if ( item === 'nif' ) {
      finalValue = finalValue.toUpperCase()
    } else {
      finalValue = finalValue.toLowerCase()
    }
    
    registerForm[ item ].setValue( finalValue );
    
  }

  // @HostListener('mousedown', ['$event'])
  // onMouseDown() {  
  //   this.slide = true;
  //   this.changed = false;
  // }

  // @HostListener('mouseup', ['$event'])
  // onMouseUp() {
  //   this.slide = false;
  // }

  // @HostListener('mousemove', ['$event'])
  // onMouseMove( event: MouseEvent ) {
  //   if ( this.slide && !this.changed ) { 
  //     event.preventDefault();
  //     if ( Math.abs(event.movementX) > 20 && Math.abs(event.movementY) < 10 ) {
  //       this.typeChange();
  //       this.changed = true;
  //     }
  //   }
  // }
  
  @HostListener('touchstart', ['$event'])
  onTouchStart( event: TouchEvent ) {
    this.startPointX = event.touches.item(0).screenX;
    this.startPointY = event.touches.item(0).screenY;
    this.slide = true;
    this.changed = false;
  }
  
  @HostListener('touchend', ['$event'])
  onTouchEnd() {
    this.slide = false;
  }

  @HostListener('touchmove', ['$event'])
  onTouchMove( event: TouchEvent ) {
    if ( this.slide && !this.changed && this.modalNewService.hideModal) {
      if ( Math.abs(event.touches.item(0).screenX - this.startPointX) > 100 && Math.abs(event.touches.item(0).screenY - this.startPointY) < 20 ) {
        this.typeChange();
        this.changed = true;
      }
    }
  }

  typeChange() {

    if ( this.selectedType === 'offers' ) {
      this.selectedType='raffles'
    } else {
      this.selectedType='offers'
    }

    localStorage.setItem( 'offererType', this.selectedType )

    const OFG = this.offererFilterGridComponent;

    OFG.type = this.selectedType;
    OFG.empty = false;
    OFG.from = 0;
    OFG.to = OFG.limit;
    OFG.contents = [];
    OFG.contentsTemp = [];
    if ( OFG.searchTerm ) {
      OFG.loadContents();
      OFG.search( OFG.searchTerm );
    } else {
      OFG.loadContents();
    }

  }

}
