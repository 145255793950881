import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ValidatorsService {

  constructor() { }
  //Variation of https://gist.github.com/afgomez/5691823
  validateSPIdentification ( identification: string ) {

    const DNI_REGEX = /^(\d{8})([A-Z])$/;
    const NIE_REGEX = /^[XYZ]\d{7,8}[A-Z]$/;
    const NIF_REGEX = /^([ABCDEFGHJKLMNPQRSUVW])(\d{7})([0-9A-J])$/;

    if ( identification.match( DNI_REGEX ) ) {
      return {
        ok: this.validSPDNI( identification ),
        identificationType: 'dni',
      };
    } else 
    if ( identification.match( NIE_REGEX ) ) {
      return {
        ok: this.validSPNIE( identification ),
        identificationType: 'nie',
      };
    } else 
    if ( identification.match( NIF_REGEX ) ) {
      const match = identification.match( NIF_REGEX )
      return {
        ok: this.validSPNIF( match ),
        identificationType: 'nif',
      };
    }

    return {
      ok: false,
      identificationType: 'error',
    };

  }

  validSPDNI ( dni: string ) {

    const dni_letters = "TRWAGMYFPDXBNJZSQVHLCKE";
    let letter = dni_letters.charAt( parseInt( dni, 10 ) % 23 ); 
    
    return letter == dni.charAt(8);

  }

  validSPNIE ( nie: any ) {

    let nie_prefix = nie.charAt( 0 );
    switch (nie_prefix) {
      case 'X': nie_prefix = 0; break;
      case 'Y': nie_prefix = 1; break;
      case 'Z': nie_prefix = 2; break;
    }

    return this.validSPDNI( nie_prefix + nie.substr(1) );

  }

  validSPNIF ( match: any ) {
    
    if (match) {
      
      const letter  = match[1];
      const number  = match[2];
      const control: any = match[3];
      
      let even_sum: any = 0;
      let odd_sum: any = 0;
      let n: any;
      
      for ( let i = 0; i < number.length; i++) {
        n = parseInt( number[i], 10 );
        
        if ( i % 2 === 0 ) {
          n *= 2;
          odd_sum += n < 10 ? n : n - 9;
        } else {
          even_sum += n;
        }
        
      }
      
      let control_digit: number = (10 - (even_sum + odd_sum).toString().substr(-1) );
      let control_letter = 'JABCDEFGHI'.substr( control_digit, 1 );
      
      // Control must be a digit
      if ( letter.match( /[ABEH]/ ) ) {
        return control == control_digit;
      // Control must be a letter
      } else if ( letter.match( /[KPQS]/ ) ) {
        return control == control_letter;
      // Can be either
      } else {
        return control == control_digit || control == control_letter;
      }
    } else {
      return false;
    }        

  };
  
}
