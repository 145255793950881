import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Raffle } from '../models/raffle.model';
import { NewRaffleForm } from '../interfaces/new-raffle-form.interface';

const base_url = environment.base_url;

@Injectable({
  providedIn: 'root'
})
export class RaffleService {

  constructor(  private http: HttpClient ) { }
  
    get token(): string {
      return localStorage.getItem('token') || '';
    }
  
    get headers() {
      return {
        headers: {
          'x-token': this.token
        }
      }
    }
  
    loadRaffles( from: number = 0, limit: number = 10 ) {

      let searchTags = [];
      if ( localStorage.getItem('searchTags') ) {
        searchTags = localStorage.getItem('searchTags').split(';');
      }

      const url = `${ base_url }/raffles/${ searchTags }?from=${ from }&limit=${ limit }`;
      return this.http.get( url, this.headers )
              .pipe(
                map( (resp: {ok: boolean, raffles: Raffle[], total: number }) => resp )
              );
    }

    loadRafflesById( id: string ) {

      const url = `${ base_url }/raffles/raffle/${ id }`;
      return this.http.get( url, this.headers )
              .pipe(
                map( (resp: {ok: boolean, raffle: Raffle }) => resp.raffle)
              );
    }

    loadRafflesByType( from: number = 0, limit: number = 10, id: string, type: string ) {

      const url = `${ base_url }/raffles/${ type }/${ id }?from=${ from }&limit=${ limit }`;
      return this.http.get( url, this.headers )
              .pipe(
                map( (resp: {ok: boolean, raffles: Raffle[], total: number }) => resp)
              );

    }

    loadMyRaffles( from: number = 0, limit: number = 10 ) {
      
      let searchTags = [];
      if ( localStorage.getItem('searchTags') ) {
        searchTags = localStorage.getItem('searchTags').split(';');
      }

      const url = `${ base_url }/raffles/own/raffles/${ searchTags }?from=${ from }&limit=${ limit }`;
      return this.http.get( url, this.headers )
              .pipe(
                map( (resp: {ok: boolean, total: number, myRaffles: Raffle[] }) => resp )
              );

    }
  
    createRaffle( formData: NewRaffleForm ) {

      return this.http.post(`${ base_url }/raffles`, formData, this.headers );
  
    }
    
    updateRaffle( _id: string, data: object  ) {

      const url = `${ base_url }/raffles/${ _id }`;
      return this.http.put( url, data, this.headers );

    }

    checkRaffle( data: object ) {

      const url =`${base_url}/raffles/check/reg`;
      return this.http.put( url, data, this.headers );

    }
  
    deleteRaffle( _id: string ) {
  
      const url = `${ base_url }/raffles/${ _id }`;
      return this.http.delete( url, this.headers );
      
    }

    winnersUpdate() {

      const url = `${ base_url }/raffles/winners/selection`;
      return this.http.get( url, this.headers );

    }

}
