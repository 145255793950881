<div class="text-center mt-5 w-70 m-auto">
    <div class="w-100 position-relative">
        <h1>Tarifas</h1>
        <div class="text-end position-absolute top-0 end-0">
            <p class="f-xl mb-2">Nivel: {{ user.level }}</p>
        </div>
    </div>
    <!-- <h1>Tarifas</h1> -->
    <div *ngIf="user.payment.active">
        <p class="m-0">Nivel Actual {{ user.level }}</p>
        <p>Última Selección Nivel {{ user.payment.nextLevel }} {{ user.payment.nextSubsType==='monthly'?'Mensual':'Anual' }}!</p>
        <p *ngIf="nextPay >= now">siguiente renovación: <small>{{ user.payment.nextPay | date: 'dd/MM/YYYY' }}</small></p>
        <div *ngIf="nextPay < now">
            <app-redsys-reno></app-redsys-reno>
        </div>
        <button (click)="cancelSubs()" type="button" class="btn btn-danger">Cancelar Subscripción</button>
    </div>
</div>

<div class="row mt-3 w-70 m-auto">
    <div class="col-md-4 px-0 py-2 px-md-2">
        <app-pay-cards [level]=1 (levelSelected)="level=$event;redsysComponent.redsysInit(level, type)" (typeSelected)="type=$event"></app-pay-cards>
    </div>
    <div class="col-md-4 px-0 py-2 px-md-2">
        <app-pay-cards [level]=2 (levelSelected)="level=$event;redsysComponent.redsysInit(level, type)" (typeSelected)="type=$event"></app-pay-cards>
    </div>
    <div class="col-md-4 px-0 py-2 px-md-2">
        <app-pay-cards [level]=3 (levelSelected)="level=$event;redsysComponent.redsysInit(level, type)" (typeSelected)="type=$event"></app-pay-cards>
    </div>
</div>

<app-redsys></app-redsys>