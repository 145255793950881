import { Component, OnInit, Input, HostListener, ViewChild, ElementRef } from '@angular/core';
import { SearchService } from '../../../services/search.service';
import { Subscription } from 'rxjs';
import { User } from '../../../models/user.model';
import { delay } from 'rxjs/operators';
import { Offerer } from 'src/app/models/offerer.model';
import { OfferService } from '../../../services/offer.service';
import { Offer } from '../../../models/offer.model';
import { Router } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { ModalNewService } from '../../../services/modal-new.service';
import { RaffleService } from '../../../services/raffle.service';
import { Raffle } from '../../../models/raffle.model';


@Component({
  selector: 'app-offerer-filter-grid',
  templateUrl: './offerer-filter-grid.component.html',
  styles: [
  ]
})
export class OffererFilterGridComponent implements OnInit {

  @Input() hostContentId: string;

  public type: 'offers'|'raffles'|string = 'offers';
  public typeSlice: string = 'offerer';

  public totalContents: number = 0;
  public contents: User[] | Offerer[] | Offer[] | any[] = [];
  public contentsTemp: User[] | Offerer[] | Offer[] | any[]  = [];
  
  public imgSubs: Subscription;
  
  public loading: boolean = true;
  public scrollCheck: boolean = true;
  public empty: boolean = false;
  
  public limit: number = 12;
  public from: number = 0;
  public to: number = this.limit;
  public searchFrom: number = 0;
  
  public userAuth: boolean = false;

  public user: User;

  public searchTerm: string;
  public windowHeigth: number;
  public animated: boolean = true;
  public savePos: number = 1500;

  constructor(  private searchService: SearchService,
                private offerService: OfferService,
                private raffleService: RaffleService,
                private router: Router,
                private authService: AuthService,
                private modalNewService: ModalNewService, ) {

                  this.user = this.authService.user;
                  this.windowHeigth = window.innerHeight;

                }

  ngOnDestroy(): void {

    document.body.classList.remove('of-hidden');
    localStorage.removeItem('offersOffererContents')
    localStorage.removeItem('rafflesOffererContents')

  }

  ngOnInit(): void {

    this.type = localStorage.getItem( 'offererType' ) || 'offers';
    
    if ( localStorage.getItem(this.type + 'OffererContents') ) {
      this.loadLocalContents();
    } else {
      this.animated = true;
      this.loadContents();
    }

  }

  public fixed: boolean = false;

  // window scroll
  @HostListener('window:scroll', ['$event'])

  onScroll() {

    const pos = ( document.documentElement.scrollTop || document.body.scrollTop ) + this.windowHeigth * 3/2;
    const max = ( document.documentElement.scrollHeight || document.body.scrollHeight );
    
    if ( pos >= this.savePos || pos <= ( this.savePos - 50 ) ) {
      this.contentStorage();
      this.savePos = pos + 200;
    }

    if ( pos - this.windowHeigth * 3/2 > this.windowHeigth * 2 && this.scrollCheck) {
      this.fixed = true; 
      this.scrollCheck = false;
    } else if ( pos - this.windowHeigth * 3/2 <= this.windowHeigth * 2 && !this.scrollCheck ) {
      this.fixed = false; 
      this.scrollCheck = true;
    }

    if ( pos >= max && !this.empty ) {
      
      if ( !this.loading ) {
        this.from += this.limit;
        if ( this.searchTerm ) {
          this.searchPush();
        } else {
          this.loadContents();
        }
      }
    }

  }

  loadContents() {

    this.loading = true;
    
    if ( this.type === 'offers' ) {
      return this.offerService.loadOffersByType( this.from, this.limit, this.hostContentId, this.typeSlice )
                  .subscribe( ({ total, offers }) => {
                    this.contentsPusher( offers, total )
                    this.loading = false;
                  });
    } else {
      return this.raffleService.loadRafflesByType( this.from, this.limit, this.hostContentId, this.typeSlice )
                  .subscribe( ({ total, raffles }) => {
                    this.contentsPusher( raffles, total )
                    this.loading = false;
                  });
    }

  }

  loadLocalContents() {

    this.animated = false;
    const now = new Date();
    const localContents = JSON.parse( localStorage.getItem(this.type + 'Contents') );
    const date = new Date( localContents.date );
    const pos = localContents.pos;
    
    if ( now.getTime() < ( date.getTime() + ( 1000 * 60 * 5 ) ) ) {         
      if ( localContents.contents.length > 0 ) {
        this.contents = localContents.contents;
        this.contentsTemp = localContents.contents;
        this.from = localContents.from;
        this.empty = false; 
      } else {
        this.empty = true;
      }
      this.to = this.from + this.limit;
      
      var root = document.querySelector(':root');
      root.setAttribute("style", "scroll-behavior: auto;");
      setTimeout(() => {
        window.scroll( 0, pos );
        root.removeAttribute("style");
      }, 100);

    } else {
      this.animated = true;
      this.loadContents();
    }
    
  }

  contentsPusher( type, total ) {
    
    if (!type) {
      this.empty = true;
      return;
    }
    
    if ( type.length > 0) {
      if ( this.searchTerm ) {
        this.search( this.searchTerm );
        this.contentsTemp = type;
      } else {
        this.contents.push(...type);
        this.contentsTemp.push(...type);
      }
      this.totalContents = total;
      this.empty = false;                      
    } else {
      this.empty = true;
    }
    
    this.contentStorage();
    
  }
  
  contentStorage() {

    let pos = ( document.documentElement.scrollTop || document.body.scrollTop );

    if ( pos < 200 ) {
      pos = 0;
    }

    const localContents = {
      contents: this.contentsTemp,
      from: this.from,
      pos: pos,
      date: new Date()
    }

    localStorage.setItem(this.type + 'OffererContents', JSON.stringify(localContents))

  }

  search( term: string ) {
    
    this.loading = true;
    this.searchTerm = term;
    this.searchFrom = 0;

    if ( term.length === 0 ) {
      return this.contents = this.contentsTemp;
    }
    
    this.searchService.selectionSearch( this.typeSlice, this.type, term, this.hostContentId, this.searchFrom, this.limit )
    .subscribe( ( resp:  User[] | Offerer[] | Offer[] | Raffle[] ) => {
      this.contents = resp;
      this.loading = false;
    });
    
  }

  searchPush() {

    this.searchFrom += this.limit;

    if ( this.searchTerm.length === 0 ) {
      return this.contents = this.contentsTemp;
    }
    
    this.searchService.search( this.type, this.searchTerm, this.searchFrom, this.limit )
    .subscribe( ( resp:  User[] | Offerer[] | Offer[] | Raffle[] ) => {
      this.contents.push(...resp);
    });

  }

  pageChange( valor: number ) {

    this.from += valor * this.limit;
    this.to = this.from + this.limit;

    if ( this.from < 0 ) {
      this.from = 0;
    } else if ( this.from >= this.totalContents ) {
      this.from -= valor * this.limit;
    }

    if ( this.to > this.totalContents ) {
      this.to = this.totalContents;
    } else if ( this.to <= 0 ) {
      this.to += this.limit;
    }

    this.loadContents();

  }

  openModal() {

    this.modalNewService.openModal();

  }

}
