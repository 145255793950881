import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { User } from '../../models/user.model';
import { AuthService } from '../../services/auth.service';
import { MailService } from '../../services/mail.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styles: [
  ]
})
export class ContactFormComponent implements OnInit {

  @Input() email: string;
  @Input() regReport: string;

  public formSubmitted = false;
  public user: User;
  public contactForm: FormGroup;

  constructor(  private router: Router,
                private fb: FormBuilder,
                private mailService: MailService  ) {}

  ngOnInit(): void {
    
    if ( this.regReport ) {
      var reportMsg = `Referencia: ${ this.regReport }\n`
    }

    this.contactForm = this.fb.group({
      email: [ this.email || '' , [ Validators.required ] ],
      msg: [ reportMsg || '', Validators.required ],
      regReport: [ this.regReport || '', Validators.pattern('[0-9]{4,5}[A-Z]{3}') ]
    });

  }

  onSend() {
    
    this.mailService.sendMail( this.contactForm.value )
      .subscribe( resp => {

        this.router.navigateByUrl('/');
        Swal.fire('Enviado', 'El mensaje fue enviado', 'success');
      }, (err) => {

        Swal.fire('Error', err.error.msg, 'error' );
      });

  }
  
}
