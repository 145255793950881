import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { User } from '../models/user.model';

// const base_url = 'http://localhost:3000/api';
// const base_url = 'https://locana.es:3000/api';

const base_url = environment.base_url;

@Injectable({
  providedIn: 'root'
})
export class RedsysService {

  constructor(  private http: HttpClient ) { }

  get token(): string {
    return localStorage.getItem('token') || '';
  }

  get headers() {
    return {
      headers: {
        'x-token': this.token
      }
    }
  }

  payment(level: number, type: string) {

    const data = {
      level: level,
      type: type
    }

    return this.http.post(`${ base_url }/payment`, data, this.headers )
        .pipe(
          map( (resp: {ok: boolean, form: object, downgrade: boolean, title: string, msg: string }) => {

            return resp;
            
          })
        );
  
  }

  paymentResp() {

    return this.http.get(`${ base_url }/payment`, this.headers )
        .pipe(
          map( resp => {

            return resp;

          })
        );
  
  }

  downgrade( level, type ) {

    return this.http.put(`${ base_url }/payment/downgrade`,{level: level, type: type} , this.headers )
        .pipe(
          map( (resp: {ok: boolean, msg: string }) => {

            return resp;

          })
        );

  }

  cancel() {

    return this.http.put(`${ base_url }/payment/cancel`,'' , this.headers )
        .pipe(
          map( (resp: {ok: boolean, msg: string }) => {

            return resp;

          })
        );

  }

  noRenovation() {

    return this.http.put(`${ base_url }/payment/noRenovation`,'' , this.headers )
        .pipe(
          map( (resp: {ok: boolean, msg: string }) => {

            return resp;

          })
        );

  }

  downGradeQuestion( level: number, type: string, user: User ) {

    const now = new Date();

    const userLevel = user.level;
    const userType = user.subsType;

    const lastPay = new Date(user.payment.nextPay);

    if ( lastPay.getTime() < now.getTime() ) {
      
      if ( level === user.payment.nextLevel ) {
        return 'renovar';
      } else {
        return 'seleccionar'
      }

    }
    
    if (level === user.payment.nextLevel && type === user.payment.nextSubsType) {
      return 'prox. renovación';
    } else if ( level > userLevel || (level === userLevel && type !== userType && type === 'yearly') ) {
      return 'aumentar';
    } else if ( level === userLevel && type === userType ) {
      return 'vigente';
    } else {
      return 'degradar';
    }

  }

}
