import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import Swal from 'sweetalert2';

import { AuthService } from '../../services/auth.service';
import { ValidatorsService } from '../../services/validators.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styles: []
})
export class RegisterComponent {

  public formSubmitted: boolean = false;
  public identificationType: string;

  public registerForm = this.fb.group({
    name: ['', Validators.required ],
    lastName: ['', Validators.required ],
    nickName: ['', Validators.required ],
    email: ['', [ Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$') ] ],
    identificationCode: ['', Validators.required ],
    phone: ['', [ Validators.minLength(9), Validators.pattern('[+0-9]{9,12}$')] ],
    password: ['', Validators.required ],
    password2: ['', Validators.required ],
    reference: [ '', [ Validators.pattern('[0-9]{4}[A-Z]{3}$') ] ],
    terms: [ false, [ Validators.required, Validators.requiredTrue ] ],
  }, {
    validators: [ this.passwordMatch('password', 'password2'), this.identificationValidator( 'identificationCode' ) ]
  });

  constructor( private fb: FormBuilder,
               private authService: AuthService,
               private router: Router,
               private validatorsService: ValidatorsService ) { }

  createUser() {
    this.formSubmitted = true;
    
    this.format();

    if ( this.registerForm.invalid ) return;
    
    this.authService.createUser( this.registerForm.value )
        .subscribe( resp => {


          this.router.navigateByUrl('/');

        }, (err) => {

          Swal.fire('Error', err.error.msg, 'error' );

        });


  }

  invalidField( field: string ): boolean {

    if ( this.registerForm.get( field ).invalid && this.formSubmitted ) {
      return true;
    } else {
      return false;
    }

  }

  invalidPassword() {
    const pass1 = this.registerForm.get('password').value;
    const pass2 = this.registerForm.get('password2').value;

    if ( (pass1 !== pass2) && this.formSubmitted ) {
      return true;
    } else {
      return false;
    }

  }

  identificationValidator ( identificationCode: string ) {

    return ( formGroup: FormGroup ) => {
      const identificationCodeControl = formGroup.get( identificationCode );
      const { ok, identificationType } = this.validatorsService.validateSPIdentification( identificationCodeControl.value );
      
      this.identificationType = identificationType;

      if ( ok ) {
        identificationCodeControl.setErrors( null );
      } else {
        identificationCodeControl.setErrors( { invalidIdentification: true } )
      }

    }

  }

  invalidIdentification() {
    
    if ( !this.formSubmitted ) {
      return false;
    }
    
    const { ok, identificationType } = this.validatorsService.validateSPIdentification( this.registerForm.controls['identificationCode'].value );
    this.identificationType = identificationType;
    
    return !ok;

  }

  acceptTerm() {
    return !this.registerForm.get('terms').value && this.formSubmitted;
  }

  passwordMatch( pass1Name: string, pass2Name: string ) {

    return ( formGroup: FormGroup ) => {

      const pass1Control = formGroup.get( pass1Name );
      const pass2Control = formGroup.get( pass2Name );

      if ( pass1Control.value === pass2Control.value ) {
        pass2Control.setErrors(null);
      } else {
        pass2Control.setErrors( { doesntMatch: true } );
      }

    }

  }

  format() {
    
    this.itemFormat('name');
    this.itemFormat('lastName');
    this.itemFormat('nickName');
    this.itemFormat('identificationCode');
    this.itemFormat('phone');
    this.itemFormat('email');
    this.itemFormat('reference');

  }

  itemFormat( item ) {

    const registerForm = this.registerForm.controls;
    let finalValue = registerForm[ item ].value.trim();

    if ( item === 'identificationCode' || item === 'reference' ) {
      finalValue = finalValue.toUpperCase()
    } else {
      finalValue = finalValue.toLowerCase()
    }
    
    registerForm[ item ].setValue( finalValue );
    
  }

}
