<form *ngIf="raffle" class="form mt-3 w-70 m-auto" [formGroup]="raffleForm">
    <div class="form-group row container-fluid animate__animated animate__fadeIn p-0">
        <div class="position-relative z-1000">
            <div class="position-absolute top-0 end-0 mt-0 mt-lg-1 me-2">
                <button *ngIf="edit" (click)="delete()" type="button" class="btn btn-danger py-1 px-2 me-2">
                    <i class="fas fa-trash-alt"></i>
                </button>
                <button *ngIf="owner" (click)="edit=!edit" type="button" class="btn btn-outline-secondary py-1 px-2" [class.bg-secondary]="edit" [class.text-white]="edit">
                    <i class="fas fa-edit"></i>
                </button>
                <div *ngIf="!owner" class="position-relative text-warning my-auto mt-1 mt-lg-0 me-1">
                    <span (click)="addFavs()" class="pointer">
                    <i class="far fa-star animated fast"></i>
                    <i class="fas fa-star animated fast position-absolute start-0 top-025" [class.o-10]="fav"></i>
                </span>
                </div>
            </div>
        </div>
        <div class="col-12 f-s">
            <div class="input-group w-30">
                <h1 *ngIf="!edit">{{ raffle.name | titlecase }}</h1>
            </div>
            <div class="input-group">
                <a [href]="'https://www.google.es/maps/place/' + raffle.offerer.address.address + ', '+ raffle.offerer.address.pc" [title]="raffle.offerer.name  + ', en ' + raffle.offerer.address.town" target="_blank" class="pointer">
                    <i class="me-2 far fa-compass"></i>
                    <small>{{ (raffle.offerer.name + ', en ' + raffle.offerer.address.town) | titlecase }}</small>
                </a>
            </div>
            <div class="input-group">
                <a [href]="'user/offerer/' + raffle.offerer._id"><i class="fas fa-sign-out-alt me-2"></i>Ir al local</a>
            </div>
        </div>
    </div>
    <div class="form-group row pt-2">
        <div class="col-12 col-lg-6" [class.col-lg-12]="edit">
            <app-img-select [img]="raffle?raffle.img:'no-img'" [type]="'raffles'" [edit]="edit" (newImg)="newImg = $event"></app-img-select>
        </div>
        <div class="col-12 mt-4 mt-lg-0 col-lg-6" [class.col-lg-12]="edit">
            <div class="position-relative m-auto" [class.h-100]="!edit" [class.mw-60-vh]="!edit" [class.w-70]="edit" [class.mt-4]="edit">
                <h5 *ngIf="!edit">Descripción</h5>
                <h5 *ngIf="edit">Editar Oferta</h5>
                <div *ngIf="edit" class="input-group">
                    <input class="form-control main mb-2" type="text" placeholder="Premio" formControlName="name" contenteditable="false" [readonly]="!edit">
                </div>
                <div class="input-group desc-txt scroll-show" [class.edit]="edit" [class.mb-2]="edit">
                    <p *ngIf="!edit" [innerText]="raffle.description"></p>
                    <textarea *ngIf="edit" class="form-control secondary" type="text" placeholder="Descripción" formControlName="description" [readonly]="!edit"></textarea>
                </div>
                <div class="row lg-absolute start-0 bottom-0 start-0 end-0 w-100 m-auto" [class.position-absolute]="!edit" [class.position-static]="edit">
                    <div class="col col-sm-6 mb-2" [class.col-sm-12]="edit" [class.show-none]="!edit && (raffle.winners.length > 0)">
                        <div class="row">
                            <div class="col-12">
                                <label class="m-auto mx-2">Premios:</label>
                            </div>
                            <div class="col-12" [class.col-12]="!edit">
                                <input class="form-control secondary py-0" type="number" placeholder="Nº de Premios" formControlName="winnersLimit" [readonly]="!edit">
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 mb-2" [class.show-none]="!edit">
                        <div class="row">
                            <div class="col-12 text-sm-end" [class.pe-1]="!edit">
                                <label class="m-auto mx-2">Fecha inicio:</label>
                            </div>
                            <div class="col-12 text-sm-end" [class.pe-1]="!edit">
                                <input class="form-control secondary text-end p-0" type="date" placeholder="Fecha de inicio" formControlName="startDate" [readonly]="!edit">
                            </div>
                            <div class="col-12 text-sm-end">
                                <label class="m-auto mx-2">Hora inicio:</label>
                            </div>
                            <div class="col-12 text-sm-end" [class.pe-1]="!edit">
                                <input class="form-control secondary text-end p-0" type="time" placeholder="Hora de inicio" formControlName="startTime" [readonly]="!edit">
                            </div>
                        </div>
                    </div>
                    <div class="col col-sm-6 mb-2" [class.show-none]="!edit && (raffle.winners.length > 0)">
                        <div [class.justify-content-end]="!edit">
                            <div class="row">
                                <div class="col-12" [class.text-end]="!edit" [class.ps-1]="!edit" [class.text-start]="edit">
                                    <label *ngIf="!edit" class="m-auto mx-2">Finaliza:</label>
                                    <label *ngIf="edit" class="m-auto mx-2">Fecha fin:</label>
                                </div>
                                <div class="col-12 text-end" [class.ps-1]="!edit">
                                    <small *ngIf="!edit" class="secondary p-0 mx-2 text-end">{{ (raffle.endDate === null ? 'Sin fecha' : (raffle.endDate | date: "dd/MM/YYYY")) }}</small>
                                    <input *ngIf="edit" class="form-control secondary text-end p-0" type="date" placeholder="Fecha de final" formControlName="endDate" [readonly]="!edit">
                                </div>
                                <div *ngIf="edit" [class.ps-0]="!edit">
                                    <div class="col-12 text-sm-end" [class.ps-1]="!edit">
                                        <label class="m-auto mx-2">Hora fin:</label>
                                    </div>
                                    <div class="col-12 text-end" [class.ps-1]="!edit">
                                        <input class="form-control secondary text-end p-0" type="time" placeholder="Hora de final" formControlName="endTime" [readonly]="!edit">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="edit" class="form-group mb-3 mb-3 col-12">
                        <label for="exampleInputuname">Etiquetas</label>
                        <div class="input-group border-bottom">
                            <div class="row">
                                <div class="col-auto">
                                    <div class="my-auto ms-0">
                                        <span *ngFor="let tag of tags" class="badge rounded-pill bg-secondary h-50 me-1 mb-1">{{ tag | titlecase }}<i (click)="onDeleteTag(tag)" class="fas fa-times-circle ms-1 pointer"></i></span>
                                    </div>
                                </div>
                                <div class="col-auto">
                                    <input (change)="onTagsInput( tagsInput.value ); tagsInput.value = ''" class="form-control border-0" list="tagsList" type="text" placeholder="Etiquetas" formControlName="tags" #tagsInput>
                                    <datalist id="tagsList">
                                    <option *ngFor="let tagListItem of tagsList" [value]="tagListItem">
                                    </datalist>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="!edit && (raffle.winners.length > 0)" class="col-12 w-100 text-center animate__animated animate__slideInDown animate__faster">
                        <h1>Ganadores</h1>
                        <div *ngFor="let winner of raffle.winners" class="d-flex flex-nowrap w-100 justify-content-center" [class.bg-warning-o]="winner._id === user.id">
                            <h4 class="me-2">{{ winner.nickName }}</h4>
                            <!-- <small class="f-s my-auto">{{ winner.reg | slice:0:4}}-{{ winner.reg | slice:4:7}}</small> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="!edit" class="row mt-3 m-auto justify-content-center animate__animated animate__fadeIn animated__faster">
        <div *ngFor="let contact of contacts, let i = index" class="col px-1 px-lg-2 px-md-3" [class.show-none]="raffle.offerer.contact[contact] ? false : true">
            <div *ngIf="raffle.offerer.contact[contact]" class="text-center">
                <button (click)="
                    contactIndex === i ? contactShow = '' : (contactShow = raffle.offerer.contact[contact]); 
                    contactIndex === i ? contactIndex = (this.contacts.length + 1) : contactIndex = i; 
                    exist.checked = !exist.checked" type="button" class="btn btn-primary text-white fw-bolder w-100 mw-300-px">
                    <span *ngIf="windowWidth >= 850">{{ contactsSp[i] | titlecase}}</span>
                    <i [class]="contactsIcon[i] + ' ms-md-2'"></i>
                </button>
                <input type="checkbox" class="show-none" #exist>
                <div *ngIf="exist.checked && (windowWidth >= 1000)" class="text-center mt-2">
                    <small class="f-s">Click para contactar:</small>
                    <a [href]="contactsRef[i] + raffle.offerer.contact[contact]" target="_blank">
                        <h3>{{ raffle.offerer.contact[contact] }}</h3>
                    </a>
                </div>
            </div>
        </div>
        <a *ngIf="(windowWidth < 1000) && contactShow" [href]="contactsRef[contactIndex] + contactShow" target="_blank" class="text-center mt-3">
            <small class="f-s">Click para contactar:</small>
            <h3>{{ contactShow }}</h3>
        </a>
    </div>
    <div *ngIf="edit" class="row animated m-auto w-70 mw-300-px">
        <div class="col">
            <button (click)="saveRaffle()" type="button" class="btn btn-warning fw-bolder my-3 w-100" [disabled]="raffleForm.invalid">
                Guardar cambios
            </button>
        </div>
    </div>
</form>