<div class="position-relative img-avatar m-auto" [class.profile]="profile">
    <div class="text-center">
        <img *ngIf="!tempCroppedImg" [src]="img | img: type" class="img-avatar shadow" [class.rounded]="!circle" [class.rounded-circle]="circle">
        <img *ngIf="tempCroppedImg" [src]="tempCroppedImg" class="img-avatar rounded-circle shadow" [class.rounded]="!circle" [class.rounded-circle]="circle">
    </div>
    <!-- <div *ngIf="!crop" class="text-center pt-3 pb-5">
        <img *ngIf="!tempImg && !crop" [src]="img | img: type" class="img-avatar rounded-circle shadow">
        <img *ngIf="tempImg && !crop" [src]="tempImg" class="img-avatar rounded-circle shadow">
    </div> -->

    <div *ngIf="!crop && edit" class="text-center">
        <input (change)="changeImg($event)" class="form-control my-1 img-select" type="file">
    </div>
    <div *ngIf="edit" class="">
        <span class="position-absolute bottom-0 start-0 ms-3 text-secondary"><i class="fas fa-images fa-2x"></i></span>
        <button (click)="imgEditor();" class="btn btn-secondary position-absolute bottom-0 end-0 mb-1 me-3 py-1 px-2"><i class="fas fa-crop"></i></button>
    </div>

</div>

<!-- <div *ngIf="crop" class="my-3 text-center">
    <app-img-processing [imgSrc]="tempImg?tempImg:(img | img: type)" (imgEmit)="tempImg=$event" (newImg)="newImg.emit($event)"></app-img-processing>
    <app-img-processing [imgSrc]="tempImg?tempImg:(img | img: type)" (imgEmit)="tempImg=$event" (newImg)="newImg.emit($event)" (cropEmit)="crop=$event"></app-img-processing>
</div> -->
<app-modal-img-processing *ngIf="tempImg || crop" [imgSrc]="tempImg?tempImg:(img | img: type)" (imgEmit)="tempCroppedImg=$event" (newImg)="newImg.emit($event)" (cropEmit)="crop=$event" (update)="updateImg($event)"></app-modal-img-processing>