import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import Swal from 'sweetalert2';

import { UploadService } from '../../services/upload.service';

import { User } from '../../models/user.model';
import { UserService } from '../../services/user.service';
import { AuthService } from '../../services/auth.service';
import { environment } from '../../../environments/environment';
import { RedsysService } from '../../services/redsys.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styles: [
  ]
})
export class ProfileComponent implements OnInit {

  public profileForm: FormGroup;
  public user: User;
  public newImg: File;
  public tempImg: any = null;
  public formSubmitted = false;

  constructor(  private fb: FormBuilder,
                private authService: AuthService,
                private userService: UserService,
                private fileUploadService: UploadService  ) {

    this.user = authService.user;

  }

  ngOnInit(): void {

    this.profileForm = this.fb.group({
      nickName: [ this.user.nickName , Validators.required ],
      email: [ this.user.email, [ Validators.required, Validators.email ] ],
      password: [ '', Validators.minLength(2) ],
      newPassword: [ '', Validators.minLength(2) ],
      newPassword2: [ '', Validators.minLength(2)],
      changePassword: [ false ],
    });

  }

  updateProfile() {

    this.formSubmitted = true;

    this.profileForm = this.fb.group({
      nickName: [ this.profileForm.controls.nickName.value , Validators.required ],
      email: [ this.profileForm.controls.email.value, [ Validators.required, Validators.email ] ],
      password: [ this.profileForm.controls.password.value ],
      newPassword: [ this.profileForm.controls.newPassword.value ],
      newPassword2: [ this.profileForm.controls.newPassword2.value ],
      changePassword: [ this.profileForm.controls.changePassword.value ],
    }, {
      validators: this.passwordMatch('newPassword', 'newPassword2', 'changePassword')
    });

    if ( this.profileForm.invalid ) {
      return;
    }
  
    if (!this.profileForm.controls.changePassword.value) {
      this.userService.updateProfile( this.profileForm.value )
      .subscribe( () => {
        const { nickName, email } = this.profileForm.value;
        this.user.nickName = nickName;
        this.user.email = email;
  
        return Swal.fire('Guardado', 'Cambios fueron guardados', 'success');
      }, (err) => {
  
        return Swal.fire('Error', err.error.msg, 'error');
  
      });
    } else {

      this.profileForm = this.fb.group({
        nickName: [ this.profileForm.controls.nickName.value , Validators.required ],
        email: [ this.profileForm.controls.email.value, [ Validators.required, Validators.email ] ],
        password: [ this.profileForm.controls.password.value, Validators.minLength(2) ],
        newPassword: [ this.profileForm.controls.newPassword.value, Validators.minLength(2) ],
        newPassword2: [ this.profileForm.controls.newPassword2.value, Validators.minLength(2)],
        changePassword: [ this.profileForm.controls.changePassword.value ],
      }, {
        validators: this.passwordMatch('newPassword', 'newPassword2', 'changePassword')
      });

      if ( this.profileForm.invalid ) {
        return;
      }

      if ( (this.profileForm.controls.newPassword.value !== this.profileForm.controls.newPassword2.value) ) {
        return Swal.fire('Error', 'las contraseñas no coinciden', 'error');
      }
  
      this.authService.changePassword( this.profileForm.value )
          .subscribe( () => {
            const { nickName, email } = this.profileForm.value;
            this.user.nickName = nickName;
            this.user.email = email;
  
            Swal.fire('Guardado', 'Contraseña actualizada', 'success');
  
          }, (err) => {
  
            Swal.fire('Error', err.error.msg, 'error');
  
          });
    }

  }

  updateImg() {

    this.fileUploadService
      .updateImg( this.newImg, 'users', this.user.id )
      .then( img => {
        this.user.img = img;
        this.newImg = null;
        
        Swal.fire('Guardado', 'Imagen de usuario actualizada', 'success');
      }).catch( err => {
        console.log(err);
        Swal.fire('Error', 'No se pudo subir la imagen', 'error');
      });

  }

  invalidField( campo: string ): boolean {

    if ( this.profileForm.get(campo).invalid && this.formSubmitted ) {
      return true;
    } else {
      return false;
    }

  }

  invalidPassword() {
    
    const pass1 = this.profileForm.get('newPassword').value;
    const pass2 = this.profileForm.get('newPassword2').value;

    if ( (pass1 !== pass2) && this.formSubmitted ) {
      return true;
    } else {
      return false;
    }

  }

  passwordMatch(newPassword: string, newPassword2: string, changePassword ) {

    return ( profileForm: FormGroup ) => {

      const pass1Control = profileForm.get(newPassword);
      const pass2Control = profileForm.get(newPassword2);

      if ( pass1Control.value === pass2Control.value || !changePassword ) {
        pass2Control.setErrors(null)
      } else {
        pass2Control.setErrors({ noEsIgual: true })
      }

    }
  }

}
