import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Offerer } from '../models/offerer.model';
import { NewOffererForm } from '../interfaces/new-offerer-form.interface';
import { LoadOfferers } from '../interfaces/load-offerers.inteface';
import { scheduled } from 'rxjs';

const base_url = environment.base_url;


@Injectable({
  providedIn: 'root'
})
export class OffererService {


  constructor(  private http: HttpClient ) { }

  get token(): string {
    return localStorage.getItem('token') || '';
  }

  get headers() {
    return {
      headers: {
        'x-token': this.token
      }
    }
  }


  loadOfferers( from: number = 0, limit: number = 10 ) {
    
    let searchTags = [];
    if ( localStorage.getItem('searchTags') ) {
      searchTags = localStorage.getItem('searchTags').split(';');
    }

    const url = `${ base_url }/offerers/${ searchTags }?from=${ from }&limit=${ limit }`;
    return this.http.get<LoadOfferers>( url, this.headers )
            .pipe(
              map( (resp: {ok: boolean, offerers: Offerer[], total: number }) => resp)
            );
  }

  loadOfferersById( id: string ) {

    const url = `${ base_url }/offerers/offerer/${ id }`;
    return this.http.get( url, this.headers )
            .pipe(
              map( (resp: {ok: boolean, offerer: Offerer }) => resp.offerer)
            );
  }

  loadUserOfferers( from: number = 0, limit: number = 10, id: string, type: string ) {

    const url = `${ base_url }/offerers/${ type }/${ id }?from=${ from }&limit=${ limit }`;
    return this.http.get( url, this.headers )
            .pipe(
              map( (resp: {ok: boolean, offerers: Offerer[], total: number }) => resp)
            );
  }

  // Load if there is no pagination
  // loadOfferers() {

  //   const url = `${ base_url }/offerers`;
  //   return this.http.get( url, this.headers )
  //             .pipe(
  //               map( (resp: {ok: boolean, offerers: Offerer[] }) => resp.offerers )
  //             );
  // }

  createOfferer( formData: NewOffererForm ) {

    const url = `${ base_url }/offerers`;
    return this.http.post( url, formData, this.headers );

  }
  
  updateOfferer( _id: string, data: object ) {
    
    const url = `${ base_url }/offerers/${ _id }`;
    return this.http.put( url, data, this.headers );

  }

  deleteOfferer( _id: string ) {

    const url = `${ base_url }/offerers/${ _id }`;
    return this.http.delete( url, this.headers );

  }

}
