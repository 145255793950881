import { Component, OnInit, ViewChild } from '@angular/core';
import { RedsysComponent } from '../redsys/redsys.component';
import { AuthService } from '../../services/auth.service';
import { User } from '../../models/user.model';
import { RedsysService } from '../../services/redsys.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-pay',
  templateUrl: './pay.component.html',
  styles: [
  ]
})
export class PayComponent implements OnInit {

  public level: number;
  public type: string;
  public user: User;
  public nextPay: Date;
  public now: Date;

  @ViewChild( RedsysComponent ) redsysComponent: RedsysComponent;

  constructor(  private authService: AuthService,
                private redsysService: RedsysService  ) { 

                  this.user = this.authService.user;  
                  this.nextPay = new Date( this.user.payment.nextPay );
                  
                }

  ngOnInit(): void {

    this.now = new Date();

  }

  cancelSubs() {

    this.redsysService.cancel().subscribe( res => {

      if ( res.ok ) {

        Swal.fire({
          title: 'Guardado', 
          text: 'Seguro que quieres cancelar la subscripción',
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: 'Si, Cancelar'
        }).then((result) => {
          if (result.value) {
            window.location.reload();
          }
        });

      } else {

        Swal.fire('Error', res.msg, 'error');

      }

    });

  }

}
