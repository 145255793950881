<div class="d-flex mt-5">
    <div class="m-auto w-100">
        <div class="row mh-30-vh">
            <div class="col mb-3 text-center">
                <img class="img-fluid mh-30-vh" src="../../../assets/img/azul-amarillo.svg" alt="LoCana">
            </div>
        </div>
        <div class="container-fluid w-70 text-center">
            <h2 class="fs-xxl">ÉXITO</h2>
            <p class="fs-md">Muchas gracias por unirte a la familia Locana</p>
        </div>
    </div>
</div>