import { Component, OnInit, ViewChild, Pipe } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import Swal from 'sweetalert2';

import { UploadService } from '../../services/upload.service';

import { AuthService } from '../../services/auth.service';
import { Raffle } from '../../models/raffle.model';
import { User } from 'src/app/models/user.model';
import { MainGridComponent } from '../../components/grids/main-grid/main-grid.component';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { DateFormatService } from '../../services/date-format.service';
import { environment } from '../../../environments/environment';
import { RaffleService } from '../../services/raffle.service';

@Component({
  selector: 'app-raffle',
  templateUrl: './raffle.component.html',
  styles: [
  ]
})
export class RaffleComponent implements OnInit {

  @ViewChild(MainGridComponent) mainGridComponent: MainGridComponent;

  public raffleForm: FormGroup;
  public raffle: Raffle;
  public user: User;
  public newImg: File;
  public tempImg: any = null;

  public owner: boolean = false;
  public edit: boolean = false;

  public tags: string[] = ['general'];
  public tagsList: string[] = ['Comida','Deporte','Moda','Ocio','Otros']

  public fav: boolean = false;
  public regText: boolean = false;
  public contacts: string[] = ['email','phone','whatsApp', 'webSite'];
  public contactsSp: string[] = ['Email','Teléfono','WhatsApp', 'Sitio web'];
  public contactsRef: string[] = ['mailTo:','tel:','https://api.whatsapp.com/send?phone=', 'https://'];
  public contactsIcon: string[] = ['fas fa-envelope','fas fa-phone','fab fa-whatsapp', 'fas fa-globe'];
  public contactShow: string;
  public contactIndex: number = this.contacts.length + 1;

  public windowWidth: number;
  public maxLength: number = 200;

  public base_url: string = environment.base_url;
  public regBody: string;
  
  constructor( private fb: FormBuilder,
               private raffleService: RaffleService,
               private authService: AuthService,
               private fileUploadService: UploadService,
               private activatedRoute: ActivatedRoute,
               private router: Router,
               private userServive: UserService,
               private dateFormatService: DateFormatService, ) {

                this.user = this.authService.user;
                this.windowWidth = window.innerWidth;

              }

  async ngOnInit() {
    
    if ( this.windowWidth < 575) {
      this.maxLength = this.windowWidth * 5 / 100;
    } else if ( this.windowWidth < 768 ) {
      this.maxLength = this.windowWidth * 3.5 / 100;
    } else {
      this.maxLength = this.windowWidth * 3.75 / 100;
    }
    
    this.raffleForm = this.fb.group({
      name: [ '', Validators.required ],
      description: [ '', Validators.required ],
      startDate: [ '' ],
      startTime: [ '' ],
      endDate: [ '' ],
      endTime: [ '' ],
      winners: [ '' ],
      winnersLimit: [ '', [ Validators.min(1), Validators.pattern('[0-9]*') ] ],
      offerer: [ '' ],
      img: [ '' ],
      tags: [ ]
    });

    const { id } = this.activatedRoute.snapshot.params;
    
    this.raffleService.loadRafflesById( id )
          .subscribe( ( raffle ) => {
    
            this.raffle = raffle;       
            
            this.tags = raffle.tags;
            if ( this.tags.length === 0 ) {
              this.tags = ['general'];
            }

            const start = this.dateFormatService.getDateTime( this.raffle.startDate );
            const end = this.raffle.endDate === null ? null : this.dateFormatService.getDateTime( this.raffle.endDate );

            this.raffleForm = this.fb.group({
              name: [ this.raffle.name, Validators.required ],
              description: [ this.raffle.description, Validators.required ],
              startDate: [ start ? start.date : '' ],
              startTime: [ start ? start.time : '' ],
              endDate: [ end ? end.date : '' ],
              endTime: [ end ? end.time : '' ],
              winners: [ this.raffle.winners ? this.raffle.winners : '' ],
              winnersLimit: [ this.raffle.winnersLimit, [ Validators.min(1), Validators.pattern('[0-9]*') ] ],
              offerer: [ this.raffle.offerer._id ],
              img: [ this.raffle.img ],
              tags: [ ]
            });
            
            try {
              if ( this.raffle.participants.includes( this.user.id ) ) {
                this.fav = true;            
              }
            } catch {}
    
            if ( this.user.id === this.raffle.user._id || this.user.role === "ADMIN_ROLE" ) {
              this.owner = true;
            }
            
          } );

  }
  
  ngOnDestroy() {
    this.fav = false;
  }

  saveRaffle() {

    if ( this.newImg ) {

      this.updateImg();
      
    } else {

      this.updateRaffle();

    }

  }

  updateRaffle() {
    
    const formControls = this.raffleForm.controls;
    const now = this.dateFormatService.getDateTime();

    formControls['startDate'].setValue( new Date( ( ( formControls['startDate'].value !== '' ) ? formControls['startDate'].value : now.date ) + ' ' + ( formControls['startTime'].value.length > 3 ? formControls['startTime'].value : now.time ) ) );
    formControls['endDate'].setValue( new Date( formControls['endDate'].value !== '' ? (formControls['endDate'].value + ' ' + ( formControls['endTime'].value.length > 3 ? formControls['endTime'].value : '23:59' ) ) : '' ) );
    formControls['tags'].setValue( this.tags );
    
    this.raffleService.updateRaffle( this.raffle.id, this.raffleForm.value )
        .subscribe( () => {

          Swal.fire('Guardado', 'Cambios fueron guardados', 'success');
          this.router.navigate( [ '/user/reload' ] )
          setTimeout( () => { this.router.navigate( [ `/user/raffle`, this.raffle.id ] ) }, 100)

        }, (err) => {

          Swal.fire('Error', err.error.msg, 'error');

        });
      
    formControls['tags'].setValue( '' );

  }

  updateImg() {

    this.fileUploadService
      .updateImg( this.newImg, 'raffles', this.raffle.id )
      .then( ( img: string ) => {
        
        if (!img) {
          img = 'no-img'
        } 

        this.raffleForm.controls[ 'img' ].setValue( img );
    
        this.updateRaffle();

      }).catch( err => {

        console.log(err);
        Swal.fire('Error', 'No se pudo subir la imagen', 'error');
        
      });
  
  }

  changeImg( event: Event ) {
    const target = event.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];
    this.newImg = file;

    if ( !file ) {
      return this.tempImg = null;
    }

    const reader = new FileReader();
    reader.readAsDataURL( file );

    reader.onloadend = () => {
      this.tempImg = reader.result;
    }

  }

  delete() {

    Swal.fire({
      title: 'Eliminar Sorteo?',
      text: `Esta a punto de Eliminar "${ this.raffle.name }"`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Si, borrarla'
    }).then((result) => {
      if (result.value) {

        this.raffleService.deleteRaffle( this.raffle.id )
          .subscribe( resp => {
  
            Swal.fire(
              'Sorteo Eliminado',
              `"${ this.raffle.name }" fue eliminado correctamente`,
              'success'
            );

            this.router.navigateByUrl('/user/raffles');

          });

      }
    });

  }

  onTagsInput( tagsInput: string ) {
    
    let valid: boolean = true;

    if ( !this.tagsList.includes( tagsInput ) ) {
      return;
    }

    tagsInput = tagsInput.toLowerCase();

    for( let tag of this.tags ) {
      if ( tag === tagsInput ) {
        valid = false;
        return;
      }
    }
    
    if ( !valid ) {
      return;
    }
    
    for( let tag of this.tags ) {
      if ( tag === 'general' ) {        
        const index = this.tags.indexOf( tag );
        this.tags.splice( index, 1 );
      }
    }

    this.tags = [ ...this.tags, tagsInput ];

  }

  onDeleteTag( tagsDeleted: string ) {

    for( let tag of this.tags ) {
      
      if ( tag === tagsDeleted ) {        
        const index = this.tags.indexOf( tag );
        this.tags.splice( index, 1 )
        return;
      }

    }
  }

}
