import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import Swal from 'sweetalert2';

import { UploadService } from '../../services/upload.service';

import { AuthService } from '../../services/auth.service';
import { Offerer } from '../../models/offerer.model';
import { User } from 'src/app/models/user.model';
import { OffererService } from '../../services/offerer.service';
import { SettingsGridComponent } from '../../components/grids/settings-grid/settings-grid.component';
import { PcLocationService } from '../../services/pc-location.service';
import { ValidatorsService } from '../../services/validators.service';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-new-offerer',
  templateUrl: './new-offerer.component.html',
  styles: [
  ]
})
export class NewOffererComponent implements OnInit {

  @ViewChild(SettingsGridComponent) settingsGridComponent: SettingsGridComponent;

  public offererForm: FormGroup;
  public offerer: Offerer;
  public user: User;
  public newImg: File;
  public tempImg: any = null;
  public newOffer: boolean = false;

  public next: boolean = false;
  public formSubmitted: boolean = false;

  public tags: string[] = ['general'];
  public tagsList: string[] = ['Comida','Deporte','Moda','Ocio','Salud','Otros'];

  public schedule: object;
  public address: object;
  public contact: object;
  public town: string;
  public province: string;

  constructor(  private fb: FormBuilder,
                private offererService: OffererService,
                private authService: AuthService,
                private fileUploadService: UploadService,
                private pcLocationService: PcLocationService,
                private validatorsService: ValidatorsService,
                private router: Router,
                private userServive: UserService ) {

                  this.user = this.authService.user;
              
                }

  ngOnInit(): void {

    this.offererForm = this.fb.group({
      name: [ '' , Validators.required ],
      nif: [ '', Validators.required ],
      pc: [ '', [ Validators.required, Validators.pattern('[0-9]{5}$') ] ],
      address: [ '', Validators.minLength(5) ],
      contact: [ '' ],
      email: [ '', Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$') ],
      phone: [ '' ],
      whatsApp: [ '', Validators.pattern('[+0-9]{8,15}$') ],
      webSite: [ '' ],
      schedule: [ {} ],
      description: [ '' ],
      tags: [ '' ],
      img: [ 'no-img' ],
    }, {
      validators: [ this.nifValidator( 'nif' ) ]
    });

  }

  async newOfferer() {

    const formControls = this.offererForm.controls;
    
    this.format();

    this.formSubmitted = true;

    if ( this.offererForm.invalid) {
      return
    }

    const pc = formControls['pc'].value;

    await this.pcLocationService.location().subscribe( data => {
      
      this.town = data.filter( f => { return f.pc === pc })[0].town;
      this.province = data.filter( f => { return f.pc === pc })[0].province;
      this.address = {
        pc: pc,
        address: formControls[ 'address' ].value,
        town: this.town,
        province: this.province
      }
      
      if ( formControls[ 'phone' ].value || formControls[ 'email' ].value ) {
        this.contact = {
          phone: formControls[ 'phone' ].value,
          email: formControls[ 'email' ].value,
          whatsApp: formControls[ 'whatsApp' ].value,
          webSite: formControls[ 'webSite' ].value,
        }
      }
      
      formControls[ 'img' ].setValue( 'no-img' );
      formControls[ 'schedule' ].setValue( this.schedule );
      formControls[ 'address' ].setValue( this.address );
      formControls[ 'contact' ].setValue( this.contact );
      formControls[ 'tags' ].setValue( this.tags );

      if ( this.newImg ) {

        this.uploadImg();
        
      } else {
        
        this.createOfferer();
  
      }

    });  

  }

  createOfferer() {

    const formControls = this.offererForm.controls;

    this.offererService.createOfferer( this.offererForm.value )
      .subscribe( () => {

        this.formSubmitted = false;
        Swal.fire('Guardado', 'Cambios fueron guardados', 'success');
        this.offererForm.reset();
        this.tags = [ 'general' ];
        this.userServive.contentsReset();
        this.router.navigate( [ '/user/reload' ] );
        setTimeout( () => { this.router.navigate( [ '/user/new-offerer' ] ) }, 100);

      }, (err) => {
        
        Swal.fire('Error', err.error.msg, 'error');
        formControls[ 'tags' ].setValue('');
        formControls[ 'address' ].setValue( formControls['address'].value['address'] );

      });


  }
  
  uploadImg() {

    const formControls = this.offererForm.controls;

    this.fileUploadService
      .uploadImg( this.newImg, 'offerers' )
      .then( ( img: string ) => {

        formControls[ 'img' ].setValue( img );

        this.createOfferer();
        
      }).catch( err => {
        console.log(err);
        Swal.fire('Error', 'No se pudo subir la imagen', 'error');
      });

  }

  changeImg( event: Event ) {
    const target = event.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];
    this.newImg = file;

    if ( !file ) {
      return this.tempImg = null;
    }

    const reader = new FileReader();
    reader.readAsDataURL( file );

    reader.onloadend = () => {
      this.tempImg = reader.result;
    }

  }

  onTagsInput( tagsInput: string ) {
    
    let valid: boolean = true;

    if ( !this.tagsList.includes( tagsInput ) ) {
      return;
    }

    tagsInput = tagsInput.toLowerCase();

    for( let tag of this.tags ) {
      if ( tag === tagsInput ) {
        valid = false;
        return;
      }
    }
    
    if ( !valid ) {
      return;
    }
    
    for( let tag of this.tags ) {
      if ( tag === 'general' ) {        
        const index = this.tags.indexOf( tag );
        this.tags.splice( index, 1 );
      }
    }

    this.tags = [ ...this.tags, tagsInput ];

  }

  onDeleteTag( tagsDeleted: string ) {
    
    for( let tag of this.tags ) {
      if ( tag === tagsDeleted ) {
        const index = this.tags.indexOf( tag );
        this.tags.splice( index, 1 )
        if ( this.tags.length === 0 ) {
          this.tags = ['general'];
        }
        return;
      }
    }

  }

  invalidField( field: string ): boolean {

    if ( this.offererForm.get( field ).invalid && this.formSubmitted ) {
      return true;
    } else {
      return false;
    }

  }

  nifValidator ( nif: string ) {
    
    return ( formGroup: FormGroup ) => {
      const nifControl = formGroup.get( nif );
      if ( !this.formSubmitted ) {
        return nifControl.setErrors( null );
      }

      const { ok } = this.validatorsService.validateSPIdentification( nifControl.value );
      
      if ( ok ) {
        return nifControl.setErrors( null );
      } else {
        return nifControl.setErrors( { invalidNIF: true } );
      }

    }

  }

  invalidNIF() {

    if ( !this.formSubmitted ) {
      return false;
    }

    const { ok } = this.validatorsService.validateSPIdentification( this.offererForm.controls['nif'].value );
    return !ok

  }

  format() {
    
    this.itemFormat('name');
    this.itemFormat('nif');
    this.itemFormat('address');
    this.itemFormat('email');
    this.itemFormat('webSite');
    this.itemFormat('tags');

  }

  itemFormat( item ) {

    const registerForm = this.offererForm.controls;
    let finalValue = registerForm[ item ].value.trim();

    if ( item === 'nif' ) {
      finalValue = finalValue.toUpperCase()
    } else {
      finalValue = finalValue.toLowerCase()
    }
    
    registerForm[ item ].setValue( finalValue );
    
  }

}
