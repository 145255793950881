<div class="row m-auto mt-4 mt-sm-5 w-70">

    <div class="card text-center reg m-auto mb-3 mb-sm-4 border border-dark border-2">
        <div class="row h-100">
            <div class="col-2 h-100 p-0 be-1">
                <div class="bg-primary p-2 h-100 w-100">
                    <div class="h-100 w-100 reg-left"></div>
                </div>
            </div>
            <div class="col-10 m-auto">
                <h1 class="m-0 reg-text">
                    <span class="me-2">{{ user.reg | slice:0:4 }}</span>
                    <span>{{ user.reg | slice:4:7 }}</span>
                </h1>
            </div>
        </div>
    </div>

    <div class="row m-auto mb-3">
        <div class="col-7">
            <h1>Mi perfil</h1>
        </div>
        <div class="col-5 text-end">
            <p class="f-xl mb-2">Nivel: {{ user.level }}</p>
        </div>
    </div>

    <div class="card">
        <div class="card-body">
            <div class="col-12 my-3">
                <div class="card h-100">
                    <div class="card-body">
                        <h4 class="card-title">Avatar</h4>
                        <h6 class="card-subtitle">Imagen del usuario</h6>

                        <div class="pt-3 pb-5 mb-5">
                            <app-img-select [img]="user.img" [type]="'users'" (newImg)="newImg = $event" [circle]="true"></app-img-select>
                        </div>

                        <div class="position-absolute start-0 bottom-0 w-100 px-3">
                            <button (click)="updateImg()" type="button " class="btn btn-warning fw-bolder text-white text-y-shadow my-3 w-100" [disabled]="!newImg">
                                Cambiar imagen
                            </button>
                        </div>

                    </div>
                </div>
            </div>

            <div class="col-12 my-3">
                <div class="card h-100">
                    <div class="card-body">
                        <h4 class="card-subtitle">Ajustes</h4>

                        <form class="form mt-3" [formGroup]="profileForm" (submit)="updateProfile()">

                            <div class="form-group mb-3">
                                <label for="exampleInputuname">Nombre de Usuario</label>
                                <div class="input-group">
                                    <div class="input-group-addon"><i class="ti-user"></i></div>
                                    <input type="text" class="form-control" placeholder="Nombre del usuario" formControlName="nickName">
                                </div>
                            </div>
                            <div class="form-group mb-3">
                                <label for="exampleInputEmail1">Dirección de Email</label>
                                <div class="input-group">
                                    <div class="input-group-addon"><i class="ti-email"></i></div>
                                    <input type="email" class="form-control" placeholder="Email" formControlName="email" [readOnly]="user.google">
                                </div>
                            </div>
                            <div class="form-group mb-3">
                                <label for="exampleInputEmail1">Contraseña actual</label>
                                <div class="input-group">
                                    <div class="input-group-addon"><i class="ti-email"></i></div>
                                    <input type="password" class="form-control" placeholder="Contraseña" formControlName="password" [readOnly]="user.google">
                                </div>
                            </div>
                            <label for="exampleInputEmail1">Nueva contraseña</label>
                            <div class="form-group mb-2">
                                <div class="input-group">
                                    <div class="input-group-addon"><i class="ti-email"></i></div>
                                    <input type="password" class="form-control" placeholder="Nueva contraseña" formControlName="newPassword" [readOnly]="user.google">
                                </div>
                            </div>
                            <div class="form-group mb-3">
                                <div class="input-group">
                                    <div class="input-group-addon"><i class="ti-email"></i></div>
                                    <input type="password" class="form-control" placeholder="Repetir contraseña" formControlName="newPassword2" [readOnly]="user.google">
                                </div>
                            </div>
                            <div class="checkbox checkbox-primary pull-left p-t-0">
                                <input class="form-check-input checkbox-bg mb-3" id="checkbox-signup" type="checkbox" formControlName="changePassword">
                                <label for="checkbox-signup" class="ms-2">Cambiar contraseña</label>
                            </div>
                            <div class="row">
                                <div class="col text-warning">

                                    <p *ngIf="invalidField('nickName')">* El nickName es obligatorio</p>
                                    <p *ngIf="invalidField('email')">* El email es obligatorio y tiene que ser válido</p>
                                    <p *ngIf="invalidPassword()">* Las contraseñas deben de ser iguales</p>

                                </div>
                            </div>

                            <div class="pb-5"></div>

                            <div class="form-group position-absolute start-0 bottom-0 w-100 px-3">
                                <button type="submit" class="btn btn-warning fw-bolder text-white text-y-shadow my-3  w-100" [disabled]="profileForm.invalid">
                                    Guardar cambios
                                </button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>