import { Component, OnInit, Input } from '@angular/core';
import { User } from '../../models/user.model';
import { FormBuilder } from '@angular/forms';
import { RedsysService } from '../../services/redsys.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-redsys-reno',
  templateUrl: './redsys-reno.component.html',
  styles: [
  ]
})
export class RedsysRenoComponent implements OnInit {
 
  public form: any;
  public user: User;

  constructor(  private fb: FormBuilder,
                private redsysService: RedsysService,
                private authService: AuthService  ) {

                  this.user = this.authService.user;
                  
                }

  ngOnInit(): void {

      this.redsysInit( this.user.payment.nextLevel, this.user.payment.nextSubsType );

  }

  redsysInit(level: number, type: string) {

    this.redsysService.payment(level, type).subscribe( res => {
      
      this.form = res.form;

    });

  }

  reload() {

    setTimeout(()=>{ 
      window.location.reload();
    }, 500)

  }

}
