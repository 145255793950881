import { Component, OnInit, Output, ViewChild, ViewChildren, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import Swal from 'sweetalert2';

import { UploadService } from '../../../services/upload.service';

import { AuthService } from '../../../services/auth.service';
import { Offer } from '../../../models/offer.model';
import { Raffle } from '../../../models/raffle.model';
import { User } from 'src/app/models/user.model';
import { OfferService } from '../../../services/offer.service';
import { ModalNewService } from '../../../services/modal-new.service';
import { DateFormatService } from '../../../services/date-format.service';
import { RaffleService } from '../../../services/raffle.service';
import { UserService } from '../../../services/user.service';
import { Offerer } from '../../../models/offerer.model';
@Component({
  selector: 'app-modal-new',
  templateUrl: './modal-new.component.html',
  styles: [
  ]
})
export class ModalNewComponent implements OnInit {

  @Output() created: EventEmitter<boolean> = new EventEmitter();
  @Input() selectedType: 'offers'|'raffles';
  @Input() offererID: string;
  @Input() address: Object;

  public newForm: FormGroup;
  public item: Offer | Raffle;
  public user: User;

  public newImg: File;
  public tempImg: any = null;

  public formSubmitted: boolean = false;

  public tags: string[] = ['general'];
  // public tags: string[] = ['general'];
  public tagsList: string[] = ['Comida','Deporte','Moda','Ocio','Salud','Otros']

  public invalid: boolean = false;

  constructor( private fb: FormBuilder,
               private offerService: OfferService,
               private raffleService: RaffleService,
               private authService: AuthService,
               private fileUploadService: UploadService,
               public modalNewService: ModalNewService,
               private dateFormatService: DateFormatService,
               private userServive: UserService  ) {

    this.user = this.authService.user;

  }

  ngOnInit(): void {
    
    this.newForm = this.fb.group({
      name: [ '', Validators.required ],
      price: [ '', [ Validators.required, Validators.min(0), Validators.pattern('[0-9.]*') ] ],
      finalPrice: [ '', [ Validators.required, Validators.min(0), Validators.pattern('[0-9.]*') ] ],
      startDate: [ '' ],
      endDate: [ '' ],
      startTime: [ '00:00' ],
      endTime: [ '23:59' ],
      limit: [ '', [ Validators.min(1), Validators.pattern('[0-9]*') ] ],
      winnersLimit: [ '', [ Validators.min(1), Validators.pattern('[0-9]*') ] ],
      waitTime: [ '12', [ Validators.pattern('[0-9]*') ] ],
      maxUses: [ '1', [ Validators.min(1), Validators.pattern('[0-9]*') ] ],
      // executionTime: [ '', [ Validators.min(0), Validators.pattern('[0-9]*') ] ],
      useConditions: [ '', Validators.minLength(8) ],
      address: [ '' ],
      description: [ '', Validators.minLength(8) ],
      offerer: [ this.offererID ],
      tags: [ '' ],
      img: [ 'no-img' ],
    });

  }

  closeModal() {
    this.tempImg = null;
    this.modalNewService.closeModal();
  }

  newItem() {

    const formControls = this.newForm.controls;

    this.formSubmitted = true;

    if ( this.selectedType === 'raffles' ) {
      formControls['price'].setValue( 2 );
      formControls['finalPrice'].setValue( 1 );
    }

    if ( this.newForm.invalid) {
      Swal.fire('Error', 'Formulario mal completado', 'error');
      return
    }

    if ( this.newImg ) {

      this.uploadImg();
      
    } else {
      
      const now = new Date();

      formControls['img'].setValue( 'no-img' );
      this.dateFormat();

    }

  }

  async createItem() {

    const formControls = this.newForm.controls;
    formControls['offerer'].setValue( this.offererID );
    formControls['tags'].setValue( this.tags );
    formControls['address'].setValue( this.address );

    
    if ( this.selectedType === 'offers' ) {
      this.offerService.createOffer( this.newForm.value )
      .subscribe( () => {
        
        Swal.fire('Guardado', 'Cambios fueron guardados', 'success');
        this.created.emit( true );
        
      }, (err) => {
        
        Swal.fire('Error', err.error.msg, 'error');
        return;
        
      });
    } else {
      this.raffleService.createRaffle( this.newForm.value )
      .subscribe( () => {
        
        Swal.fire('Guardado', 'Cambios fueron guardados', 'success');
        this.created.emit( true );
        
      }, (err) => {
        
        Swal.fire('Error', err.error.msg, 'error');
        return;
        
      });
    }
    
    this.closeModal();
    this.newForm.reset();
    this.tags = [ 'general' ];
    this.userServive.contentsReset();

  }
  
  uploadImg() {

    this.fileUploadService
      .uploadImg( this.newImg, this.selectedType )
      .then( ( img: string ) => {
        
        this.newForm.controls['img'].setValue( img );
        this.dateFormat();
        
      }).catch( err => {

        console.log(err);
        Swal.fire('Error', 'No se pudo subir la imagen', 'error');
        
      });

  }

  async dateFormat() {
    
    const formControls = this.newForm.controls;
    let ok = true;

    if ( this.selectedType === 'raffles' && ( !formControls['endDate'].value || !formControls['startDate'].value ) ) {

      await Swal.fire({
        title: '¿Fecha final no seleccionada?',
        text: `De continuar, se sumará un mes a la fecha actual como fecha final del sorteo`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Si, Continuar'
      }).then((result) => {
        ok = result.isConfirmed;
      });

    }

    if ( !ok ) {
      return;
    }

    const now = this.dateFormatService.getDateTime();
    
    formControls['startDate'].setValue( new Date( ( formControls['startDate'].value ? formControls['startDate'].value : now.date ) + ' ' + ( formControls['startTime'].value ? formControls['startTime'].value : now.time ) ) );

    if ( this.selectedType === 'raffles') {
      // If you want to force an endDate
      const tempEndDate = new Date( now.fullDate.setMonth( now.fullDate.getMonth() + 1 ) );
      const endDate = this.dateFormatService.getDateTime( tempEndDate );

      formControls['endDate'].setValue( new Date( ( formControls['endDate'].value ? formControls['endDate'].value : endDate.date ) + ' ' + ( formControls['endTime'].value ? formControls['endTime'].value : '23:59' ) ) );
    } else {
      formControls['endDate'].setValue( new Date( formControls['endDate'].value ? (formControls['endDate'].value + ' ' + ( formControls['endTime'].value ? formControls['endTime'].value : '23:59' ) ) : '' ) );
    }

    this.createItem();

  }

  changeImg( event: Event ) {
    const target = event.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];
    this.newImg = file;

    if ( !file ) {
      return this.tempImg = null;
    }

    const reader = new FileReader();
    reader.readAsDataURL( file );

    reader.onloadend = () => {
      this.tempImg = reader.result;
    }

  }

  onTagsInput( tagsInput: string ) {
    
    let valid: boolean = true;

    if ( !this.tagsList.includes( tagsInput ) ) {
      return;
    }

    tagsInput = tagsInput.toLowerCase();

    for( let tag of this.tags ) {
      if ( tag === tagsInput ) {
        valid = false;
        return;
      }
    }
    
    for( let tag of this.tags ) {
      if ( tag === 'general' ) {        
        const index = this.tags.indexOf( tag );
        this.tags.splice( index, 1 );
      }
    }

    this.tags = [ ...this.tags, tagsInput ];

  }

  onDeleteTag( tagsDeleted: string ) {
    
    for( let tag of this.tags ) {
      if ( tag === tagsDeleted ) {
        const index = this.tags.indexOf( tag );
        this.tags.splice( index, 1 );
        if ( this.tags.length === 0 ) {
          this.tags = ['general'];
        }
        return;
        }
      }
    }

  invalidField( campo: string ): boolean {

    if ( this.newForm.get(campo).invalid && this.formSubmitted ) {
          return true;
        } else {
          return false;
        }
  
    }

  validatePrice() {

    const formControls = this.newForm.controls;
    
    if ( formControls[ 'price' ].value < formControls[ 'finalPrice' ].value || !formControls[ 'finalPrice' ].value ) {
      this.invalid = true;
    } else {
      this.invalid = false;
    }
    
  }

}
