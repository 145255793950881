import { Component, OnInit, Input, HostListener } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { SearchService } from '../../../services/search.service';
import { ModalImageService } from '../../../services/modal-image.service';
import { Subscription } from 'rxjs';
import { User } from '../../../models/user.model';
import { delay } from 'rxjs/operators';
import { OffererService } from '../../../services/offerer.service';
import { Offerer } from 'src/app/models/offerer.model';
import { OfferService } from '../../../services/offer.service';
import { Offer } from '../../../models/offer.model';
import { Router } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { PcLocationService } from '../../../services/pc-location.service';
import { RaffleService } from '../../../services/raffle.service';
import { Raffle } from '../../../models/raffle.model';


@Component({
  selector: 'app-main-grid',
  templateUrl: './main-grid.component.html',
  styles: []
})
export class MainGridComponent implements OnInit {

  @Input() pageType: 'users'|'offerers'|'offers'|'raffles'|'myRaffles'|'favs'|string;
  public type: 'users'|'offerers'|'offers'|'raffles'|string;
  public typeSlice: string;
  public totalContents: number = 0;
  public contents: User[] | Offerer[] | Offer[] | any[] = [];
  public contentsTemp: User[] | Offerer[] | Offer[] | any[]  = [];
  
  public imgSubs: Subscription;
  
  public loading: boolean = false;
  public scrollCheck: boolean = true;
  public empty: boolean = false;
  
  public limit: number = 12;
  public from: number = 0;
  public to: number = this.limit;
  public searchFrom: number = 0;
  
  public userAuth: boolean = false;

  public user: User;

  public searchTerm: string;
  public searchTags: string[];
  
  public renew: boolean = false;
  public location: any;
  public animated: boolean = true;
  public savePos: number = 1500;

  constructor(  private userService: UserService,
                private searchService: SearchService,
                private modalImageService: ModalImageService,
                private offererService: OffererService,
                private offerService: OfferService,
                private raffleService: RaffleService,
                private router: Router,
                private authService: AuthService,
                private userServive: UserService ) {   
    
                  this.user = this.authService.user;

                }

  ngOnInit(): void {

    if ( this.pageType === 'raffles') {
      this.pageType = localStorage.getItem( 'rafflesType' ) || 'raffles';      
    }

    if ( this.pageType === 'favs' ) {
      this.type = 'offers';
    } else if ( this.pageType === 'myRaffles') {
      this.type = 'raffles';
    } else {
      this.type = this.pageType;
    }

    if ( localStorage.getItem('searchTags') ) {
      this.searchTags = localStorage.getItem('searchTags').split(';');
    }
  
    this.typeSlice = this.type.slice(0, -1);
    
    if (!this.renew) {
      if ( localStorage.getItem(this.pageType + 'Contents') ) {
        this.loadLocalContents()
      } else {
        this.animated = true;
        this.loadContents();
      }
    }
    this.renew = false;
    
  }

  public fixed: boolean = false;

  // window scroll
  @HostListener('window:scroll', ['$event'])

  onScroll() {
    
    const pos = ( document.documentElement.scrollTop || document.body.scrollTop ) + 1300;
    const max = ( document.documentElement.scrollHeight || document.body.scrollHeight );
    
    if ( pos >= this.savePos || pos <= ( this.savePos - 50 ) ) {
      this.contentStorage();
      this.savePos = pos + 200;
    }

    if ( pos - 1300 > 140 && this.scrollCheck) {
      this.fixed = true; 
      this.scrollCheck = false;
    } else if ( pos - 1300 <= 140 && !this.scrollCheck ) {
      this.fixed = false; 
      this.scrollCheck = true;
    }

    if ( pos >= max && !this.empty ) {
      if ( !this.loading ) {
        this.from += this.limit;
        if ( this.searchTerm ) {
          this.searchPush();
        } else {
          this.loadContents();
        }
      }
    }
    
  }

  loadContents() {

    this.loading = true;
    const now = new Date();
        
    switch ( this.pageType ) {
      case 'users':
        return this.userService.loadUsers( this.from, this.limit )
                  .subscribe( ({ total, users }) => {
                    this.contentsPusher( users, total );
                  });
                  
      case 'offerers':
        return this.offererService.loadOfferers( this.from, this.limit )
                    .subscribe( ({ total, offerers }) => {
                      this.contentsPusher( offerers, total );
                    });

      case 'offers':
        return this.offerService.loadOffers( this.from, this.limit )
                    .subscribe( ({ total, offers }) => {
                      this.contentsPusher( offers, total );
                    });

      case 'raffles':
        return this.raffleService.loadRaffles( this.from, this.limit )
                    .subscribe( ({ total, raffles }) => {
                      this.contentsPusher( raffles, total );
                    });

      case 'myRaffles':
        return this.raffleService.loadMyRaffles( this.from, this.limit )
                    .subscribe( ({ total, myRaffles }) => {
                      this.contentsPusher( myRaffles, total )
                    });

      case 'favs':
        return this.offerService.loadFavs( this.from, this.limit, this.user.id, this.type )
                    .subscribe( ({ total, favs }) => {
                      this.contentsPusher( favs, total );
                    });

      default:
        return [];
        
    }

  }

  loadLocalContents() {

    this.animated = false;
    const now = new Date();
    const localContents = JSON.parse( localStorage.getItem(this.pageType + 'Contents') );
    const date = new Date( localContents.date );
    const pos = localContents.pos;
    
    if ( now.getTime() < ( date.getTime() + ( 1000 * 60 * 5 ) ) ) {         
      if ( localContents.contents.length > 0 ) {
        this.contents = localContents.contents;
        this.contentsTemp = localContents.contents;
        this.from = localContents.from;
        this.empty = false; 
      } else {
        this.empty = true;
      }
      this.to = this.from + this.limit;
      
      var root = document.querySelector(':root');
      root.setAttribute("style", "scroll-behavior: auto;");
      setTimeout(() => {
        window.scroll( 0, pos );
        root.removeAttribute("style");
      }, 100);

    } else {
      this.animated = true;
      this.loadContents();
    }
    
  }
  
  contentsPusher( type, total ) {
    
    if (!type) {
      this.empty = true;
      return;
    }
    
    if ( type.length > 0) {
      if ( this.searchTerm ) {
        this.contentsTemp = type;
        this.search( this.searchTerm );
      } else {
        this.contents.push(...type);
        this.contentsTemp.push(...type);
      }
      this.totalContents = total;
      this.empty = false;                      
    } else {
      this.empty = true;
    }

    this.contentStorage();

    this.loading = false;

  }

  contentStorage() {

    let pos = ( document.documentElement.scrollTop || document.body.scrollTop );
    
    if ( pos < 200 ) {
      pos = 0;
    }
    
    const localContents = {
      contents: this.contentsTemp,
      from: this.from,
      pos: pos,
      date: new Date()
    }

    localStorage.setItem(this.pageType + 'Contents', JSON.stringify(localContents))

  }
  
  search( term: string ) {

    this.searchTerm = term;
    this.searchFrom = 0;

    if ( term.length === 0 ) {
      return this.contents = this.contentsTemp;
    }
    
    this.searchService.search( this.pageType, term, this.searchFrom, this.limit )
    .subscribe( ( resp: User[] | Offerer[] | Offer[] | Raffle[] ) => {
      this.contents = resp;      
    });
    
  }

  searchPush() {

    this.searchFrom += this.limit;

    if ( this.searchTerm.length === 0 ) {
        return this.contents = this.contentsTemp;
    }
    
    this.searchService.search( this.pageType, this.searchTerm, this.searchFrom, this.limit )
    .subscribe( ( resp: User[] | Offerer[] | Offer[] | Raffle[] ) => {
      this.contents.push(...resp);
    });

  }

  pageChange( valor: number ) {

    this.from += valor * this.limit;
    this.to = this.from + this.limit;

    if ( this.from < 0 ) {
      this.from = 0;
    } else if ( this.from >= this.totalContents ) {
      this.from -= valor * this.limit;
    }

    if ( this.to > this.totalContents ) {
      this.to = this.totalContents;
    } else if ( this.to <= 0 ) {
      this.to += this.limit;
    }

    this.loadContents();

  }

  openModal( content: User | Offerer | Offer ) {
    
    this.modalImageService.openModal(this.type, content.id, content.img );

  }

  deleteTag( tag ) {

    let tags = [];
    if ( localStorage.getItem('searchTags') ) {
      tags = localStorage.getItem('searchTags').split(';');
    }

    const i = tags.indexOf(tag);

    if ( i >= 0 ) {
      tags.splice( i, 1 );
    }

    this.searchTags = tags;
    const newTags = tags.join(';')
    
    if ( newTags.length <= 0 ) {
      localStorage.removeItem('searchTags');
    } else {
      localStorage.setItem('searchTags', newTags);
    }

    this.restartTagsFilter();

  }

  restartTagsFilter() {

    this.userServive.contentsReset();

    this.from = 0;
    this.to = this.limit;
    this.contents = [];
    this.contentsTemp = [];
    setTimeout( () => this.ngOnInit(), 200 );

  }

}
