import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { MainGridComponent } from '../../components/grids/main-grid/main-grid.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-favs',
  templateUrl: './favs.component.html',
  styles: [
  ]
})
export class FavsComponent implements OnInit {

  @ViewChild( MainGridComponent ) mainGridComponent: MainGridComponent;
  @ViewChild( 'catMenu' ) catMenu: ElementRef;
  
  public collapsedCat: boolean = false;
  public collapsedCatTemp: boolean = false;
  public tagsList: string[] = ['Comida','Deporte','Moda','Ocio','Otros'];
  public type: string;
  public renew: boolean = true;

  public slide: boolean = false;
  public startPointX: number;
  public startPointY: number;
  
  constructor(  private activatedRoute: ActivatedRoute,
                private router: Router  ) {}

  ngOnInit() {

    this.type = this.activatedRoute.routeConfig.path;
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
    
  }

  async catClick( tagInput: string ) {
    
    let valid: boolean = true;

    if ( !this.tagsList.includes( tagInput ) ) {
      return;
    }

    tagInput = tagInput.toLowerCase();
    
    let nowTags = [];

    if ( localStorage.getItem('searchTags') ) {
      nowTags = localStorage.getItem('searchTags').split(';');
    }

    for( let tag of nowTags ) {
      if ( tag === tagInput ) {
        valid = false;
        return;
      }
    }
    
    for( let tag of nowTags ) {
      if ( tag === 'general' ) {        
        const i = nowTags.indexOf( tag );
        nowTags.splice( i, 1 );
      }
    }
  
    this.mainGridComponent.restartTagsFilter();
    const oldTags = nowTags.join(';');
    let newTags = '';
    
    if ( oldTags ) {    
      newTags = oldTags + ';' + tagInput;
    } else {
      newTags = tagInput;
    }
            
    localStorage.setItem('searchTags', newTags);

    this.mainGridComponent.renew = true;
    this.mainGridComponent.loadContents();

  }

  @HostListener('mousedown', ['$event'])
  onMouseDown() {  
    this.slide = true;
  }

  @HostListener('mouseup', ['$event'])
  onMouseUp() {
    this.slide = false;
  }

  @HostListener('mousemove', ['$event'])
  onMouseMove( event: MouseEvent ) {
    if ( this.slide ) { 
      event.preventDefault();
      if ( event.movementX > 10 && Math.abs(event.movementY) < 2 ) {
        this.router.navigateByUrl('/user/raffles')
      } else if ( event.movementX < -10 && Math.abs(event.movementY) < 2) {
        this.router.navigateByUrl('/user/offers')
      }
    }
  }
  
  @HostListener('touchstart', ['$event'])
  onTouchStart( event: TouchEvent ) {
    this.startPointX = event.touches.item(0).screenX;
    this.startPointY = event.touches.item(0).screenY;
    this.slide = true;
  }
  
  @HostListener('touchmove', ['$event'])
  onTouchMove( event: TouchEvent ) {
    if ( this.slide ) { 
      if ( (event.touches.item(0).screenX - this.startPointX) > 50 && Math.abs(event.touches.item(0).screenY - this.startPointY) < 20 ) {
        this.router.navigateByUrl('/user/raffles')
      } else if ( (event.touches.item(0).screenX - this.startPointX) < -50 && Math.abs(event.touches.item(0).screenY - this.startPointY) < 20) {
        this.router.navigateByUrl('/user/offers')
      }
    }
  }

}
