import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { UsersMaintenanceComponent } from './users.maintenance/users.maintenance.component';
import { OfferersMaintenanceComponent } from './offerers.maintenance/offerers.maintenance.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ComponentsModule } from '../../components/components.module';
import { RouterModule } from '@angular/router';
import { PipesModule } from '../../pipes/pipes.module';
import { OffersMaintenanceComponent } from './offers.maintenance/offers.maintenance.component';
import { RafflesMaintenanceComponent } from './raffles.maintenance/raffles.maintenance.component';



@NgModule({
  declarations: [
    MaintenanceComponent,
    UsersMaintenanceComponent,
    OfferersMaintenanceComponent,
    OffersMaintenanceComponent,
    RafflesMaintenanceComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ComponentsModule,
    RouterModule,
    PipesModule,
  ],
  exports: [
    MaintenanceComponent,
    UsersMaintenanceComponent,
    OfferersMaintenanceComponent,
    OffersMaintenanceComponent,
    RafflesMaintenanceComponent
  ]
})
export class MaintenanceModule { }
