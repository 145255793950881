import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PagesRoutingModule } from './pages/pages.routing.module';
import { AuthRoutingModule } from './auth/auth.routing.module';
import { MaintenanceRoutingModule } from './pages/maintenance/maintenance-routing.module';
import { InitRoutingModule } from './init/init.routing.module';

const routes: Routes =[
  { path: '', redirectTo: '/user/offers', pathMatch: 'full' },
  { path: '**', redirectTo: '/user/offers', pathMatch: 'full' },
]

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot( routes, { onSameUrlNavigation: 'reload' } ),
    PagesRoutingModule,
    InitRoutingModule,
    AuthRoutingModule,
    MaintenanceRoutingModule
  ],
  exports: [ RouterModule ]
})
export class AppRoutingModule { }
