import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';

import { User } from '../models/user.model';
import { Offerer } from '../models/offerer.model';
import { Offer } from '../models/offer.model';
import { Raffle } from '../models/raffle.model';

const base_url = environment.base_url;


@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor( private http: HttpClient ) { }

  get token(): string {
    return localStorage.getItem('token') || '';
  }

  get headers() {
    return {
      headers: {
        'x-token': this.token
      }
    }
  }

  search( type: 'users'|'offerers'|'offers'|'raffles'|'myRaffles'|'myFavs'|string, term: string, from: number = 0, limit: number = 5 ) {
console.log(base_url);

    let searchTags = [];
    if ( localStorage.getItem('searchTags') ) {
      searchTags = localStorage.getItem('searchTags').split(';');
    }

    const url = `${ base_url }/search/collection/${ type }/${ term }/${ searchTags }?from=${ from }&limit=${ limit }`;

    return this.http.get( url, this.headers )
            .pipe(
              map( (resp: any ) => {
                return resp.results;
              })
            );

  }

  selectionSearch( selection: string, type: 'users'|'offerers'|'offers'|'raffles'|string, term: string, id: string,   from: number = 0, limit: number = 5 ) {
    

    const url = `${ base_url }/search/${ selection }/${ id }/collection/${ type }/${ term }?from=${ from }&limit=${ limit }`;
    
    return this.http.get<any[]>( url, this.headers )
            .pipe(
              map( (resp: any ) => {
                return resp.results;
              })
            );

  }

  searchAll( term: string,  from: number = 0, limit: number = 5  ) {

    const url = `${ base_url }/search/${ term }?from=${ from }&limit=${ limit }`;

    return this.http.get( url, this.headers )
            .pipe(
              map( (resp: {ok: boolean, offerers: Offerer[], offers: Offer[] }) => resp )
            )
  }

}
