import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';
import Swal from 'sweetalert2';

import { Offerer } from '../../../models/offerer.model';

import { SearchService } from '../../../services/search.service';
import { OffererService } from '../../../services/offerer.service';
import { ModalImageService } from '../../../services/modal-image.service';

@Component({
  selector: 'app-offerers-maintenance',
  templateUrl: './offerers.maintenance.component.html',
  styles: [
  ]
})
export class OfferersMaintenanceComponent implements OnInit, OnDestroy {

  public offerers: Offerer[] = [];
  public offerersTemp: Offerer[] = [];
  

  public loading: boolean = true;
  private imgSubs: Subscription;
  
  public totalOfferers: number = 0;
  public from: number = 0;
  public limit: number = 5;
  public to: number = this.limit;

  public searchTerm: string;
  
  constructor( private offererService: OffererService,
               private modalImageService: ModalImageService,
               private searchService: SearchService ) { }

  ngOnDestroy(): void {
    this.imgSubs.unsubscribe();
  }

  ngOnInit(): void {
    this.loadOfferers();

    this.imgSubs = this.imgSubs = this.modalImageService.newImage
      .pipe(delay(100))
      .subscribe( img => this.loadOfferers() );
  }

  loadOfferers() {

    this.loading = true;

    if ( this.searchTerm ) {
      this.searchService.search( 'offerers', this.searchTerm, this.from, this.limit )
      .subscribe( ( resp: Offerer[] ) => {
        this.offerers = resp;
        this.loading = false;
      });
    } else {
    this.offererService.loadOfferers( this.from, this.limit )
        .subscribe( ({offerers, total}) => {
          this.totalOfferers = total;
          this.offerers = offerers;
          this.offerersTemp = offerers;          
          this.loading = false;    
        });
      }
  }

  search( term: string ) {

    if ( term.length === 0 ) {
      return this.offerers = this.offerersTemp;
    }    

    this.searchTerm = term;
    this.from = 0;
    this.to = this.limit;

    this.searchService.search( 'offerers', term, this.from, this.limit )
        .subscribe( ( resp: Offerer[] ) => {

          this.offerers = resp;

        });
  }

  deleteOfferer( offerer: Offerer ) {
    Swal.fire({
      title: '¿Borrar Local?',
      text: `Esta a punto de borrar a ${ offerer.name }`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Si, borrarlo'
    }).then((result) => {
      if (result.value) {
        this.offererService.deleteOfferer( offerer.id )
            .subscribe( resp => {
              this.loadOfferers();
              Swal.fire( 'Borrado', offerer.name, 'success' );
            });
          }
        });

  }

  // async openSweetAlert() {
  //   const { value = '' } = await Swal.fire<string>({
  //     title: 'Crear offerer',
  //     text: 'Ingrese el nombre del nuevo offerer',
  //     input: 'text',
  //     inputPlaceholder: 'Nombre del offerer',
  //     showCancelButton: true,
  //   });
    
  //   if( value.trim().length > 0 ) {
  //     this.offererService.createOfferer( value )
  //       .subscribe( (resp: any) => {
  //         this.offerers.push( resp.offerer )
  //       })
  //   }
  // }

  pageChange( valor: number ) {

    this.from += valor * this.limit;
    this.to = this.from + this.limit;

    if ( this.from < 0 ) {
      this.from = 0;
    } else if ( this.from >= this.totalOfferers ) {
      this.from -= valor * this.limit;
    }

    if ( this.to > this.totalOfferers ) {
      this.to = this.totalOfferers;
    } else if ( this.to <= 0 ) {
      this.to += this.limit;
    }

    this.loadOfferers();

  }

}
 