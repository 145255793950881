<nav class="navbar navbar-expand-sm navbar-dark fixed-top py-0" [class.navbar-h]="!collapsed" [class.bg-primary]="collapsed">
    <div class="container-fluid justify-content-end bg-primary w-95 pt-2">
        <button (click)="collapsed=!collapsed" class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="navbar-collapse justify-content-end" [class.show-none]="!collapsed" id="navbarTogglerDemo02" #smMenu>

            <ul class="navbar-nav my-auto text-center me-sm-3">
                <li class="nav-item">
                    <a class="nav-link" routerLink="/user" routerLinkActive="active">Home</a>
                </li>
                <!-- <li class="nav-item">
                    <a class="nav-link" routerLink="/init/about" routerLinkActive="active">Sobre Nosotros</a>
                </li> -->
                <li class="nav-item">
                    <a class="nav-link" routerLink="/init/contact" routerLinkActive="active">Contacto</a>
                </li>
            </ul>

        </div>
    </div>
</nav>