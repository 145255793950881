<ul class="nav nav-tabs mt-5">
    <li class="nav-item">
        <a class="nav-link text-secondary" aria-current="page" routerLink="users" routerLinkActive="active text-dark">Usuarios</a>
    </li>
    <li class="nav-item">
        <a class="nav-link text-secondary" routerLink="offerers" routerLinkActive="active text-dark">Locales</a>
    </li>
    <li class="nav-item">
        <a class="nav-link text-secondary" routerLink="offers" routerLinkActive="active text-dark">Ofertas</a>
    </li>
    <li class="nav-item">
        <a class="nav-link text-secondary" routerLink="raffles" routerLinkActive="active text-dark">Sorteos</a>
    </li>
</ul>

<router-outlet></router-outlet>