import { Injectable, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { tap, map, catchError } from 'rxjs/operators';

import { environment } from '../../environments/environment';



import { RegisterForm } from '../interfaces/register-form.interface';
import { LoginForm } from '../interfaces/login-form.interface';
import { User } from '../models/user.model';

const base_url = environment.base_url;

// declare const gapi: any;

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  // public auth2: any;
  public user: User;

  constructor(  private http: HttpClient,
                private router: Router,
                private ngZone: NgZone ) {

    // this.googleInit();

  }

  get uid(): string {
    return this.user.id || '';
  }

  get token(): string {
    return localStorage.getItem('token') || '';
  }

  get headers() {
    return {
      headers: {
        'x-token': this.token
      }
    }
  }

  validateToken(): Observable<boolean> {

    return this.http.get( `${ base_url }/auth/renew`,{
      headers: { 
        'x-token': this.token
      }
    }).pipe(
      map( (resp: {ok: boolean, token: string, user: User }) => {

        this.user = resp.user;
        // const { name, lastName, nickName, email, phone, img = '',google, role, level, reg, id } = resp.user;
        // this.user = new User( name, lastName, nickName, email, phone, '', img, google, role, level, reg, id );

        localStorage.setItem( 'token', resp.token );

        return true;
      }),
      catchError( error => of(false) )
    );

  }

  createUser( formData: RegisterForm ) {

    return this.http.post( `${ base_url }/users`, formData )
              .pipe(
                tap( (resp: any) => {
                  localStorage.setItem( 'token', resp.token );
                })
              );

  }

  login( formData: LoginForm ) {

    return this.http.post( `${ base_url }/auth`, formData )
                .pipe(
                  tap( (resp: any) => {
                    localStorage.setItem( 'token', resp.token )
                  })
                );

  }

  logout() {

    localStorage.clear();

    // this.auth2.signOut().then(() => {

    //   this.ngZone.run(() => {
    //     this.router.navigateByUrl('/login');
    //   });
    // });

  }

  changePassword( formData ) {

    return this.http.put( `${ base_url }/auth/change/${ this.user.id }`, formData, this.headers );

  }

  // loginGoogle( token ) {

  //   return this.http.post(`${ base_url }/auth/google`, { token } )
  //               .pipe(
  //                 tap( (resp: any) => {
  //                   localStorage.setItem('token', resp.token )
  //                 })
  //               );

  // }

  // googleInit() {

  //   return new Promise<void>( resolve => {
  //     gapi.load('auth2', () => {
  //       this.auth2 = gapi.auth2.init({
  //         client_id: '884852557589-117qbtfu6f51ctf5ujev4m0ra7ggtfv6.apps.googleusercontent.com',
  //         cookiepolicy: 'single_host_origin',
  //       });

  //       resolve();
  //     });
  //   })

  // }
}
