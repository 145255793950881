import { Component, Input, OnDestroy } from '@angular/core';
import { UserService } from './services/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Locana';

  constructor( private userServive: UserService ) {}

  ngOnInit() {

    this.userServive.contentsReset();

  }
}
