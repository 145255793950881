<div class="container text-center w-100 text-white position-relative">
    <div class="row">
        <div class="col">
            <img class="img-fluid mh-30-vh" src="../../../assets/img/blanco-amarillo.svg" alt="LoCana">
        </div>
    </div>
    <h1>Introduzca su email</h1>
    <p>La nueva contraseña será enviada a su correo electrónico</p>
    <div class="justify-content-center">
        <div class="container text-center text-white login w-100">
            <form class="form-horizontal form-material" [formGroup]="contactForm" (ngSubmit)="onSend()" id="loginform">

                <div class="form-group m-t-40 mb-3">
                    <div class="col-xs-12">
                        <input class="form-control" type="text" placeholder="Email" value="email?email:''" formControlName="email">
                    </div>
                </div>
                <div class="form-group text-center m-t-20">
                    <div class="col-xs-12">
                        <button class="btn btn-warning text-white fw-bolder text-y-shadow px-5 my-3" type="submit">Enviar</button>
                    </div>
                </div>

            </form>
        </div>
    </div>
    <div class="form-group">
        <div class="col-12 text-center">
            <p>¿La recordaste? <a routerLink="login" class="text-warning text-decoration-none ms-2"><b>Sign In</b></a></p>
        </div>
    </div>
</div>